import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import AdminLayout from '@app/layout/AdminLayout.tsx';
import { useParams } from 'react-router-dom';
import HTTPDetail from '@components/obsolete/admin/httprequests/HTTPDetail.tsx';
import ModuleSettingsTimeslot from '@components/features/module-settings/timeslot/ModuleSettingsTimeslot.tsx';

interface ComponentProps {}

const ModuleSettingsTimeslotPage: React.FC<ComponentProps> = () => {
  const { companyId } = useParams();

  if (!companyId) {
    return <></>;
  }

  return (
    <>
      <AdminLayout title={'Timeslot module settings'}>
        <ModuleSettingsTimeslot companyId={companyId} />
      </AdminLayout>
    </>
  );
};

export default withAuthenticationRequired(ModuleSettingsTimeslotPage);
