import React, { useContext, useState } from 'react';
import { CatalogCarrierFilter, CatalogCarrierPreviewOutDTO, PageResponseCatalogCarrierPreviewOutDTO } from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { convertToArray } from '@utils/utils';
import { InputText } from 'primereact/inputtext';
import { useCountryRegion, useImportExportCountries, useLovTruckTypes } from '@hooks/customer.hooks.tsx';
import { MultiSelect } from 'primereact/multiselect';
import { SplitButton } from 'primereact/splitbutton';
import AddCarrierSidebar from './AddCarrierSidebar';
import { Tooltip } from 'primereact/tooltip';
import { ApiContext } from '@api/api';
import useNavigation from '@hooks/useNavigation.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { Link } from 'react-router-dom';
import { Heading, HeadingRow } from '@components/styles.tsx';

interface ComponentProps {}

const CarrierCatalog: React.FC<ComponentProps> = () => {
  const { cuGetCatalogCarriers } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [data, setData] = useState<PageResponseCatalogCarrierPreviewOutDTO>(undefined);
  const countryRegions = useCountryRegion();
  const importExportCountries = useImportExportCountries();
  const truckTypes = useLovTruckTypes();
  const [loading, setLoading] = useState(true);
  const [addCarrierId, setAddCarrierId] = useState(undefined);
  const navigation = useNavigation();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<CatalogCarrierFilter>((page, filter) => {
    loadData(page.pageSize, page.pageNumber, filter);
  });

  const loadData = (pageSize: number, pageNumber: number, filter: CatalogCarrierFilter) => {
    setLoading(true);
    cuGetCatalogCarriers(
      filter,
      {
        pageSize,
        pageNumber: pageNumber,
      },
      data => {
        setLoading(false);
        setData(data);
      },
    );
  };

  const exportTemplate = (row: CatalogCarrierPreviewOutDTO) => {
    return (
      <FlexContainer>
        <div>{row.exportCountries.join(', ')}</div>
        {row.exportNote && (
          <>
            <span className={'pi pi-book'} id={`exportNote${row.carrierId}`}></span>
            {/*
        // @ts-ignore*/}
            <Tooltip target={`#exportNote${row.carrierId}`}>{row.exportNote}</Tooltip>
          </>
        )}
      </FlexContainer>
    );
  };

  const importTemplate = (row: CatalogCarrierPreviewOutDTO) => {
    return (
      <FlexContainer>
        <div>{row.importCountries.join(', ')}</div>
        {row.importNote && (
          <>
            <span className={'pi pi-book'} id={`importNote${row.carrierId}`}></span>
            {/*
        // @ts-ignore*/}
            <Tooltip target={`#importNote${row.carrierId}`}>{row.importNote}</Tooltip>
          </>
        )}
      </FlexContainer>
    );
  };

  const carsTemplate = (row: CatalogCarrierPreviewOutDTO) => {
    return row.cars.map(t => `${t.name} (${t.count})`).join(', ');
  };

  const actionTemplate = (row: CatalogCarrierPreviewOutDTO) => {
    const model = [
      {
        label: tr(`CarrierCatalog.detail`, `Detail`),
        command: () => {
          navigation.navigate(navigation.urlFunctions.createCarrierDetail(row.carrierId));
        },
        visible: true,
      },
    ];
    return (
      <ActionButtons>
        {!row.isAlreadyAdded && (
          <Button
            label={tr(`CarrierCatalog.addCarrier`, `Přidat`)}
            className={'p-button-rounded p-button-outlined'}
            onClick={e => setAddCarrierId(row.carrierId)}
          ></Button>
        )}
        {row.isAlreadyAdded && <i className="pi pi-check" style={{ fontSize: '2em' }}></i>}

        <SplitButton model={model.filter(t => t.visible)} className="btn-more-info p-button-rounded p-button-outlined" />
      </ActionButtons>
    );
  };

  const setFilterProperty = (value: any, attribute: string) => {
    applyFilterValue(attribute, value);
  };

  const clickableNameElement = (textToDisplay: string, url: string) => {
    return (
      <Link to={url}>
        <span className={'text-blue'} style={{ cursor: 'pointer' }}>
          {textToDisplay}
        </span>
      </Link>
    );
  };

  return (
    <>
      <HeadingRow>
        <Heading>{tr(`CarrierCatalog.carrierCatalogTitle`, `Katalog dopravců`)}</Heading>
        {/*        <Button
          className="p-button-rounded"
          icon="pi pi-plus"
          label={tr('CarrierCatalog.newCarrier', 'Nový dopravce')}
        ></Button>*/}
      </HeadingRow>
      {data && (
        <StyledDataTable>
          {/*
        // @ts-ignore*/}
          <DataTable
            value={data?.data}
            emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            rows={data.size}
            paginator={true}
            lazy={true}
            totalRecords={data.total}
            onPage={onPage}
            loading={loading}
            first={(data.page - 1) * data.size}
            filterDisplay="row"
          >
            <Column
              header={tr('CarrierCatalog.carrier', 'Dopravce')}
              body={(row: CatalogCarrierPreviewOutDTO) =>
                clickableNameElement(row.name, navigation.createNavigationLink(navigation.urlFunctions.createCarrierDetail(row.carrierId)))
              }
              filter={true}
              filterElement={<InputText value={filter.carrierName} onChange={e => setFilterProperty(e.target.value, 'carrierName')} style={{ width: '100%' }} />}
            ></Column>
            <Column
              header={tr(`CarrierCatalog.hq`, `Sídlo`)}
              field="countryRegion"
              filter={true}
              filterElement={
                <MultiSelect
                  showClear={true}
                  filter={true}
                  options={countryRegions}
                  optionValue={'id'}
                  optionLabel={'name'}
                  value={convertToArray(filter?.headquarters)}
                  style={{ width: '100%' }}
                  onChange={e => setFilterProperty(convertToArray(e.value), 'headquarters')}
                />
              }
            ></Column>
            <Column
              header={tr(`CarrierCatalog.export`, `Země export`)}
              body={exportTemplate}
              filter={true}
              filterElement={
                <MultiSelect
                  showClear={true}
                  filter={true}
                  options={importExportCountries}
                  optionValue={'code'}
                  optionLabel={'name'}
                  value={convertToArray(filter?.exportCountries)}
                  style={{ width: '100%' }}
                  onChange={e => setFilterProperty(convertToArray(e.value), 'exportCountries')}
                />
              }
            ></Column>
            <Column
              header={tr(`CarrierCatalog.import`, `Země import`)}
              body={importTemplate}
              filter={true}
              filterElement={
                <MultiSelect
                  showClear={true}
                  filter={true}
                  options={importExportCountries}
                  optionValue={'code'}
                  optionLabel={'name'}
                  value={convertToArray(filter?.importCountries)}
                  style={{ width: '100%' }}
                  onChange={e => setFilterProperty(convertToArray(e.value), 'importCountries')}
                />
              }
            ></Column>
            <Column
              header={tr(`CarrierCatalog.trucks`, `Vozový park`)}
              body={carsTemplate}
              filter={true}
              filterElement={
                <MultiSelect
                  showClear={true}
                  filter={true}
                  options={truckTypes}
                  optionValue={'code'}
                  optionLabel={'name'}
                  value={convertToArray(filter?.trucks)}
                  style={{ width: '100%' }}
                  onChange={e => setFilterProperty(convertToArray(e.value), 'trucks')}
                />
              }
            ></Column>
            <Column body={actionTemplate}></Column>
          </DataTable>
          <AddCarrierSidebar
            visible={addCarrierId}
            onHide={() => setAddCarrierId(undefined)}
            carrierId={addCarrierId}
            onAdded={() => {
              setAddCarrierId(undefined);
              loadData(page.pageSize, page.pageNumber, filter);
            }}
          />
        </StyledDataTable>
      )}
    </>
  );
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDataTable = styled.div`
  .p-datatable .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 1rem 1rem;
  }
`;

const ActionButtons = styled.div`
  display: flex;
`;

const ListAndNote = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default CarrierCatalog;
