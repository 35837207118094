import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Sidebar } from 'primereact/sidebar';
import { ACLInDTO, ModulesEnum, VisibilityEnum } from '@api/logsteo-api.v2';
import { Button } from 'primereact/button';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { ButtonRow, RingilH2 } from '@components/styles.tsx';
import InputACL from '@components/obsolete/Form/InputACL/InputACL.tsx';

interface ComponentProps {
  onCompleted: () => void;
  locationIds: string[];
}

export const useCreateLocationACLSidebar = () => {
  const [visible, setVisible] = useState(false);
  const LocationACLSidebar: React.FC<ComponentProps> = ({ onCompleted, locationIds }) => {
    const { tr } = useTranslationLgs();
    const { changeACLForLocations } = useContext(ApiContext);
    const [acl, setAcl] = useState<ACLInDTO>({
      visibility: VisibilityEnum.PUBLIC,
      visibleToCompanies: [],
    });

    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const save = () => {
      changeACLForLocations(
        {
          locationIds,
          visibility: acl.visibility,
          visibleToCompanies: acl.visibleToCompanies,
        },
        () => {
          setVisible(false);
          onCompleted();
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    return (
      <>
        {/*
        // @ts-ignore*/}
        <Sidebar
          visible={visible}
          position="right"
          baseZIndex={1000000}
          onHide={() => {
            setVisible(false);
            onCompleted();
          }}
          blockScroll={true}
          dismissable={false}
          style={{ minWidth: '40rem' }}
        >
          <RingilH2>{tr(`LocationACLSidebar.visibilitySettings`, `Visibility settings`)}</RingilH2>

          <BackendValidationComponent />

          <InputACL acl={acl} moduleTypes={[ModulesEnum.SUPPLIER_NOTIFICATION, ModulesEnum.TIMESLOTS]} onChange={v => setAcl(v)} />
          <ButtonRow>
            <Button
              label={tr(`LocationACLSidebar.save`, `Save`)}
              onClick={() => save()}
              disabled={acl.visibility === VisibilityEnum.VISIBLE_TO_COMPANIES && acl.visibleToCompanies.length === 0}
            ></Button>
            <Button
              label={tr(`LocationACLSidebar.back`, `Back`)}
              className={'p-button-text'}
              onClick={e => {
                setVisible(false);
              }}
            ></Button>
          </ButtonRow>
        </Sidebar>
      </>
    );
  };

  return { LocationACLSidebar, setVisible };
};
