import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import * as yup from 'yup';
import { CustomerNotificationResponse } from '@api/logsteo-api.v2.tsx';
import { dumpVars, isNullOrUndefined } from '@utils/utils.tsx';
import FilterButtons, { CustomerNotificationData } from '@components/ringil3/Features/Customer2InheritorNotification/components/FilterButtons.tsx';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import Message from '@components/ringil3/Features/Customer2InheritorNotification/components/Message.tsx';
import { mapFromAPIDateTime } from '@utils/date.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';

interface ComponentProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const Customer2InheritorNotificationSidebar: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  const [selectedModule, setSelectedModule] = useState(null);
  const { getCompanyNotificationsForInheritor, readCustomerNotification } = useContext(ApiContext);
  const [notifications, setNotifications] = useState<CustomerNotificationResponse[]>();
  const { tr } = useTranslationLgs();

  useEffect(() => {
    const timeoutId = setInterval(() => {
      if (visible) load();
    }, 5000);
    if (visible) load();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [visible]);

  const load = () => {
    getCompanyNotificationsForInheritor(data => {
      setNotifications(data.notifications);
    });
  };

  const markAsRead = (notificationId: string) => {
    readCustomerNotification(notificationId, () => {
      console.log('Notification marked as read');
      load();
    });
  };

  const summarization = notifications?.reduce((acc, curr) => {
    const oldValue = acc.find(t => t.module == curr.companyModule);

    if (isNullOrUndefined(oldValue)) {
      return [...acc, { module: curr.companyModule, count: 1, unread: curr.unread === true ? 1 : 0 }];
    } else {
      return acc.map(t => {
        if (t.module == curr.companyModule) {
          return { ...t, count: t.count + 1, unread: t.unread + (curr.unread === true ? 1 : 0) };
        }
        return t;
      });
    }
    return acc;
  }, [] as CustomerNotificationData[]);

  const groupBy = <T,>(array: T[], predicate: (value: T, index: number, array: T[]) => string): { [key: string]: T[] } =>
    array?.reduce((acc, value, index, array) => {
      (acc[predicate(value, index, array)] ||= []).push(value);
      return acc;
    }, {} as { [key: string]: T[] });

  const filterNotifications = (notifications: CustomerNotificationResponse[]): CustomerNotificationResponse[] => {
    if (notifications == null) return [];
    if (isNullOrUndefined(selectedModule)) return notifications;
    else {
      return notifications.filter(t => t.companyModule == selectedModule);
    }
  };

  const groupedByModule = groupBy<CustomerNotificationResponse>(filterNotifications(notifications), t => t.companyModule);

  return (
    <>
      <RingilSidebar
        visible={visible}
        onContinue={() => {}}
        onBack={() => {
          setVisible(false);
        }}
        heading={tr(`Customer2InheritorNotificationSidebar.customerNotification`, `Upozornění`)}
        showSaveButton={false}
      >
        {notifications?.length > 0 ? (
          <div className={'flex flex-col gap-6 flex-wrap'}>
            <FilterButtons selectedModule={selectedModule} data={summarization} onModuleChange={module => setSelectedModule(module)} />
            {Object.keys(groupedByModule).map((module: string, index: number) => {
              return (
                <div className={'flex flex-col gap-2'} key={index}>
                  <COHeadline
                    title={tr(`Customer2InheritorNotificationSidebar.module${module}`, `Module {{_count}}`, { _count: groupedByModule[module].length })}
                    variant={'h3'}
                  />
                  {groupedByModule[module].map((message, messageIndex) => {
                    return (
                      <Message
                        key={messageIndex}
                        subject={message.subject}
                        message={message.body}
                        companyName={message.companyName}
                        since={mapFromAPIDateTime(message.since)}
                        till={mapFromAPIDateTime(message.till)}
                        unread={message.unread}
                        onRead={() => {
                          markAsRead(message.id);
                        }}
                        attachments={message.attachments}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : (
          <COInlineMessage variant={'info'}>{tr(`Customer2InheritorNotificationSidebar.noMessages`, `Nemáte žázdné zprávy`)}</COInlineMessage>
        )}
      </RingilSidebar>
    </>
  );
};

export default Customer2InheritorNotificationSidebar;
