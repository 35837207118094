import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ColWithGap, Row, RowForTagsAndBadges } from '@components/styles';
import DistributionValidTillTag from '@components/ringil3/Tags/DistributionValidTillTag';
import ExpectedPriceTag from '@components/ringil3/Tags/ExpectedPriceTag';
import SelectedCarrierTag from '@components/ringil3/Tags/SelectedCarrierTag';
import CarPlateTag from '@components/ringil3/Tags/CarPlateTag';
import AssignedDriverTag from '@components/ringil3/Tags/AssignedDriverTag';
import RouteTable from '@components/ringil3/Features/Expedition/RouteTable/RouteTable';
import { ExpeditionDetailV3OutDTO } from '@api/logsteo-api.v2';
import SvgLkw from '@components/ringil3/icons/heading/Lkw';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  expedition: ExpeditionDetailV3OutDTO;
  onReload: () => void;
}

const TabExpeditionAssignedPlus: React.FC<ComponentProps> = ({ expedition, onReload }) => {
  const { tr } = useTranslationLgs();

  const saveCarPlate = (carPlate: string) => {
    alert('TODO');
  };

  return (
    <>
      <ColWithGap>
        <COHeadline variant={'h2'} title={tr(`ExpeditionDetail.carrierTitle`, `Carriers`)} icon={<SvgLkw />}></COHeadline>
        <RowForTagsAndBadges>
          <Row>
            <DistributionValidTillTag validTillISO8601={expedition.distribution.validTill} onChanged={() => onReload()} expeditionId={expedition.id} />
            <ExpectedPriceTag price={expedition.distribution?.price} currency={expedition.distribution?.currency} expeditionId={expedition.id} onReload={onReload} />
            <SelectedCarrierTag carrierName={expedition.assignedCarrierName} carrierId={null} companyLocationCustomerId={expedition.customerId} />
            <CarPlateTag carPlate={expedition.carPlate} onChange={carPlate => saveCarPlate(carPlate)} />
            <AssignedDriverTag assignedDriverName={expedition.assignedDriver} />
          </Row>
        </RowForTagsAndBadges>
        <RouteTable
          routes={expedition.routes}
          onChanged={() => onReload()}
          applicationId={expedition.applicationId}
          companyId={expedition.customerId}
          expeditionId={expedition.id}
        ></RouteTable>
      </ColWithGap>
    </>
  );
};

export default TabExpeditionAssignedPlus;
