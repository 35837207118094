import React from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';

interface Event {
  id: number;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  label: string;
}

interface RampCalendarProps {
  ramps: string[];
  events: { [rampName: string]: Event[] };
  validateEventMove?: (event: Event, newStart: dayjs.Dayjs) => boolean;
}

const RampCalendar: React.FC<RampCalendarProps> = ({ ramps, events, validateEventMove }) => {
  const [startTime, setStartTime] = useState(dayjs().hour(10).minute(0).second(0));
  const [windowHours, setWindowHours] = useState(24);
  const [selectedRamp, setSelectedRamp] = useState<string | null>(null);
  const [draggingEvent, setDraggingEvent] = useState<Event | null>(null);
  const endTime = startTime.add(windowHours, 'hour');

  const addFiveHours = () => {
    setStartTime(prev => prev.add(5, 'hour'));
  };

  const subtractFiveHours = () => {
    setStartTime(prev => prev.subtract(5, 'hour'));
  };

  const handleDragStart = (event: Event) => {
    setDraggingEvent(event);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, hour: dayjs.Dayjs) => {
    e.preventDefault();
    if (draggingEvent && validateEventMove) {
      const isValid = validateEventMove(draggingEvent, hour);
      const draggedElement = document.getElementById(`event-${draggingEvent.id}`);
      if (draggedElement) {
        draggedElement.style.backgroundColor = isValid ? 'green' : 'red';
      }
    }
  };

  const handleDrop = (ramp: string, hour: dayjs.Dayjs) => {
    if (draggingEvent && validateEventMove) {
      const isValid = validateEventMove(draggingEvent, hour);
      if (isValid) {
        // Update the event's start and end time
        const updatedEvent = { ...draggingEvent, start: hour, end: hour.add(draggingEvent.end.diff(draggingEvent.start, 'hour'), 'hour') };
        events[ramp] = events[ramp].map(event => (event.id === draggingEvent.id ? updatedEvent : event));
        setDraggingEvent(null);
      }
    }
  };

  const renderEvents = (ramp: string) => {
    return events[ramp]
      ?.filter(event => {
        return event.end.isAfter(startTime) && event.start.isBefore(endTime);
      })
      .map(event => {
        const totalHours = endTime.diff(startTime, 'hour');
        const eventStartOffset = Math.max(0, event.start.diff(startTime, 'hour'));
        const eventEndOffset = Math.min(totalHours, event.end.diff(startTime, 'hour'));
        const eventDuration = eventEndOffset - eventStartOffset;

        if (eventDuration <= 0) return null;

        return (
          <div
            key={event.id}
            id={`event-${event.id}`}
            className="absolute bg-green-600 text-white p-2 rounded cursor-move"
            style={{
              left: `${(eventStartOffset / totalHours) * 100}%`,
              width: `${(eventDuration / totalHours) * 100}%`,
            }}
            draggable
            onDragStart={() => handleDragStart(event)}
          >
            {event.label}
          </div>
        );
      });
  };

  const hours = Array.from({ length: windowHours }, (_, i) => startTime.add(i, 'hour'));

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between mb-4">
        <button onClick={subtractFiveHours} className="p-2 bg-blue-500 text-white rounded">
          -5 Hours
        </button>
        <div>
          {startTime.format('DD.MM.YYYY HH:mm')} - {endTime.format('DD.MM.YYYY HH:mm')}
        </div>
        <button onClick={addFiveHours} className="p-2 bg-blue-500 text-white rounded">
          +5 Hours
        </button>
      </div>
      <div className="flex">
        <div className="w-20 bg-gray-100 text-center p-2 border border-gray-300">Time</div>
        {hours.map(hour => (
          <div
            key={hour.toString()}
            className="flex-1 bg-gray-100 text-center p-2 border border-gray-300"
            onDragOver={e => handleDragOver(e, hour)}
            onDrop={() => handleDrop(selectedRamp || ramps[0], hour)}
          >
            {hour.format('HH:mm')}
          </div>
        ))}
      </div>
      {ramps.map(ramp => (
        <div key={ramp} className="flex items-center gap-2">
          <div
            className={`w-20 bg-gray-200 p-4 border border-gray-300 cursor-pointer ${selectedRamp === ramp ? 'bg-blue-100' : ''}`}
            onClick={() => setSelectedRamp(ramp)}
          >
            {ramp}
          </div>
          <div className="relative flex-1 border border-gray-300 h-12">{renderEvents(ramp)}</div>
        </div>
      ))}
    </div>
  );
};

// Example usage with sample data
const ramps = ['Ramp 1', 'Ramp 2', 'Ramp 3'];
const events = {
  'Ramp 1': [
    { id: 1, start: dayjs().hour(23), end: dayjs().add(1, 'day').hour(1), label: 'Late Loading A' },
    { id: 2, start: dayjs().hour(8), end: dayjs().hour(10), label: 'Loading B' },
  ],
  'Ramp 2': [
    { id: 3, start: dayjs().hour(1), end: dayjs().hour(4), label: 'Unloading C' },
    { id: 4, start: dayjs().hour(12), end: dayjs().hour(15), label: 'Loading D' },
  ],
  'Ramp 3': [{ id: 5, start: dayjs().hour(6), end: dayjs().hour(9), label: 'Maintenance' }],
};

const validateEventMove = (event: Event, newStart: dayjs.Dayjs) => {
  // Example validation logic: prevent moving events to before 6 AM or after 10 PM
  console.log('Validating event move', event, newStart);
  return newStart.hour() >= 6 && newStart.hour() <= 22;
};

const App: React.FC = () => {
  return <RampCalendar ramps={ramps} events={events} validateEventMove={validateEventMove} />;
};

export default App;
