import React from 'react';
import { COSelectProps } from '@components/framework/selects/types.ts';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import SvgCheck from '@components/framework/selects/Check.tsx';

const COSelect: React.FC<COSelectProps> = ({ selected, label, badgeTexts, onClick }) => {
  return (
    <div>
      <span
        className={twMerge(
          clsx('inline-flex h-[40px] gap-2 items-center p-4 whitespace-nowrap font-medium text-[14px] rounded-[20px] bg-content-quaternary border ', {
            'bg-content-brandMedium text-content-brand border border-content-brand border-solid ': selected,
          }),
        )}
        onClick={onClick}
      >
        {selected && (
          <div>
            <SvgCheck />
          </div>
        )}
        {label}
        {badgeTexts?.length > 0 &&
          badgeTexts?.map((badgeText, index) => (
            <div key={index} className={twMerge(clsx('h-4 px-1 bg-white rounded justify-center items-center inline-flex', { 'bg-content-brand': selected === true }))}>
              <div className={twMerge(clsx('text-xs font-normal text-content-primary  tracking-tight items-center', { 'text-white': selected }))}>{badgeText}</div>
            </div>
          ))}
      </span>
    </div>
  );
};

export default COSelect;
