import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import * as yup from 'yup';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';
import dayjs from 'dayjs';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import { findValidationMessage } from '@utils/validation.tsx';

interface ComponentProps {
  onSaved: () => void;
}

interface Form {
  carPlate: string;
  name: string;
  sinceLocalDate: string;
  tillLocalDate: string;
}

export const useCreateAddCarPlateSidebar = () => {
  const [visible, setVisible] = useState(false);

  const AddCarPlateSidebar: React.FC<ComponentProps> = ({ onSaved }) => {
    const { saveUpdateAllowedCarPlate } = useContext(ApiContext);
    const { tr } = useTranslationLgs();
    const { setBeValidationErrors, BackendValidationComponent } = useBEValidation();

    const form = useForm<Form>(
      yup.object().shape({
        carPlate: yup.string().required().min(1),
        sinceLocalDate: yup.string().test('sinceLocalDate', 'validation.required', value => dayjs(value).isValid()),
      }),
      {
        carPlate: '',
        name: null,
        sinceLocalDate: dayjs().format('YYYY-MM-DD'),
        tillLocalDate: null,
      },
      d => save(d),
      false,
      false,
    );

    const save = (data: Form) => {
      saveUpdateAllowedCarPlate(
        data,
        () => {
          onSaved();
        },
        null,
        {
          onValidationFailed: setBeValidationErrors,
        },
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          onContinue={() => {
            form.validateAndSend();
          }}
          onBack={() => {
            setVisible(false);
          }}
          heading={tr('AddCarPlateSidebar.pridatSpz', 'Přidat SPZ')}
        >
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <Field label={tr('AddCarPlateSidebar.spz', 'SPZ')} required={true} errorMessage={findValidationMessage(form.validationErrors, 'carPlate', tr)}>
              <RingilInputText value={form.values.carPlate} onChange={v => form.setFieldValue(form.names.carPlate, v)} />
            </Field>
            <Field label={tr('AddCarPlateSidebar.pojmenovani', 'Pojmenování')}>
              <RingilInputText value={form.values.name} onChange={v => form.setFieldValue(form.names.name, v)} />
            </Field>

            <Field label={tr('AddCarPlateSidebar.platnostOd', 'Platnost od')} required errorMessage={findValidationMessage(form.validationErrors, 'sinceLocalDate', tr)}>
              <RingilCalendar value={form.values.sinceLocalDate} onChange={v => form.setFieldValue(form.names.sinceLocalDate, v)} fullWidth />
            </Field>
            <Field label={tr('AddCarPlateSidebar.platnostDo', 'Platnost do')}>
              <RingilCalendar value={form.values.tillLocalDate} onChange={v => form.setFieldValue(form.names.tillLocalDate, v)} fullWidth />
            </Field>
          </div>
        </RingilSidebar>
      </>
    );
  };

  return { AddCarPlateSidebar, visible, setVisible };
};
