import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils.tsx';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface ComponentProps {
  helpText?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
  children: ReactElement<{ value: any; required: boolean; readOnly: boolean; disabled: boolean; style: { [key: string]: any } }>;
  actionButtons?: ReactElement;
  buttonsMode?: 'actionButton' | 'iconButton';
  width?: string;
}

export const Field: React.FC<ComponentProps> = ({
  helpText,
  label,
  children,
  errorMessage,
  required = false,
  readOnly,
  disabled,
  actionButtons,
  width,
  buttonsMode = 'iconButton',
}) => {
  const hasValue = isNotNullOrUndefined(children.props.value) && `${children.props.value}`.trim() !== '';

  const convertToMode = (errorMessage: string, readOnly: boolean, disabled: boolean, required: boolean): 'error' | 'disabled' | 'readOnly' | 'normal' | 'required' => {
    if (errorMessage) {
      return 'error';
    }
    if (disabled) {
      return 'disabled';
    }
    if (readOnly) {
      return 'readOnly';
    }
    if (required && !hasValue) {
      return 'required';
    }
    return 'normal';
  };

  const mode = convertToMode(errorMessage, readOnly, disabled, required);
  return (
    <>
      <Content mode={mode} className={errorMessage ? 'hasError' : null} width={width}>
        <LabelContainer mode={mode}>
          <Label>{label}</Label>
          {helpText && <div className={''}>{helpText}</div>}
        </LabelContainer>
        <div className={''}>
          <ContentInner>
            {React.cloneElement(children, { readOnly, required: required && !hasValue, disabled, style: { width: '100%' } })}
            {isNotNullOrUndefined(actionButtons) && (
              <div
                className={twMerge(
                  clsx(
                    '',
                    {
                      'flex flex-row gap-2 ml-2 items-center': buttonsMode === 'iconButton',
                    },
                    {
                      '': buttonsMode === 'actionButton',
                    },
                  ),
                )}
              >
                {actionButtons}
              </div>
            )}
          </ContentInner>
        </div>
        {errorMessage && <div className={'text-complementary-error text-[10px]'}>{errorMessage}</div>}
      </Content>
    </>
  );
};

const ContentInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 40px;
`;

const LabelContainer = styled.div<{
  mode: 'error' | 'disabled' | 'readOnly' | 'normal' | 'required';
}>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0.5rem 0;

  ${props =>
    props.mode === 'readOnly' &&
    css`
      // ** move to the label container

      span.p-inputtext,
      div {
        color: var(--ringil3-content-tertiary-color);
      }
    `}
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;

  /* Black */
  color: var(--ringil3-primary-color);
`;

const HelpInner = styled.div`
  font-size: 0.75rem;
`;

const Content = styled.div<{
  mode: 'error' | 'disabled' | 'readOnly' | 'normal' | 'required';
  width?: string;
}>`
  display: flex;
  flex-direction: column;

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  span.p-inputtext {
    border: none !important;
  }

  input,
  textarea,
  div.p-inputwrapper,
  div.r-input-wrapper {
    border: 1px solid var(--ringil3-content-secondary);
  }

  span.p-inputtext {
    border: 1px solid var(--ringil3-content-secondary);
  }

  ${props =>
    props.mode === 'error' &&
    css`
      input,
      textarea,
      input:focus,
      div.p-inputwrapper,
      div.r-input-wrapper {
        border: 1px solid var(--ringil3-complementary-error-color);
        color: var(--ringil3-complementary-error-color);
      }

      span.p-inputtext {
        color: var(--ringil3-complementary-error-color);
      }
    `}

  ${props =>
    props.mode === 'required' &&
    css`
      input,
      textarea,
      div.p-inputwrapper,
      div.r-input-wrapper {
        border: 1px solid var(--ringil3-content-secondary);
        background-color: var(--ringil3-complementary-warning-medium);
      }

      span.p-inputtext {
        border: 1px solid var(--ringil3-content-secondary);
        background-color: var(--ringil3-complementary-warning-medium);
      }
    `}

    ${props =>
    props.mode === 'disabled' &&
    css`
      input,
      textarea,
      div.p-inputwrapper {
        border: 1px solid var(--ringil3-content-tertiary-color);
        color: var(--ringil3-content-tertiary-color);
      }

      span.p-inputtext {
        color: var(--ringil3-content-tertiary-color);
      }
    `}

    ${props =>
    props.mode === 'readOnly' &&
    css`
      input,
      input:focus,
      textarea,
      div.p-inputwrapper {
        border: 1px solid var(--ringil3-content-tertiary-color);
        color: var(--ringil3-content-tertiary-color);
      }
    `}
`;
