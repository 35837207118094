import { useEffect, useRef, useState, useCallback } from 'react';
import SockJS from 'sockjs-client';
import { Client, IMessage, Stomp } from '@stomp/stompjs';

type MessageCallback<T> = (message: T) => void;

export const useWebSocket = <T,>() => {
  const [client, setClient] = useState<Client | null>(null);
  const [connected, setConnected] = useState<boolean>(false);
  const subscriptionsRef = useRef<{ [key: string]: () => void }>({});

  useEffect(() => {
    // Inicializace WebSocket klienta s použitím továrny
    const stompClient = new Client({
      brokerURL: undefined, // Nepoužívá se, protože používáme SockJS
      connectHeaders: {}, // Volitelné hlavičky
      reconnectDelay: 5000, // Automatické znovupřipojení
      webSocketFactory: () => new SockJS('/services/frontend-service/websocket-endpoint'), // Továrna na SockJS
      debug: (msg: string) => console.log(msg), // Pro ladění
    });

    stompClient.onConnect = () => {
      console.log('WebSocket connected');
      setConnected(true);
      setClient(stompClient);
    };

    stompClient.onDisconnect = () => {
      console.log('WebSocket disconnected');
      setConnected(false);
      setClient(null);
    };

    stompClient.activate();

    // Cleanup při odpojení
    return () => {
      if (stompClient.active) {
        stompClient.deactivate();
        console.log('WebSocket deactivated');
      }
    };
  }, []);

  // Přihlášení k tématu
  const listen = useCallback(
    (topic: string, callback: MessageCallback<T>) => {
      if (!connected || !client) {
        console.warn('WebSocket is not connected yet');
        return;
      }

      const subscription = client.subscribe(`/topic/${topic}`, (message: IMessage) => {
        try {
          const parsedMessage = JSON.parse(message.body) as T;
          callback(parsedMessage);
        } catch (error) {
          console.error('Failed to parse message:', message.body);
        }
      });

      subscriptionsRef.current[topic] = () => subscription.unsubscribe();

      console.log(`Subscribed to /topic/${topic}`);
    },
    [client, connected],
  );

  // Odhlášení z tématu
  const unsubscribe = useCallback((topic: string) => {
    if (subscriptionsRef.current[topic]) {
      subscriptionsRef.current[topic]();
      delete subscriptionsRef.current[topic];
      console.log(`Unsubscribed from /topic/${topic}`);
    }
  }, []);

  return { listen, unsubscribe, connected };
};
