import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Sidebar } from 'primereact/sidebar';
import RingilButton from '@components/ringil3/Buttons/RingilButton';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';
import { EntityTypeEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  visible: boolean;
  heading: string | JSX.Element;
  onContinue: () => void;
  onBack: () => void;
  loading?: boolean;
  continueLabel?: string | JSX.Element;
  ready?: boolean;
  showSaveButton?: boolean;
  applicationId: string;
  entityType: EntityTypeEnum;
}

const RingilForEntitySidebar: React.FC<PropsWithChildren<ComponentProps>> = ({
  visible,
  heading,
  onContinue,
  onBack,
  children,
  loading = false,
  continueLabel,
  ready = true,
  showSaveButton = true,
  applicationId,
  entityType,
}) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        onHide={onBack}
        visible={visible}
        style={{ width: '550px', backgroundColor: 'var(--ringil3-base-color)' }}
        position={'right'}
        icons={() => <SidebarHeader>{heading}</SidebarHeader>}
        closeOnEscape={true}
        dismissable={false}
        blockScroll={true}
      >
        <div className={'flex flex-col gap-4'}>
          <VerticalFieldAndValueWithIcon
            label={tr(`RingilForEntitySidebar.title${entityType}`, `RingilForEntitySidebar.title${entityType}`)}
            valueText={applicationId}
            iconPath={'/images/icons/ringil3/forExpedition.svg'}
          />
          <HorizontalLine variant={'tertiary'} />
          {children}
        </div>
        <SidebarButtons>
          {showSaveButton && (
            <RingilButton
              label={continueLabel ? continueLabel : tr(`RingilSidebar.continue`, `Continue`)}
              onClick={() => onContinue()}
              mode={'filled'}
              size={'big'}
              loading={loading}
            />
          )}

          <RingilButton label={tr(`RingilSidebar.back`, `Back`)} onClick={() => onBack()} mode={'text-only'} size={'big'} />
        </SidebarButtons>
      </Sidebar>
    </>
  );
};

const SidebarButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SidebarHeader = styled.div`
  display: flex;
  flex: 1;
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 24px;
  padding: 0.5rem 0;
`;
export default RingilForEntitySidebar;
