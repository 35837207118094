import React from 'react';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgCoButtonPlus from '@components/framework/buttons/icons/CoButtonPlus.tsx';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface ComponentProps {
  title: string;
  onClick: () => void;
  fullWidth?: boolean;
}

const AddButton: React.FC<ComponentProps> = ({ onClick, title, fullWidth = false }) => {
  return (
    <div className={'inline-flex'} onClick={onClick}>
      <div
        className={twMerge(
          'flex flex-row  gap-4 justify-between items-center p-4 rounded border border-solid border-content-gr2 bg-white cursor-pointer',
          clsx({ 'flex-grow': fullWidth == true }),
        )}
      >
        <div className={'text-content-brand text-base font-medium  flex-grow'}>{title}</div>
        <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} />
      </div>
    </div>
  );
};

export default AddButton;
