import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { useWebSocket } from '@hooks/useWebSocket/useWebSocket.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { InputSwitch } from 'primereact/inputswitch';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { dumpVars } from '@utils/utils.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface ComponentProps {}

interface OpenGateResponse {
  action: 'OPEN' | 'CLOSE';
}

const GatePoCPage: React.FC<ComponentProps> = () => {
  const { pocOpenGate } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [companyLocationId, setCompanyLocationId] = useState('7b0fc769-1473-4bab-8583-87d18dce79fb');
  const [externalCamId, setExternalCamId] = useState('');
  const [messages, setMessages] = useState<OpenGateResponse[]>([]);
  const [listening, setListening] = useState(false);

  const { listen, unsubscribe, connected } = useWebSocket<OpenGateResponse>();

  useEffect(() => {
    if (connected) {
      return () => {
        unsubscribe(`gate-opening/${companyLocationId}/${externalCamId}`);
      };
    }
  }, [connected, listen, unsubscribe]);

  const connect = () => {
    if (connected) {
      setListening(true);
      listen(`gate-opening/${companyLocationId}/${externalCamId}`, message => {
        setMessages(m => [...m, message]);
      });
    }
  };

  const openGate = () => {
    pocOpenGate(companyLocationId, { externalCamId }, () => {});
  };

  return (
    <div className={'p-3 flex flex-col gap-2'}>
      <COHeadline title={'Gate PoC'} variant={'h2'} />
      <div className={'flex flex-col gap-2 w-[600px]'}>
        <Field label={'Company Location ID'}>
          <RingilInputText value={companyLocationId} onChange={v => setCompanyLocationId(v)} />
        </Field>
        <Field label={'External CamID'}>
          <RingilInputText value={externalCamId} onChange={v => setExternalCamId(v)} />
        </Field>
      </div>
      <div>
        <div>Connected: {connected ? 'Yes' : 'No'}</div>
        <div>Listening: {listening ? 'Yes' : 'No'}</div>
      </div>
      <div className={'flex flex-row gap-2'}>
        <RingilButton label={'Start listening'} onClick={() => connect()} />
        <RingilButton label={'Simulate gate opening'} onClick={() => openGate()} />
      </div>
      <div>{dumpVars(messages)}</div>
    </div>
  );
};

export default withAuthenticationRequired(GatePoCPage);
