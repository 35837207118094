import React from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { dateFormatOnlyDateShort } from '@utils/date.tsx';
import { useVisibilityAction } from '@hooks/useVisibilityAction/useVisibilityAction.tsx';
import { CustomerNotificationAttachmentResponse } from '@api/logsteo-api.v2.tsx';
import { dumpVars } from '@utils/utils.tsx';
import SvgDocument from '@app/pages/attachment/Document.tsx';
import SvgDeleteIcon from '@app/pages/attachment/DeleteIcon.tsx';
import COItemDisplay from '@app/pages/COItemDisplay/COItemDisplay.tsx';
import { useDownload } from '@hooks/download/useDownload.tsx';
import SvgDownloadIcon from '@app/pages/attachment/DownloadIcon.tsx';

interface ComponentProps {
  unread: boolean;
  companyName: string;
  subject: string;
  message: string;
  since: Dayjs;
  till: Dayjs;
  onRead?: () => void;
  attachments: CustomerNotificationAttachmentResponse[];
}

const Message: React.FC<ComponentProps> = ({ companyName, subject, message, since, till, onRead, attachments, unread }) => {
  const { tr } = useTranslationLgs();

  const handleAction = () => {
    if (unread === true && onRead) {
      onRead();
    }
  };

  const elementRef = useVisibilityAction<HTMLDivElement>(handleAction, 5000, unread); // 2000 ms = 2 sekundy
  const { downloadFile, downloadingId } = useDownload();

  return (
    <div
      ref={elementRef}
      className={twMerge(
        clsx('flex flex-col gap-4  p-4 bg-base-tertiary border-solid border border-content-quaternary cursor-pointer', {
          'bg-white border-complementary-warning': unread,
        }),
      )}
    >
      <div role="message" className={'flex flex-col'}>
        <div className={'flex flex-row justify-between'}>
          <div className={'text-base font-bold'}>{subject}</div>
          <div>{companyName}</div>
        </div>
        <div className={'py-4 text-sm'}>{message}</div>
        {attachments?.map((attachment, attachmentIndex) => {
          return (
            <COItemDisplay
              className={'p-0 py-4'}
              key={attachmentIndex}
              icon={<SvgDocument />}
              label={attachment.name}
              rightIcon={
                <SvgDownloadIcon
                  className={twMerge('cursor-pointer', clsx({ 'animate-spin': downloadingId === attachment.id }))}
                  onClick={() => {
                    downloadFile(attachment.id);
                  }}
                />
              }
            />
          );
        })}
        <div role="date" className={'flex flex-row justify-between'}>
          <div className={'text-xs'}>
            {tr(`Message.sinceAndRemaining`, `od {{date}} | zbývá dnů: {{remaining}}`, {
              date: dateFormatOnlyDateShort(since),
              remaining: till.diff(since, 'days'),
            })}
          </div>
          {unread === true && <div className={'text-complementary-warning text-xs'}>{tr(`Message.newMessage`, `Nová zpráva`)}</div>}
        </div>
      </div>
    </div>
  );
};

export default Message;
