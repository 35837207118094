import React from 'react';
import { InputText } from 'primereact/inputtext';
import { isNullOrUndefined } from '@utils/utils.tsx';
import { twMerge } from 'tailwind-merge';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  readOnly?: boolean;
  className?: string;
  type?: string;
}

const RingilInputText: React.FC<ComponentProps> = ({ value, onChange, disabled, placeholder, fullWidth, className, type = 'text', readOnly = false }) => {
  const style = fullWidth ? { width: '100%' } : {};

  return (
    <>
      <input
        type={type}
        value={isNullOrUndefined(value) ? '' : value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        className={twMerge('h-[42px] w-full p-2 outline-none focus:ring-0 text-base', className)}
        style={style}
        readOnly={readOnly}
      />
    </>
  );
};

export default RingilInputText;
