import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { LovDTO } from '@api/logsteo-api.v2';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import DropdownLayout from '@components/obsolete/Form/DropdownLayout/DropdownLayout.tsx';
import { carriersAndGroupsTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  carrierId: string;
  onSuccess: () => void;
}

const AddToCarrierGroup: React.FC<ComponentProps> = ({ visible, onHide, carrierId, onSuccess }) => {
  const { t } = useTranslation('common');
  const { cuLoVCarrierGroups, cuAddCarrierGroupForCarrier } = useContext(ApiContext);
  const [groups, setGroups] = useState<LovDTO[]>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<LovDTO[]>();

  useEffect(() => {
    if (visible) {
      setSelectedGroup([]);
      clear();
      load();
    }
  }, [visible]);

  const load = () => {
    cuLoVCarrierGroups(carrierId, data => {
      setGroups(data.groups);
    });
  };

  const save = () => {
    cuAddCarrierGroupForCarrier(
      carrierId,
      {
        carrierGroupIds: selectedGroup.map(g => g.id),
      },
      () => {
        onSuccess();
      },
    );
  };

  const validationSchema = yup.array().min(1);
  const { clear, validationErrors, validateAndSend, find } = useValidation(validationSchema, save);

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal  sidebar-modal-add-new-carrier"
        style={{ width: '750px' }}
      >
        <h3 className="p-mb-4">{t(`AddToCarrierGroup.addCarriersToGroups`, `Add carriers to groups`)}</h3>
        {groups && (
          <InputLayout>
            <DropdownLayout classNameWrapper="p-col-12 p-md-8">
              <Label title={t(`AddToCarrierGroup.carrierGroupName`, `Carrier group name`)} required={true} />
              <MultiSelect
                display="chip"
                value={selectedGroup}
                options={groups}
                onChange={e => setSelectedGroup(e.value)}
                optionLabel="name"
                placeholder={t(`AddToCarrierGroup.selectAGroup`, `Select a group`)}
                filter
                className="multiselect-custom"
                style={{ width: '100%' }}
                itemTemplate={carriersAndGroupsTemplate}
              />
              <ValidationDiv errors={validationErrors} path={''} />
              {/*<div className="p-invalid-feedback">Hodnota musí být zadána</div>*/}
            </DropdownLayout>
          </InputLayout>
        )}

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t('labelAdd')} className="p-mr-2" onClick={() => validateAndSend(selectedGroup)} />
          <Button label={t('labelCancel')} onClick={() => onHide()} className="p-button-text" />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default AddToCarrierGroup;
