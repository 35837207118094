import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import { Column } from 'primereact/column';
import { AdminImportCompanyLocationRequest, AdminImportCompanyLocationResponse } from '@api/logsteo-api.v2';
import { useCreateInputPasteTabular } from '@components/framework/input/InputPasteTabular/InputPasteTabular.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';

interface ComponentProps {}

interface FormValues extends AdminImportCompanyLocationRequest {
  status: AdminImportCompanyLocationResponse;
}

const ImportCompanyLocation: React.FC<ComponentProps> = () => {
  const { adminImportCompanyLocation } = useContext(ApiContext);
  const [importData, setImportData] = useState([]);

  const { InputPasteTabular, PasteTabularButton } = useCreateInputPasteTabular<AdminImportCompanyLocationRequest>([
    'companyId',
    'locationName',
    'country',
    'zipCode',
    'city',
    'street',
    'concurrentReservation',
    'includeInternal',
    'contactOneName',
    'contactOnePhone',
    'contactOneEmail',
    'contactTwoName',
    'contactTwoPhone',
    'contactTwoEmail',
    'contactThreeName',
    'contactThreePhone',
    'contactThreeEmail',
    'contactFourName',
    'contactFourPhone',
    'contactFourEmail',
    'contactFiveName',
    'contactFivePhone',
    'contactFiveEmail',
    'visibility',
    'visibilityCompanies',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]);

  const { Table, Column } = useCreateTable<FormValues>();

  const importAction = () => {
    importData.forEach((t, tIndex) =>
      adminImportCompanyLocation(t, res => {
        setImportData(prev =>
          prev.map((r, rIndex) => {
            if (tIndex === rIndex) {
              return { ...r, status: res };
            }
            return r;
          }),
        );
      }),
    );
  };

  return (
    <div className={'flex flex-col gap-4'}>
      <h1>Import company location</h1>
      <div className={'flex flex-row gap-4 items-center'}>
        <PasteTabularButton />
        <a href={'/imports/importLocations.xlsx'}>Download template</a>
      </div>
      <h2>CompanyLocation to import</h2>

      <InputPasteTabular
        onSave={data => {
          setImportData(data);
        }}
      />

      <div className={'max-w-full'}>
        <Table value={importData}>
          <Column
            fixed
            width={160}
            body={r => <div className={r.status?.error ? 'text-complementary-error' : 'text-complementary-success'}>{r.status?.message}</div>}
            header="Status"
          />
          <Column fixed width={200} body={r => <div>{r.companyId}</div>} header="Company ID" />
          <Column fixed width={150} body={r => <div>{r.locationName}</div>} header="Location Name" />
          <Column width={150} body={r => <div>{r.country}</div>} header="Country" />
          <Column width={150} body={r => <div>{r.zipCode}</div>} header="Zip Code" />
          <Column width={150} body={r => <div>{r.city}</div>} header="City" />
          <Column width={150} body={r => <div>{r.street}</div>} header="Street" />
          <Column width={150} body={r => <div>{r.concurrentReservation}</div>} header="Concurrent Reservation" />
          <Column width={150} body={r => <div>{r.includeInternal}</div>} header="Include Internal" />
          <Column width={150} body={r => <div>{r.contactOneName}</div>} header="Contact One Name" />
          <Column width={150} body={r => <div>{r.contactOnePhone}</div>} header="Contact One Phone" />
          <Column width={250} body={r => <div>{r.contactOneEmail}</div>} header="Contact One Email" />
          <Column width={150} body={r => <div>{r.contactTwoName}</div>} header="Contact Two Name" />
          <Column width={150} body={r => <div>{r.contactTwoPhone}</div>} header="Contact Two Phone" />
          <Column width={250} body={r => <div>{r.contactTwoEmail}</div>} header="Contact Two Email" />
          <Column width={150} body={r => <div>{r.contactThreeName}</div>} header="Contact Three Name" />
          <Column width={150} body={r => <div>{r.contactThreePhone}</div>} header="Contact Three Phone" />
          <Column width={250} body={r => <div>{r.contactThreeEmail}</div>} header="Contact Three Email" />
          <Column width={150} body={r => <div>{r.contactFourName}</div>} header="Contact Four Name" />
          <Column width={150} body={r => <div>{r.contactFourPhone}</div>} header="Contact Four Phone" />
          <Column width={250} body={r => <div>{r.contactFourEmail}</div>} header="Contact Four Email" />
          <Column width={150} body={r => <div>{r.contactFiveName}</div>} header="Contact Five Name" />
          <Column width={150} body={r => <div>{r.contactFivePhone}</div>} header="Contact Five Phone" />
          <Column width={250} body={r => <div>{r.contactFiveEmail}</div>} header="Contact Five Email" />
          <Column width={150} body={r => <div>{r.visibility}</div>} header="Visibility" />
          <Column width={250} body={r => <div>{r.visibilityCompanies}</div>} header="Visibility Companies" />
          <Column width={150} body={r => <div>{r.monday}</div>} header="Monday" />
          <Column width={150} body={r => <div>{r.tuesday}</div>} header="Tuesday" />
          <Column width={150} body={r => <div>{r.wednesday}</div>} header="Wednesday" />
          <Column width={150} body={r => <div>{r.thursday}</div>} header="Thursday" />
          <Column width={150} body={r => <div>{r.friday}</div>} header="Friday" />
          <Column width={150} body={r => <div>{r.saturday}</div>} header="Saturday" />
          <Column width={150} body={r => <div>{r.sunday}</div>} header="Sunday" />
        </Table>
      </div>
      <RingilButton label={'Import'} onClick={() => importAction()} mode={'filled-big'} />
    </div>
  );
};

export default ImportCompanyLocation;

/**
 companyId,locationName,country,zipCode,city,street,concurrentReservation,includeInternal,contactOneName,contactOnePhone,contactOneEmail,contactTwoName,contactTwoPhone,contactTwoEmail,contactThreeName,contactThreePhone,contactThreeEmail,contactFourName,contactFourPhone,contactFourEmail,contactFiveName,contactFivePhone,contactFiveEmail,visibility,visibilityCompanies,monday,tuesday,wednesday,thursday,friday,saturday,sunday
 63da860f875c5b719450612d,Main Office,USA,12345,New York,123 Main St,10,True,John Doe,+123456789,john.doe@example.com,Jane Smith,+987654321,jane.smith@example.com,,,,,,,,,,PRIVATE,6504168bd8cab4708de0f3eb,"08:00-12:00,13:00-17:00","09:00-12:00,14:00-18:00","08:30-12:30,13:30-17:30","08:00-12:00,13:00-17:00","08:00-14:00,13:00-24:00",NONSTOP,

 */
