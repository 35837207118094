import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import { AdminGetUsersFilter, AdminUserOutDTO, PageResponseAdminUserOutDTO } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { NoWrapText, RowWithSpaceBetween } from '@components/styles.tsx';
import { EditUserSidebar } from '@components/obsolete/CompanyProfile/EditUserSidebar.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';

interface ComponentProps {
  companyId: string;
}

const AdminCompanyUsers: React.FC<ComponentProps> = ({ companyId }) => {
  const { adminGetUsers, adminCreateUserInAuth0 } = useContext(ApiContext);
  const [pageData, setPageData] = useState<PageResponseAdminUserOutDTO>();
  const { showToastMessage } = useContext(ToastContext);
  const [showPermisionForUser, setShowPermisionForUser] = useState<string>();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort } = useFilter<AdminGetUsersFilter>(
    (page, filter) => {
      console.log({
        Filter,
        applyFilterValue,
        filter,
        applyPaging,
        page,
        onPage,
      });
      load(page.pageSize, page.pageNumber, filter);
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  const load = (pageSize: number, pageNumber: number, filter: AdminGetUsersFilter) => {
    adminGetUsers({ pageSize, pageNumber }, { companyId }, d => {
      setPageData(d);
    });
  };

  /*  const onPage = (event) => {
    load(event.rows, event.page + 1, { companyId });
  };*/

  if (isNullOrUndefined(pageData)) return <></>;

  const renderActions = (row: AdminUserOutDTO) => {
    return (
      <NoWrapText>
        <Button
          label={'Create in Auth0'}
          onClick={e => {
            adminCreateUserInAuth0({ userId: row.id }, () => {
              showToastMessage('Success', 'User created in Auth0', 'success');
            });
          }}
        />
        <Button
          label={'Manage permission'}
          onClick={e => {
            setShowPermisionForUser(row.id);
          }}
        />
        <a href={`/?runAs=${row.id}`} target={'_blank'}>
          Login as user
        </a>
      </NoWrapText>
    );
  };

  return (
    <>
      <RowWithSpaceBetween>
        <h2>Company users</h2>
        <Button label={'Add user'} onClick={() => setShowPermisionForUser('_NEW_')} />
      </RowWithSpaceBetween>
      {/*// @ts-ignore*/}
      <DataTable
        value={pageData.data}
        rows={pageData.size}
        paginator={true}
        lazy={true}
        totalRecords={pageData.total}
        onPage={onPage}
        first={(pageData.page - 1) * pageData.size}
      >
        <Column header={'email'} field={'email'} style={{ width: '25rem' }} />
        <Column header={'firstName'} field={'firstName'} style={{ width: '10rem' }} />
        <Column header={'lastName'} field={'lastName'} style={{ width: '10rem' }} />
        <Column header={'companyName'} field={'companyName'} style={{ width: '10rem' }} />
        <Column header={'actions'} body={renderActions} style={{ width: '10rem' }} />
      </DataTable>
      <EditUserSidebar
        visible={isNotNullOrUndefined(showPermisionForUser)}
        onHide={() => {
          setShowPermisionForUser(undefined);
        }}
        onComplete={() => {
          setShowPermisionForUser(undefined);
          load(pageData.size, pageData.page, { companyId });
        }}
        userId={showPermisionForUser === '_NEW_' ? undefined : showPermisionForUser}
        companyId={companyId}
      />
    </>
  );
};

export default AdminCompanyUsers;
