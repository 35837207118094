import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { Sidebar } from 'primereact/sidebar';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ACLInDTO, CompanyLocationContactPersonOutDTO, ModulesEnum, VisibilityEnum } from '@api/logsteo-api.v2';
import ValidationDiv from '@utils/validation';
import useForm from '@hooks/useForm/useForm.tsx';
import { Col, RingilH3, Row } from '@components/styles.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputCountryPlain from '@components/obsolete/Form/InputCountry/InputCountryPlain.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import { ClearButton } from '@components/obsolete/Buttons/ClearButton/ClearButton.tsx';
import InputACL from '@components/obsolete/Form/InputACL/InputACL.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onCompleted: () => void;
  locationId?: string;
}

interface FormData {
  name: string;
  city: string;
  postalCode: string;
  streetNr: string;
  country: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  concurrentlyActiveEventsCount: number;
  isNotIncludedPrivateRamps: boolean;
  contacts: CompanyLocationContactPersonOutDTO[];
  acl: ACLInDTO;
  isExternal: boolean;
}

const initialData: FormData = {
  country: 'CZE',
  streetNr: '',
  city: '',
  name: '',
  contactEmail: '',
  contactName: '',
  contactPhone: '',
  postalCode: '',
  concurrentlyActiveEventsCount: null,
  isNotIncludedPrivateRamps: false,
  contacts: [],
  acl: { visibility: VisibilityEnum.PUBLIC, visibleToCompanies: [] },
  isExternal: false,
};

const AddEditLocationSidebar: React.FC<ComponentProps> = ({ visible, onHide, onCompleted, locationId }) => {
  const { tr } = useTranslationLgs();
  const { createCompanyLocations, getCompanyLocationById, updateCompanyLocations } = useContext(ApiContext);

  useEffect(() => {
    if (locationId) {
      getCompanyLocationById(locationId, d => {
        formik.setValues(d);
      });
    }
  }, [locationId]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(tr(`validation.required`, 'Value is required')),
    postalCode: yup.string().required(tr(`validation.required`, 'Value is required')),
    city: yup.string().required(tr(`validation.required`, 'Value is required')),
    streetNr: yup.string().required(tr(`validation.required`, 'Value is required')),
    contactEmail: yup.string().email(tr(`validation.valid_email`, 'Value has to be valid email address.')).required(tr(`validation.required`, 'Value is required')),
    contacts: yup.array().of(
      yup.object().shape({
        contactEmail: yup.string().email(tr(`validation.valid_email`, 'Value has to be valid email address.')).required(tr(`validation.required`, 'Value is required')),
        contactName: yup.string().required(tr(`validation.required`, 'Value is required')),
      }),
    ),
    acl: yup.mixed().test({
      name: 'required',
      test: (value: any, testContext) => {
        const v = testContext.parent as FormData;
        if (v.acl.visibility === VisibilityEnum.VISIBLE_TO_COMPANIES && v.acl.visibleToCompanies.length === 0) return false;
        return true;
      },
      message: 'required',
      exclusive: true,
    }),
  });

  const formik = useForm<FormData>(validationSchema, initialData, values => save(values), false, false);
  const names = formik.names;

  const addContactPerson = () => {
    formik.setFieldValue(names.contacts, [...formik.values.contacts, {}]);
  };
  const removeContactPerson = (deleteIndex: number) => {
    formik.setFieldValue(
      names.contacts,
      formik.values.contacts.filter((_, index) => index != deleteIndex),
    );
  };

  const save = (values: FormData) => {
    if (locationId) {
      updateCompanyLocations(locationId, values, () => {
        onCompleted();
      });
    } else {
      createCompanyLocations(values, () => {
        onCompleted();
      });
    }
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={onHide} blockScroll={true} style={{ minWidth: '40rem' }} closeOnEscape={false}>
        <ColumnRow>
          <InnerRow>
            <SidebarHeading>{tr(`AddEditLocationSidebar.addEditLocality`, `Add/Edit locality`)}</SidebarHeading>
          </InnerRow>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.localityName`, `Locality name`)}
              value={
                <>
                  <InputText style={{ width: '100%' }} value={formik.values.name} onChange={e => formik.setFieldValue(names.name, e.target.value)} />
                  <Error>{formik.errors.name}</Error>
                </>
              }
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.state`, `State`)}
              value={
                <>
                  <InputCountryPlain onChange={v => formik.setFieldValue(names.country, v)} codeValue={formik.values.country} style={{ width: '100%' }} />
                  <Error>{formik.errors.country}</Error>
                </>
              }
            />
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.zipCode`, `Zip code`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'postalCode'}
                    value={formik.values.postalCode}
                    onChange={e => formik.setFieldValue(names.postalCode, e.target.value)}
                  />
                  <Error>{formik.errors.postalCode}</Error>
                </>
              }
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.city`, `City`)}
              value={
                <>
                  <InputText style={{ width: '100%' }} name={'city'} value={formik.values.city} onChange={e => formik.setFieldValue(names.city, e.target.value)} />
                  <Error>{formik.errors.city}</Error>
                </>
              }
            />
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.streetNr`, `Street and number`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'streetNr'}
                    value={formik.values.streetNr}
                    onChange={e => formik.setFieldValue(names.streetNr, e.target.value)}
                  />
                  <Error>{formik.errors.streetNr}</Error>
                </>
              }
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.concurrentlyActiveEventsCountLabel`, `Concurrently active events count`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'concurrentlyActiveEventsCount'}
                    value={formik.values.concurrentlyActiveEventsCount}
                    onChange={e => formik.setFieldValue(names.concurrentlyActiveEventsCount, e.target.value)}
                  />
                  <Error>{formik.errors.concurrentlyActiveEventsCount}</Error>
                </>
              }
            />
            <HorizontalFieldValue
              label={tr(`AddEditRamp.isNotIncludedPrivateRamps`, `Not Include private ramps`)}
              value={<InputYesNoPlain value={formik.values.isNotIncludedPrivateRamps} onChange={v => formik.setFieldValue(names.isNotIncludedPrivateRamps, v)} />}
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.contactName`, `Contact name`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'contactName'}
                    value={formik.values.contactName}
                    onChange={e => formik.setFieldValue(names.contactName, e.target.value)}
                  />
                  <Error>{formik.errors.contactName}</Error>
                </>
              }
            />
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.contactPhone`, `Contact phone`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'contactPhone'}
                    value={formik.values.contactPhone}
                    onChange={e => formik.setFieldValue(names.contactPhone, e.target.value)}
                  />
                  <Error>{formik.errors.contactPhone}</Error>
                </>
              }
            />
          </Row>
          <Row>
            <HorizontalFieldValue
              label={tr(`AddEditLocationSidebar.contactEmail`, `Contact email`)}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'contactEmail'}
                    value={formik.values.contactEmail}
                    onChange={e => formik.setFieldValue(names.contactEmail, e.target.value)}
                  />
                  <Error>{formik.errors.contactEmail}</Error>
                </>
              }
            />
          </Row>

          <RingilH3>{tr(`AddEditLocationSidebar.additionalContacts`, `Additional contacts`)}</RingilH3>
          {formik.values.contacts.map((contact, contactIndex) => {
            return (
              <Row key={contactIndex}>
                <HorizontalFieldValue
                  label={tr(`AddEditLocationSidebar.contactName`, `Contact name`)}
                  value={
                    <>
                      <InputText
                        style={{ width: '100%' }}
                        name={'contactName'}
                        value={contact.contactName}
                        onChange={e => formik.setFieldValue(`${names.contacts}[${contactIndex}].contactName`, e.target.value)}
                      />
                      <Error>{formik.find(`${names.contacts}[${contactIndex}].contactName`)}</Error>
                    </>
                  }
                />
                <HorizontalFieldValue
                  label={tr(`AddEditLocationSidebar.contactEmail`, `Contact email`)}
                  value={
                    <>
                      <InputText
                        style={{ width: '100%' }}
                        name={'contactEmail'}
                        value={contact.contactEmail}
                        onChange={e => formik.setFieldValue(`${names.contacts}[${contactIndex}].contactEmail`, e.target.value)}
                      />
                      <Error>{formik.find(`${names.contacts}[${contactIndex}].contactEmail`)}</Error>
                    </>
                  }
                />
                <HorizontalFieldValue
                  label={tr(`AddEditLocationSidebar.contactPhone`, `Contact phone`)}
                  value={
                    <>
                      <InputWithButton>
                        <InputText
                          style={{ width: '100%' }}
                          name={'contactPhone'}
                          value={contact.contactPhone}
                          onChange={e => formik.setFieldValue(`${names.contacts}[${contactIndex}].contactPhone`, e.target.value)}
                        />
                        <ClearButton
                          onClick={() => {
                            removeContactPerson(contactIndex);
                          }}
                        />
                      </InputWithButton>
                      <Error>{formik.find(`${names.contacts}[${contactIndex}].contactPhone`)}</Error>
                    </>
                  }
                />
              </Row>
            );
          })}
          <Row>
            <Button label={tr(`AddEditLocationSidebar.addContact`, `Add contact`)} onClick={e => addContactPerson()} />
          </Row>

          <Col>
            <InputACL
              acl={formik.values.acl}
              moduleTypes={[ModulesEnum.SUPPLIER_NOTIFICATION, ModulesEnum.TIMESLOTS]}
              onChange={v => formik.setFieldValue(names.acl, v)}
            />
            <ValidationDiv errors={formik.validationErrors} path={'acl'} />
          </Col>

          <ButtonRow>
            <Button label={tr(`AddEditLocationSidebar.save`, `Save`)} onClick={() => formik.validateAndSend()}></Button>
            <Button
              label={tr(`AddEditLocationSidebar.back`, `Back`)}
              className={'p-button-text'}
              onClick={e => {
                onHide();
              }}
            ></Button>
          </ButtonRow>
        </ColumnRow>
      </Sidebar>
    </>
  );
};

const InputWithButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const ButtonRow = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
`;

const SidebarHeading = styled.div`
  display: flex;
  font-size: 1.5rem;
  color: black;
  margin-bottom: 1rem;
`;

const InnerRow = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
`;

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  display: flex;
  color: red;
`;

export default AddEditLocationSidebar;
