import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import * as yup from 'yup';

interface ComponentProps {
  title: string;
  onClick?: () => void;
}

const InputActionButton: React.FC<ComponentProps> = ({ title, onClick }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <div
        className={'flex flex-row gap-2 h-[36px] items-center py-[8px] px-[24px] text-normal leading-4 text-content-brand border-2 border-solid cursor-pointer'}
        onClick={e => onClick()}
      >
        {title}
      </div>
    </>
  );
};

export default InputActionButton;
