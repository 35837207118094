import dayjs, { Dayjs } from 'dayjs';
import { DayTimeSlot, DistributionTemplateView, DistributionView, DropdownItem } from '@type/types';
import { QtyUnit } from '@type/enums';
import {
  CargoTemplateWithoutIdDTO,
  CarrierOrGroupEnum,
  ContactPerson,
  ContactTypeEnum,
  DistributionTypeEnum,
  ExpeditionTypeEnum,
  GlobalTimeslotsDTO,
  InvoiceRebillingTypeEnum,
  InvoiceStateEnum,
  LoadingTypesEnum,
  LovDTO,
  OpeningEntityTypeEnum,
  PartnerLocationContactPersonInDTO,
  UserLovOutDTO,
  WeightModeEnum,
} from '@api/logsteo-api.v2';

export enum PageID {
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
  STEP4 = 'STEP4',
}

export enum RouteType {
  DIRECT = 'DIRECT',
  ROUND_TRIP = 'ROUND_TRIP',
}

export interface NewExpeditionView {
  currentPage: PageID;
  fromSupplierManifestId: string;
  fromSupplierNotificationId: string;
  routeType?: RouteType;
  locations?: Location[];
  expeditionType?: ExpeditionTypeEnum;
  truckType?: string;
  manipulationTypes?: LoadingTypesEnum[];
  specialRequirements?: string[];
  cargoType?: string;
  carrierNote?: string;
  adrWeight?: number;
  unloadAllLocationId?: string;
  distribution: DistributionView;
  distributionTemplate?: DistributionTemplateView;
  distributionTemplateApplied?: boolean;
  responsiblePerson?: UserLovOutDTO;
  expeditionTemplateId?: string;
  subscribersList: LovDTO[];
  internalNote?: string;

  invoiceUserId?: string;
  invoiceState?: InvoiceStateEnum;
  pairingSymbol?: string;
  invoiceRebillingType?: InvoiceRebillingTypeEnum;
  invoicePartnerId?: string;
  invoiceDepartment?: string;
  labelCodes: string[];
  duplicatedFromExpeditionId?: string;
  copySNAttachments: boolean;
  linkToSN: boolean;
  copyAttachments: boolean;
  applicationId?: string;
}

export interface CarrierOrGroup {
  type: CarrierOrGroupEnum;
  id: number;
  name: string;
}

export interface TemplateMetadata {
  appliedTemplate: boolean;
  templateId: number | null;
}

export interface TimeSlotsViewData {
  interval?: {
    from: Dayjs;
    to: Dayjs;
  };
  specifyTimeForDay?: boolean;
  timeSlots?: DayTimeSlot[];
}

export interface OrderItemViewData {
  cargoItemType?: CargoTemplateWithoutIdDTO;
  quantity: number;
  quantityUnit: string;
  height: number;
  width: number;
  length: number;
  weight: number;
  weightMode: WeightModeEnum;
  // used to make readonly input
  /*viewDisabledProperties?: string[];*/
  stackable: boolean;
  cargoMessage: string;
}

export interface OrderViewData {
  internalId: string;
  loadingLocationId: number;
  unloadingLocationId: number;
  name?: string;
  items?: OrderItemViewData[];
  totalWeight: number;
}

export interface Location {
  // index
  id: number;
  name?: string;
  streetNr?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  carrierCode?: string;
  loadingLocation: boolean;
  unloadingLocation: boolean;
  unloadAll: boolean;
  loadingOrders: OrderViewData[];
  timeslots: GlobalTimeslotsDTO;
  feKey?: string;
  contactPersons: ContactPerson[];
  appliedContactPerson?: PartnerLocationContactPersonInDTO;
  enabledEmailNotificationForContactPerson: boolean;
  additionalNotificationPersons?: PartnerLocationContactPersonInDTO[];
  locationId: string;
  contactType: ContactTypeEnum;
  openingHoursCoordinated: OpeningHoursCoordinates;
}

export const initialState: NewExpeditionView = {
  currentPage: PageID.STEP1,
  expeditionType: ExpeditionTypeEnum.FULL_TRUCK_LOAD,
  manipulationTypes: [LoadingTypesEnum.FROM_SIDE],
  routeType: RouteType.DIRECT,
  subscribersList: [],
  fromSupplierManifestId: null,
  fromSupplierNotificationId: null,
  distribution: {
    distributionType: DistributionTypeEnum.SPOT,
    enabled: false,
    responseDeadline: dayjs(),
    selectedItems: [],
    currency: 'CZK',
    price: 0,
    isPublished: true,
  },
  labelCodes: [],
  copyAttachments: false,
  copySNAttachments: false,
  linkToSN: false,
};

export const createEmptyOrderItem = (): OrderItemViewData => {
  return {
    weightMode: WeightModeEnum.TOTAL_WEIGHT,
    quantityUnit: QtyUnit.KS,
    cargoItemType: null,
    height: 0,
    length: 0,
    quantity: 0,
    weight: 0,
    width: 0,
    stackable: false,
    cargoMessage: null,
  };
};

export const createName = (locationName: string, counter: number) => `${locationName}-${counter}`;

export const createEmptyLocation = (locationIndex: number, loadingLocation: boolean = false, unloadingLocation: boolean = false): Location => {
  return {
    id: locationIndex,
    name: null,
    loadingLocation,
    unloadingLocation,
    loadingOrders: [],
    unloadAll: false,
    city: '',
    country: 'CZE',
    zipCode: '',
    streetNr: '',
    carrierCode: '',
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    contactPersons: [],
    timeslots: { dayWithInterval: [] },
    enabledEmailNotificationForContactPerson: false,
    contactType: null,
    locationId: null,
    openingHoursCoordinated: null,
  };
};

export const createEmptyOrder = (loadingLocationId: number, name: string, orderIndex?: number): OrderViewData => {
  return {
    internalId: loadingLocationId.toString(),
    unloadingLocationId: null,
    loadingLocationId,
    name: orderIndex ? `${name}-${orderIndex}` : name,
    items: [createEmptyOrderItem()],
    totalWeight: null,
  };
};

export const weightModes: DropdownItem[] = [
  {
    value: WeightModeEnum.TOTAL_WEIGHT,
    label: 'Celková váha',
  },
  {
    value: WeightModeEnum.SKU_WEIGHT,
    label: 'Váha jednotky (SKU)',
  },
];

export const qtyUnits: DropdownItem[] = [
  {
    value: QtyUnit.KS,
    label: 'KS',
  },
];

export interface OpeningHoursCoordinates {
  entityId: string;
  entityType: OpeningEntityTypeEnum;
}
