import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import { AccessTypeEnum, DocumentTypeEnum, EntityTypeEnum } from '@api/logsteo-api.v2.tsx';
import { Note } from '@components/styles.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { dumpVars, isNullOrUndefined } from '@utils/utils.tsx';
import { mapFromAPIToDateShort } from '@utils/date.tsx';
import COText from '@app/pages/COText/COText.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SvgIconFileBig from '@components/framework/icons/IconFileBig.tsx';
import PDFViewerDialog from '@components/obsolete/PDFViewer/PDFViewerDialog.tsx';
import TabularShowButton from '@components/ringil3/Buttons/TabularShowButton.tsx';
import TabularDeleteButton from '@components/ringil3/Buttons/TabularDeleteButton.tsx';
import Labels from '@components/obsolete/Labels/Labels.tsx';

export interface AttachmentListDTO {
  attachmentId: string;
  fileName: string;
  size: number;
  contentType: string;
  userId: string;
  userName: string;
  companyId: string;
  uploadedAt: string;
  companyName: string;
  accessType?: AccessTypeEnum;
  accessTypeName?: string;
  documentType?: DocumentTypeEnum;
  documentTypeName?: string;
  canBeDeleted: boolean;
  userNote?: string;
  userDate?: string;
}

interface ComponentProps {
  attachments: AttachmentListDTO[];
  disabled?: boolean;
  onDeletedAttachment?: (attachmentId: string) => void;
}

const AttachmentsList: React.FC<ComponentProps> = ({ attachments, onDeletedAttachment, disabled = false }) => {
  const { downloadAttachment, deleteAttachment } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const { Table, Column } = useCreateTable<AttachmentListDTO>();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const [previewFileDataURL, setPreviewFileDataURL] = useState<string>();

  const viewDocument = (attachmentId: string) => {
    downloadAttachment(attachmentId, data => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      setPreviewFileDataURL(linkSource);
    });
  };

  const onDeleteAttachmentHandler = (attachmentIdToDelete: string) => {
    if (onDeletedAttachment != null) {
      onDeletedAttachment(attachmentIdToDelete);
    }
    deleteAttachment(attachmentIdToDelete, () => {});
  };

  return (
    <>
      <COHeadline title={tr('AttachmentsList.prilohy', 'Přílohy')} icon={<SvgIconFileBig />} variant={'h2'} />
      <Table value={attachments || []} showMassActions>
        <Column
          body={d => (
            <div className={'flex flex-col gap-3'}>
              <div className={'flex flex-row gap-4 items-center'}>
                <div className={'cursor-pointer text-content-brand'}>{d.fileName}</div>
                <TabularShowButton onClick={() => viewDocument(d.attachmentId)} />
              </div>
              <Labels objectType={EntityTypeEnum.ATTACHMENTS} objectId={d.attachmentId} disabled={disabled} />
            </div>
          )}
          header={tr('AttachmentsList.nazev', 'Název')}
        />

        <Column
          body={d => (
            <div>
              <div>{d.userName}</div>
              <div>{d.companyName}</div>
            </div>
          )}
          header={tr('AttachmentsList.nahral', 'Nahrál/a')}
        />

        <Column
          body={d => (
            <div>
              <div>{mapFromAPIToDateShort(d.userDate)}</div>
              <Note>{d.userNote}</Note>
            </div>
          )}
          header={tr('AttachmentsList.datum', 'Datum')}
        />

        <Column
          body={d => (
            <div className={'flex flex-row'}>
              <div className={'bg-content-primary text-white self-start py-0.5 px-2 rounded'}>{tr(`TabDocuments.type_${d.documentType}`)}</div>
            </div>
          )}
          header={tr('AttachmentsList.typ', 'Typ')}
        />

        <Column body={d => <div>{mapFromAPIToDateShort(d.uploadedAt)}</div>} header={tr('AttachmentsList.nahrano', 'Nahráno')} />
        <Column
          body={d => (
            <div>
              {d.accessType === AccessTypeEnum.INTERNAL && (
                <div className={'flex flex-row'}>
                  <COText className={'bg-complementary-warningMedium text-content-primary self-start py-0.5 px-2 rounded'} label={tr('AttachmentsList.ano', 'Ano')} />
                </div>
              )}
            </div>
          )}
          header={tr('AttachmentsList.interni', 'Interní')}
        />

        <Column
          body={d => (
            <div>
              <TabularDeleteButton
                onClick={() => {
                  onDeleteAttachmentHandler(d.attachmentId);
                }}
              />
            </div>
          )}
          header={''}
        />
      </Table>
      <PDFViewerDialog pdfData={previewFileDataURL} onHide={() => setPreviewFileDataURL(null)} />
    </>
  );
};

export default AttachmentsList;
