import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ApiContext } from '@api/api';
import { Calendar } from 'primereact/calendar';
import { dateFormatOnlyDateShort, daysInterval, mapFromAPIToDateShort, shortDateFormat } from '@utils/date';
import {
  DayEnum,
  GlobalDayWithInterval,
  GlobalInterval,
  GlobalTimeslotsDTO,
  OpeningEntityTypeEnum,
  OpeningHourDefinitionsDTO,
  OpeningModeEnum,
} from '@api/logsteo-api.v2';
import { dumpVars, isNullOrUndefined, validateHourString } from '@utils/utils';
import dayjs, { Dayjs } from 'dayjs';
import { CenteredRowWithGap, Clickable, Note, RowWithGap, RowWithSpaceBetween } from '@components/styles';
import { Button } from 'primereact/button';
import InputHour from '../Form/InputHour/InputHour';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import ValidationDiv from '@utils/validation';
import { useStateCallback } from '@hooks/useStateCallback/useStateCallback.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import produce from 'immer';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';
import CompanyExceptionWarning from '@components/obsolete/CompanyExceptionWarning/CompanyExceptionWarning.tsx';

interface ComponentProps {
  entityId: string;
  entityType: OpeningEntityTypeEnum;
  timeslot: GlobalTimeslotsDTO;
  onChange: (timeslot: GlobalTimeslotsDTO) => void;
  noOpeningHours: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
  externalLabel?: boolean;
}

export const createEmptyTimeslot = (): GlobalTimeslotsDTO => {
  return {
    dayWithInterval: [],
  };
};

export const timeslotValidationSchema = yup.object().shape({
  dayWithInterval: yup.array().of(
    yup.object().shape({
      day: yup.string().required(),
      intervalError: yup.mixed().test({
        name: 'intervals.not.disjunct',
        test: (value: any, testContext) => {
          const parent = testContext.parent as GlobalDayWithInterval;

          if (parent.intervals && parent.intervals.length === 0) {
            return true;
          }

          if (parent.openingMode === OpeningModeEnum.OPEN || parent.openingMode === OpeningModeEnum.CLOSED) {
            return true;
          }

          const a = parent.intervals
            .filter(t => t.sinceHoursString !== '' && t.tillHoursString !== '')
            .sort((a, b) => a.sinceHoursString.localeCompare(b.sinceHoursString))
            .map(t => {
              return [t.sinceHoursString, t.tillHoursString];
            })
            .flat(1)
            .filter(t => t !== '');

          const result = a.reduce((acc, cur) => {
            if (acc <= cur) return cur;
            else return 'Error';
          }, '00:00');

          return result !== 'Error';
        },
        message: 'required',
        exclusive: true,
      }),
      intervals: yup.array().of(
        yup.mixed().test({
          name: 'wrong.interval',
          test: (value: GlobalInterval, testContext) => {
            // @ts-ignore
            const parent = testContext.from[0].value as GlobalDayWithInterval;
            if (parent.openingMode === OpeningModeEnum.CLOSED || parent.openingMode === OpeningModeEnum.OPEN) {
              return true;
            }
            const since = value.sinceHoursString;
            const till = value.tillHoursString;
            if (validateHourString(since) && validateHourString(till)) {
              if (since < till) {
                return true;
              }
            }
            return false;
          },
          message: 'required',
          exclusive: true,
        }),
      ),
    }),
  ),
});

/**
 * This is used for the managing of timeslots, delivery windows, etc. The component should use the opening hours, if specified.
 * @param entityId
 * @param entityType
 * @param timeslot
 * @param onChange
 * @param noOpeningHours
 * @constructor
 */
const ManageTimeslot: React.FC<ComponentProps> = ({
  entityId,
  entityType,
  timeslot,
  onChange,
  noOpeningHours,
  validationErrors,
  validationPrefix,
  externalLabel = false,
}) => {
  const [openingHours, setOpeningHours] = useStateCallback<OpeningHourDefinitionsDTO>(null);
  const { getOpeningHoursForEntity } = useContext(ApiContext);
  const [range, setRange] = useState<Date[] | Date>();
  const { tr } = useTranslationLgs();

  const loadOpeningHours = () => isNullOrUndefined(entityId) || isNullOrUndefined(entityType) || isNullOrUndefined(timeslot);

  useEffect(() => {
    // load opening times
    if (!loadOpeningHours()) {
      getOpeningHoursForEntity(entityId, entityType, d => {
        setOpeningHours(d, state => {
          a(state);
        });
      });
    }
  }, [loadOpeningHours()]);

  const a = (openingHourDefinition: OpeningHourDefinitionsDTO) => {
    if (haNoTimeslot()) {
      // no timeslots
      //const date = new Date();
      //const range = [date, date];
      //changeRange(range, openingHourDefinition);
    } else {
      /*const range = [
        dayjs(timeslot.dayWithInterval[0].day).toDate(),
        dayjs(timeslot.dayWithInterval[timeslot.dayWithInterval.length - 1].day).toDate(),
      ];
      changeRange(range, openingHourDefinition);*/
    }
  };

  const normalizeInterval = (d: Date | Date[]): Dayjs[] => {
    if (Array.isArray(d)) {
      if (d[0] == d[1]) return [dayjs(d[0])];
      return [dayjs(d[0]), dayjs(d[1])];
    } else {
      return [dayjs(d)];
    }
  };

  const regenerate = (range: Date[] | Date, openingHours?: OpeningHourDefinitionsDTO): GlobalTimeslotsDTO => {
    const normalizedRange = normalizeInterval(range);
    const days = daysInterval(normalizedRange[0], normalizedRange[1]);
    console.log('Regenerate', openingHours);
    return {
      dayWithInterval: days.map(day => {
        const openingHour = openingHours?.openingHoursDays?.find(oh => isSameDay(day, oh.day));
        const openingMode = openingHour?.openingMode ?? OpeningModeEnum.CLOSED;
        const intervals =
          openingHour?.openingHours.map(t => {
            return {
              sinceHoursString: t.since,
              tillHoursString: t.till,
            } as GlobalInterval;
          }) ?? [];

        return {
          day: day.toISOString(),
          openingMode,
          intervals,
        };
      }),
    };
  };

  const isSameDay = (day1: Dayjs, day2: DayEnum) => {
    return mapDaysDayToJava(day1.day()) === day2;
  };

  const mapDaysDayToJava = (day: number) => {
    switch (day) {
      case 0:
        return DayEnum.SUNDAY;
      case 1:
        return DayEnum.MONDAY;
      case 2:
        return DayEnum.TUESDAY;
      case 3:
        return DayEnum.WEDNESDAY;
      case 4:
        return DayEnum.THURSDAY;
      case 5:
        return DayEnum.FRIDAY;
      case 6:
        return DayEnum.SATURDAY;
    }
  };

  const haNoTimeslot = () => {
    return timeslot.dayWithInterval.length === 0;
  };

  const changeRange = (range: Date[] | Date, openingHourDefinition: OpeningHourDefinitionsDTO) => {
    setRange(range);
    const newTimeslots = regenerate(range, openingHourDefinition);
    onChange(newTimeslots);
  };

  const addInterval = (day: Dayjs) => {
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        dayWithInterval.intervals.push({
          sinceHoursString: '',
          tillHoursString: '',
        });
      }
    });
    onChange(newTimeslot);
  };

  const removeDay = (day: Dayjs, position: number) => {
    // remove position-nth day
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        dayWithInterval.intervals.splice(position, 1);
      }
    });
    onChange(newTimeslot);
  };

  const generateFromOpeningHours = (openingHours: OpeningHourDefinitionsDTO, day: Dayjs): { intervals: GlobalInterval[]; openingMode: OpeningModeEnum } => {
    const openingHour = openingHours?.openingHoursDays?.find(oh => isSameDay(day, oh.day));
    if (isNullOrUndefined(openingHour)) return { openingMode: OpeningModeEnum.CLOSED, intervals: [] };

    if (openingHour.openingMode === OpeningModeEnum.CLOSED) return { intervals: [], openingMode: OpeningModeEnum.CLOSED };

    if (openingHour.openingMode === OpeningModeEnum.OPEN)
      return {
        intervals: [{ sinceHoursString: '00:00', tillHoursString: '24:00' }],
        openingMode: OpeningModeEnum.OPEN,
      };

    const intervals = openingHour?.openingHours.map(t => {
      return {
        sinceHoursString: t.since,
        tillHoursString: t.till,
      } as GlobalInterval;
    }) ?? [{ sinceHoursString: '', tillHoursString: '' }];

    return { intervals, openingMode: OpeningModeEnum.PARTIALLY_OPEN };
  };

  const openDay = (day: Dayjs) => {
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        const generatedHours = generateFromOpeningHours(openingHours, dayjs(dayWithInterval.day));
        if (generatedHours.openingMode === OpeningModeEnum.CLOSED) {
          dayWithInterval.openingMode = OpeningModeEnum.PARTIALLY_OPEN;
          dayWithInterval.intervals = [{ sinceHoursString: '', tillHoursString: '' }];
        } else {
          dayWithInterval.openingMode = generatedHours.openingMode;
          dayWithInterval.intervals = generatedHours.intervals;
        }
      }
    });
    onChange(newTimeslot);
  };

  const closeDay = (day: Dayjs) => {
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        dayWithInterval.openingMode = OpeningModeEnum.CLOSED;
        dayWithInterval.intervals = [];
      }
    });
    onChange(newTimeslot);
  };

  const toggleMode = (day: Dayjs) => {
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        dayWithInterval.openingMode = dayWithInterval.openingMode == OpeningModeEnum.PARTIALLY_OPEN ? OpeningModeEnum.OPEN : OpeningModeEnum.PARTIALLY_OPEN;
        dayWithInterval.intervals = [{ sinceHoursString: '00:00', tillHoursString: '24:00' }];
      }
    });
    onChange(newTimeslot);
  };

  const changeSince = (day: Dayjs, position: number, since: string) => {
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        dayWithInterval.intervals[position].sinceHoursString = since;
      }
    });
    onChange(newTimeslot);
  };

  const changeTill = (day: Dayjs, position: number, till: string) => {
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        dayWithInterval.intervals[position].tillHoursString = till;
      }
    });
    onChange(newTimeslot);
  };

  const loadFromOpeningHours = (day: Dayjs) => {
    const newTimeslot = produce(timeslot, draft => {
      const dayWithInterval = draft.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
      if (dayWithInterval) {
        const generatedHours = generateFromOpeningHours(openingHours, dayjs(dayWithInterval.day));
        dayWithInterval.openingMode = generatedHours.openingMode;
        dayWithInterval.intervals = generatedHours.intervals;
      }
    });
    onChange(newTimeslot);
  };

  const differsFromOpeningHours = (day: Dayjs) => {
    if (isNullOrUndefined(openingHours)) return false;
    const dayWithInterval = timeslot.dayWithInterval.find(d => day.isSame(dayjs(d.day), 'day'));
    if (!dayWithInterval) return false;

    const generatedHours = generateFromOpeningHours(openingHours, dayjs(dayWithInterval.day));
    if (generatedHours.openingMode !== dayWithInterval.openingMode) return true;

    if (generatedHours.openingMode === OpeningModeEnum.CLOSED) return false;

    if (generatedHours.intervals.length !== dayWithInterval.intervals.length) return true;

    return generatedHours.intervals.some((interval, index) => {
      const tsInterval = dayWithInterval.intervals[index];
      return interval.sinceHoursString !== tsInterval.sinceHoursString || interval.tillHoursString !== tsInterval.tillHoursString;
    });
  };

  return (
    <SlotsInner externalLabel={externalLabel}>
      <>
        {/*{dumpVars({ entityId, entityType, range, timeslot, openingHours, isDirty: loadOpeningHours() })}*/}

        {timeslot ? (
          <>
            {entityType === OpeningEntityTypeEnum.LOCATION && <CompanyExceptionWarning customerLocationId={entityId} slots={timeslot} />}
            <Calendar
              showIcon={true}
              dateFormat={shortDateFormat}
              selectionMode={'range'}
              value={range}
              onChange={e => changeRange(e.value, openingHours)}
              style={{ width: '100%' }}
              locale={'cs'}
              hideOnDateTimeSelect={true}
            />
            {isNullOrUndefined(openingHours) && <Note>{tr(`ManageTimeslot.localityHasNoOpeningTimes`, `Locality has no opening times`)}</Note>}
            {timeslot.dayWithInterval?.map((ts, tsIndex) => {
              return (
                <DayInner key={tsIndex}>
                  <RowWithSpaceBetween>
                    <Day>
                      {dateFormatOnlyDateShort(dayjs(ts.day), false)} ({tr(`DayInWeek${dayjs(ts.day).day()}`, `DayInWeek${dayjs(ts.day).day()}`)})
                    </Day>
                    <RowWithGap>
                      {differsFromOpeningHours(dayjs(ts.day)) && (
                        <Button
                          label={tr(`ManageTimeslot.loadOpeningHours`, `Load opening hours`)}
                          className={'p-button-outlined'}
                          onClick={() => loadFromOpeningHours(dayjs(ts.day))}
                        />
                      )}
                    </RowWithGap>
                  </RowWithSpaceBetween>
                  {ts.openingMode === OpeningModeEnum.CLOSED && (
                    <CenteredRowWithGap>
                      <span>{tr(`OpeningHourDefinitionForm.isClosed`, `Is closed`)}</span>
                      <Button label={tr(`OpeningHourDefinitionForm.open`, `Open`)} className={'p-button-outlined'} onClick={() => openDay(dayjs(ts.day))} />
                    </CenteredRowWithGap>
                  )}
                  {ts.openingMode === OpeningModeEnum.OPEN && (
                    <FourGrid>
                      <InputHour value={'00:00'} readOnly={true} disabled={true} onChange={() => {}} />
                      <InputHour value={'24:00'} readOnly={true} disabled={true} onChange={() => {}} />
                      <CheckboxWithLabel
                        checkboxLabel={tr(`ManageTimeslot.openNonStop`, `Open non-stop`)}
                        value={true}
                        onChange={() => {
                          toggleMode(dayjs(ts.day));
                        }}
                        disabled={false}
                      />
                      <Button label={tr(`ManageTimeslot.itIsClosed`, `It is closed`)} className={'p-button-outlined'} onClick={() => closeDay(dayjs(ts.day))}></Button>
                    </FourGrid>
                  )}
                  {ts.openingMode === OpeningModeEnum.PARTIALLY_OPEN && (
                    <>
                      {ts.intervals?.map((hours, hIndex) => {
                        return (
                          <React.Fragment key={hIndex}>
                            <FourGrid>
                              <InputHour
                                value={hours.sinceHoursString}
                                readOnly={ts.openingMode === OpeningModeEnum.OPEN}
                                disabled={ts.openingMode === OpeningModeEnum.OPEN}
                                onChange={v => changeSince(dayjs(ts.day), hIndex, v)}
                              />
                              <InputHour
                                value={hours.tillHoursString}
                                readOnly={ts.openingMode === OpeningModeEnum.OPEN}
                                disabled={ts.openingMode === OpeningModeEnum.OPEN}
                                onChange={v => changeTill(dayjs(ts.day), hIndex, v)}
                              />

                              {hIndex === 0 ? (
                                <CheckboxWithLabel
                                  checkboxLabel={tr(`ManageTimeslot.openNonStop`, `Open non-stop`)}
                                  value={ts.openingMode === OpeningModeEnum.OPEN}
                                  onChange={() => {
                                    toggleMode(dayjs(ts.day));
                                  }}
                                  disabled={false}
                                />
                              ) : (
                                <div></div>
                              )}

                              {hIndex === 0 ? (
                                <Button
                                  label={tr(`ManageTimeslot.itIsClosed`, `It is closed`)}
                                  className={'p-button-outlined'}
                                  onClick={() => closeDay(dayjs(ts.day))}
                                ></Button>
                              ) : (
                                <Button
                                  label={tr(`ManageTimeslot.remove`, `Remove`)}
                                  className={'p-button-outlined'}
                                  onClick={() => removeDay(dayjs(ts.day), hIndex)}
                                ></Button>
                              )}
                            </FourGrid>
                            <ValidationDiv errors={validationErrors} path={`${validationPrefix}dayWithInterval[${tsIndex}].intervals[${hIndex}]`} />
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}
                  <ValidationDiv errors={validationErrors} path={`${validationPrefix}dayWithInterval[${tsIndex}].intervalError`} />
                  {ts.openingMode === OpeningModeEnum.PARTIALLY_OPEN && (
                    <AddWrapper>
                      <Clickable onClick={() => addInterval(dayjs(ts.day))}>{tr(`OpeningHourDefinitionForm.addWorkingHours`, `+ add working hours`)}</Clickable>
                    </AddWrapper>
                  )}
                </DayInner>
              );
            })}
          </>
        ) : (
          <div></div>
        )}
      </>
    </SlotsInner>
  );
};

export const printDeliverySlots = (timeslots: GlobalTimeslotsDTO, tr: any) => {
  return (
    <div>
      {timeslots.dayWithInterval.map((ts, tsIndex) => {
        return (
          <CenteredRowWithGap key={tsIndex}>
            <span>{mapFromAPIToDateShort(ts.day)}</span>
            {ts.openingMode === OpeningModeEnum.PARTIALLY_OPEN && <span>{ts.intervals.map(t => `${t.sinceHoursString}-${t.tillHoursString}`).join(', ')}</span>}
            {ts.openingMode === OpeningModeEnum.OPEN && <>{tr(`ManageTimeslot.openNonStop`, `Open non-stop`)}</>}
            {ts.openingMode === OpeningModeEnum.CLOSED && <>{tr(`ManageTimeslot.closed`, `Closed`)}</>}
          </CenteredRowWithGap>
        );
      })}
    </div>
  );
};

export const formatTimeslotInterval = (timeslots: GlobalTimeslotsDTO) => {
  if (isNullOrUndefined(timeslots)) return <>No slots</>;
  if (isNullOrUndefined(timeslots.dayWithInterval)) return <>{dumpVars(timeslots)}</>;

  const openedDays = timeslots.dayWithInterval.filter(t => t.openingMode !== OpeningModeEnum.CLOSED);
  if (openedDays.length < 1) return <></>;

  return `${dateFormatOnlyDateShort(dayjs(openedDays[0].day))} - ${dateFormatOnlyDateShort(dayjs(openedDays[openedDays.length - 1].day))}`;
};

export const isSomeOpenTimeslotsExists = (timeslots: GlobalTimeslotsDTO) => {
  if (isNullOrUndefined(timeslots)) return false;
  if (isNullOrUndefined(timeslots.dayWithInterval)) return false;

  const openedDays = timeslots.dayWithInterval.filter(t => t.openingMode !== OpeningModeEnum.CLOSED);
  return openedDays.length >= 1;
};

const FourGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 80px 1fr 120px;
  gap: 0.5rem;
  margin: 0.3rem 0;
`;

const Day = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.1rem;
`;

const DayInner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 0.5rem;
`;

const SlotsInner = styled.div<{ externalLabel: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 1rem;
  background-color: #e9ecef;
  ${props =>
    props.externalLabel &&
    css`
      width: 100%;
      margin: 0rem 0;
    `}
`;

const AddWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`;
export default ManageTimeslot;
