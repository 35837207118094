import React, { PropsWithChildren, useContext, useDeferredValue, useState } from 'react';
import SvgMenuDashboard from '../icons/menu/MenuDashboard.tsx';
import SvgMenuSettings from '../icons/menu/MenuSettings.tsx';
import SvgMenuUser from '../icons/menu/MenuUser.tsx';
import SvgMenuTms from '../icons/menu/MenuTms.tsx';
import SvgMenuTimeslot from '../icons/menu/MenuTimeslot.tsx';
import SvgMenuSn from '../icons/menu/MenuSn.tsx';
import SvgMenuContacts from '../icons/menu/MenuContacts.tsx';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { CompanyModuleEnum, FeaturesEnum, PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';
import { dumpVars, isNullOrUndefined, useLocalStorage } from '@utils/utils.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import PermissionError from '@components/ringil3/PermissionError/PermissionError.tsx';
import MenuHeader from '@app/layout/MenuHeader.tsx';
import Menu from '@app/layout/Menu.tsx';
import { MenuItemModel, SubMenuItemModel } from '@components/menu/types.ts';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { useAuth0 } from '@auth0/auth0-react';
import { Note } from '@components/styles.tsx';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from '@react-hooks-library/core';
import { useTopInformation } from '@components/obsolete/TopInformation/TopInformation.tsx';
import NotificationMenuItem from '@app/layout/NotificationMenuItem.tsx';
import CustomerNotificationMenuItem from '@app/layout/CustomerNotificationMenuItem.tsx';
import Customer2InheritorNotificationSidebar from '@components/ringil3/Features/Customer2InheritorNotification/sidebars/Customer2InheritorNotificationSidebar.tsx';
import { ApiContext } from '@api/api.tsx';
import SvgMenuReports from '@app/icons/menu/MenuReports.tsx';
import { useDebounce } from 'use-debounce';
import { useConditionalDebounce } from '@hooks/useDebounceConditional/useDebounceConditional.tsx';
import SvgContacts from '@components/ringil3/icons/Contacts.tsx';

interface ComponentProps {
  title?: string;
  oneOfPermission?: PermissionTypesEnum[];
}

const AdminLayout: React.FC<PropsWithChildren<ComponentProps>> = ({ title, oneOfPermission, children }) => {
  const [smallMenuForDebounce, setSmallMenu] = useState(false);
  const smallMenu = useConditionalDebounce<boolean>(smallMenuForDebounce, 700, t => !t);

  const [lockedMenu, setLockedMenu] = useLocalStorage('Layout.lockedMenuAdmin', true);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { logout } = useAuth0();
  const { i18n } = useTranslation();
  const { versionInfo, TopInformation } = useTopInformation();

  const { loggedUser, hasModule, hasPermission, hasPermissionAndModule, isCustomerAdmin, isCompanyAdmin, isCarrierAdmin } = useContext(AuthenticatedUserContext);
  const [visible, setVisible] = useState(false);

  if (oneOfPermission?.length > 0 && isNullOrUndefined(loggedUser)) return <>Loading...</>;
  const showMessagedFromOwners =
    loggedUser?.modules?.filter(t => t.inheritingModule === true).length > 0 || loggedUser?.modules?.some(t => t.companyModule === CompanyModuleEnum.CARRIER);

  const showNotificationAdmin =
    loggedUser?.modules?.filter(t => t.inheritingModule === false).length > 0 && !loggedUser?.modules?.some(t => t.companyModule === CompanyModuleEnum.CARRIER);

  if (oneOfPermission?.length > 0 && !hasPermission(oneOfPermission))
    return (
      <>
        <PermissionError permission={[PermissionTypesEnum.PERMISSION_TIMESLOT_READ, PermissionTypesEnum.PERMISSION_TIMESLOT_FULLREAD]} />
      </>
    );

  const appMenu: MenuItemModel[] = [
    {
      title: 'Companies',
      render: true,
      mode: 'open-inside',
      icon: <SvgContacts />,
      subMenu: [
        { title: 'Create company', render: true, url: '/admin/company' },
        { title: 'List companies', render: true, url: '/admin/companies' },
      ],
    },
    {
      title: 'Imports',
      render: true,
      mode: 'open-inside',
      icon: <SvgContacts />,
      subMenu: [
        { title: 'Import users', render: true, url: '/admin/import/users' },
        { title: 'Import company locations', render: true, url: '/admin/import/company-locations' },
      ],
    },
    {
      title: 'Security',
      render: true,
      mode: 'open-inside',
      icon: <SvgContacts />,
      subMenu: [
        { title: 'Users', render: true, url: '/admin/users' },
        { title: 'Security roles', render: true, url: '/admin/security/roles' },
      ],
    },
    {
      title: 'Tools',
      render: true,
      mode: 'open-inside',
      icon: <SvgContacts />,
      subMenu: [
        { title: 'I18N', render: true, url: '/admin/i18n' },
        { title: 'HTTP requests', render: true, url: '/admin/requests' },
        { title: 'Email requests', render: true, url: '/admin/email-monitoring' },
        { title: 'Find requests', render: true, url: '/admin/find-email-request' },
        { title: 'Resolved email requests', render: true, url: '/admin/email-monitoring2' },
        { title: 'Find resolved emails', render: true, url: '/admin/find-resolved-emails' },
      ],
    },
  ] as MenuItemModel[];

  const languages = [
    { name: tr(`AppTopbar.czech`, `Czech`), code: 'cs', countryCode: 'cz' },
    { name: tr('AppTopbar.english', 'English'), code: 'en', countryCode: 'gb' },
    { name: tr('AppTopbar.german', 'Deutsch'), code: 'de', countryCode: 'de' },
    {
      name: tr('AppTopbar.hungarian', 'Hungrarian'),
      code: 'hu',
      countryCode: 'hu',
    },
    { name: tr('AppTopbar.poland', 'Polish'), code: 'pl', countryCode: 'pl' },
  ];

  const changeLanguage = (selectedLocale: string) => {
    //i18n.changeLanguage(selectedLocale);
    window.location.href = `/${selectedLocale}/`;
  };

  const displaySelectedLanguage = (
    languages: {
      name: string;
      code: string;
      countryCode: string;
    }[],
    selectedLanguage: string,
  ) => {
    return {
      label: languages.find(l => l.code === selectedLanguage)?.name,
      icon: languages.find(l => l.code === selectedLanguage)?.countryCode,
    };
  };

  const selectedLang = displaySelectedLanguage(languages, i18n.language);

  const userMenu = [
    {
      title: selectedLang.label,
      icon: <img src={`/images/flags/${selectedLang.icon}.svg`} alt={selectedLang.label} width={24} />,
      mode: 'open-outside-bottom',
      render: true,
      subMenu: languages.map(
        lang =>
          ({
            title: (
              <div className={'flex flex-row gap-4'}>
                <img src={`/images/flags/${lang.countryCode}.svg`} alt={selectedLang.label} width={24} />
                <div>{lang.name}</div>
              </div>
            ),
            onClick: () => {
              changeLanguage(lang.code);
            },
            active: false,
            render: true,
          } as SubMenuItemModel),
      ),
    },
    {
      title: (
        <div>
          <div>
            {loggedUser?.firstName} {loggedUser?.lastName}
          </div>
          <Note>{loggedUser?.company}</Note>
        </div>
      ),
      icon: <SvgMenuUser />,
      mode: 'open-outside-bottom',
      render: true,
      subMenu: [
        {
          title: tr('Layout.profile', 'Profile'),
          url: nav.createNavigationLink(nav.urlFunctions.createMyProfile()),
          render: true,
        },
        {
          title: tr('Layout.logout', 'Logout'),
          onClick: () => logout({ logoutParams: { returnTo: window.location.origin } }),
          render: true,
        },
      ],
    },
  ] as MenuItemModel[];

  if (isNullOrUndefined(loggedUser)) <></>;

  return (
    <>
      {title && <title>{title}</title>}
      <TopInformation />
      <div className={'h-screen flex flex-row bg-base-tertiary min-w-[1280px] max-w-[1600px]'}>
        <aside
          className={twMerge(
            clsx('min-h-screen justify-between flex flex-col w-[270px] duration-150 bg-white shrink-0', {
              'w-[80px]': smallMenu && !lockedMenu,
            }),
          )}
          onMouseOut={() => {
            setSmallMenu(true);
          }}
          onMouseOver={() => {
            if (!visible) setSmallMenu(false);
          }}
        >
          <div className={'flex flex-col grow  overflow-auto'}>
            <MenuHeader smallMenu={smallMenu} lockedMenu={lockedMenu} setLockedMenu={setLockedMenu} />
            <div className={'flex flex-col grow overflow-auto'}>
              <Menu menu={appMenu} smallMenu={smallMenu} lockedMenu={lockedMenu} localStorageKey={'AdminLayout.menu1'} />
            </div>
          </div>
          <div className={'mb-5'}>
            <Menu menu={userMenu} smallMenu={smallMenu} lockedMenu={lockedMenu} localStorageKey={'AdminLayout.menu2'} />
          </div>
        </aside>
        <main className={'flex-1 flex overflow-hidden w-full mx-4  '}>
          <div className={'flex-1 overflow-y-scroll pl-0.5 pb-0.5 pr-0.5 gap-2 flex-col'}>{children}</div>
        </main>
      </div>
    </>
  );
};

export default AdminLayout;
