import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import { Clickable, RingilHeading, RowWithSpaceBetween } from '@components/styles';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ListShipmentPreviewOutDTO, PageResponseListShipmentPreviewOutDTO, SupplierNotificationTypeEnum, TypeEnum } from '@api/logsteo-api.v2';

import { mapFromAPIToDateShort, shortDateFormat } from '@utils/date';
import useNavigation from '@hooks/useNavigation';
import { fixBooleanValue, isNullOrUndefined, useLocalStorage } from '@utils/utils';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Link } from 'react-router-dom';
import { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import { LabelDiv } from '@components/obsolete/Labels/Labels.tsx';
import { isNotAgent } from '@components/obsolete/shipment/utils.ts';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';

interface ComponentProps {}

interface SupplierNotificationList {
  supplierNotificationId: string;
  supplierNotificationAppId: string;
  multiDemandName: string;
  loadingLocation: string;
  loadingDate: string;
  customerName: string;
  supplierName: string;
  expeditionAppId: string;
}

interface FilterType {
  arrivalAt?: string;
  departureAt?: string;
  showArchived?: boolean;
}

const SupplierShipments: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const navigation = useNavigation();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const { listShipment } = useContext(ApiContext);
  const [data, setData] = useState<PageResponseListShipmentPreviewOutDTO>(undefined);

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<FilterType>((page, filter) => {
    loadData(page.pageSize, page.pageNumber, filter);
  });

  const loadData = (pageSize: number, pageNumber: number, filter: FilterType) => {
    listShipment(
      { pageSize, pageNumber },
      {
        arrivalAt: filter?.arrivalAt,
        departureAt: filter?.departureAt,
        showArchived: filter?.showArchived,
      },
      d => {
        setData(d);
      },
    );
  };

  useEffect(() => {
    load();
  }, []);

  const load = () => {};

  const [revealTabTexts, setRevealTabTexts] = useLocalStorage<boolean>('ringil_tags_expanded', false);

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Card>
        <RowWithSpaceBetween>
          <RingilHeading>{tr(`SupplierShipments.transporationInformation`, `Transporation information`)}</RingilHeading>
          <Button
            label={tr(`SupplierShipments.createShipment`, `Create supplier shipment`)}
            onClick={() => {
              /*router.push('/shipments/create-shipment');*/
              navigation.navigate(navigation.urlFunctions.createShipmentCreate());
            }}
          />
        </RowWithSpaceBetween>
        <div className="p-mt-3 p-mb-2">
          <div className="p-formgroup-inline">
            <div className="p-field">
              <Calendar
                locale={'cs'}
                value={filter?.arrivalAt ? new Date(filter?.arrivalAt) : null}
                onChange={e => {
                  applyFilterValue('arrivalAt', (e.value as Date)?.toISOString());
                }}
                dateFormat={shortDateFormat}
                readOnlyInput
                showButtonBar={true}
                showIcon
                onClearButtonClick={() => applyFilterValue('arrivalAt', null)}
                placeholder={tr(`SupplierShipments.arrivalAt`, `Arrival at`)}
              />
            </div>
            <div className="p-field">
              <Calendar
                locale={'cs'}
                value={filter?.departureAt ? new Date(filter?.departureAt) : null}
                onChange={e => {
                  applyFilterValue('departureAt', (e.value as Date)?.toISOString());
                }}
                dateFormat={shortDateFormat}
                readOnlyInput
                showButtonBar={true}
                showIcon
                onClearButtonClick={() => applyFilterValue('departureAt', null)}
                placeholder={tr(`SupplierShipments.departureAt`, `Departure at`)}
              />
            </div>
            <div className="p-field-checkbox">
              <InputSwitch
                className="expedition-filters-switch"
                checked={fixBooleanValue(filter?.showArchived)}
                onChange={e => applyFilterValue('showArchived', e.value)}
              />
              <label>{tr(`SupplierShipments.showOnlyArchived`, `Show only archived`)}</label>
            </div>
          </div>
        </div>

        {data && (
          <>
            {/*
        // @ts-ignore*/}
            <DataTable
              value={data.data}
              rows={data.size}
              paginator={true}
              lazy={true}
              totalRecords={data.total}
              onPage={onPage}
              first={(data.page - 1) * data.size}
              paginatorPosition={'both'}
            >
              <Column
                header={tr(`SupplierShipments.id`, `ID`)}
                body={(row: ListShipmentPreviewOutDTO) => (
                  <div>
                    <Link to={navigation.createNavigationLink(navigation.urlFunctions.createShipmentDetail(row.id))}>
                      <Clickable>{row.applicationId}</Clickable>
                    </Link>
                    <BadgeRow style={{ gap: '0.5rem' }} className={`${revealTabTexts ? 'p-mt-6' : ''}`}>
                      {row.labels
                        .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
                        .map((t, tIndex) => (
                          <LabelDiv
                            bgColor={t.labelColor}
                            key={tIndex}
                            onClick={e => {
                              setRevealTabTexts(v => !v);
                            }}
                          >
                            {revealTabTexts && <div>{t.labelValue}</div>}
                          </LabelDiv>
                        ))}
                    </BadgeRow>
                  </div>
                )}
              ></Column>
              <Column header={tr(`SupplierShipments.sender`, `Sender`)} body={(row: ListShipmentPreviewOutDTO) => <div>{row.loadingCompanyName}</div>}></Column>
              <Column
                header={tr(`SupplierShipments.loadingLocation`, `Loading location`)}
                body={(row: ListShipmentPreviewOutDTO) => (
                  <div>
                    <div>{row.loadingLocationName}</div>
                    <div>{mapFromAPIToDateShort(row.departureAt)}</div>
                  </div>
                )}
              ></Column>
              <Column
                header={tr(`SupplierShipments.unloadingLocation`, `Unloading location`)}
                body={(row: ListShipmentPreviewOutDTO) => (
                  <div>
                    <div>{row.unloadingLocationName}</div>
                    <div>{mapFromAPIToDateShort(row.arrivalAt)}</div>
                  </div>
                )}
              ></Column>
              <Column
                header={tr(`SupplierShipments.supplierNotification`, `Supplier notification`)}
                body={(row: ListShipmentPreviewOutDTO) => (
                  <div>
                    {row.supplierNotificationCreated && (
                      <div>
                        {isNotAgent(loggedUser, row.entityOwnerCompanyId) ? (
                          <Link
                            to={navigation.createNavigationLink(
                              navigation.urlFunctions.createSupplierNotificationDetail(
                                row.supplierNotificationCreated.supplierNotificationId,
                                SupplierNotificationTypeEnum.FCA,
                              ),
                            )}
                          >
                            <Clickable>{row.supplierNotificationCreated.supplierNotificationApplicationId}</Clickable>
                          </Link>
                        ) : (
                          <>{row.supplierNotificationCreated.supplierNotificationApplicationId}</>
                        )}
                      </div>
                    )}
                  </div>
                )}
              ></Column>
              <Column
                header={tr(`SupplierShipments.groupDemand`, `Group demand`)}
                body={(row: ListShipmentPreviewOutDTO) => (
                  <div>
                    {row.groupDemandCreated && (
                      <>
                        {isNotAgent(loggedUser, row.entityOwnerCompanyId) ? (
                          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerGroupDemandDetail(row.groupDemandCreated.groupDemandId))}>
                            <Clickable>{row.groupDemandCreated.groupDemandName}</Clickable>
                          </Link>
                        ) : (
                          <>{row.groupDemandCreated.groupDemandName}</>
                        )}
                      </>
                    )}
                  </div>
                )}
              ></Column>
              <Column
                body={(row: ListShipmentPreviewOutDTO) => (
                  <div>
                    {!row.supplierNotificationCreated && isNotAgent(loggedUser, row.entityOwnerCompanyId) && (
                      <Button
                        label={tr(`SupplierShipments.createSupplierNotification`, `Create supplier notification`)}
                        onClick={e => {
                          /*router.push(`/supplier-notifications/process-shipment?fromShipmentId=${row.id}`);*/
                          navigation.navigate(navigation.urlFunctions.createSupplierNotificationFromShipment(row.id));
                        }}
                      />
                    )}
                  </div>
                )}
              />
            </DataTable>
          </>
        )}
      </Card>
    </>
  );
};

export default SupplierShipments;
