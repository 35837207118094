import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { dapOrderConfig } from '@app/pages/dap/config/orders.ts';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge.tsx';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import { Order } from '@components/framework/orders/common.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { createOrdersComponentValidationSchema } from '@components/framework/orders/validation.ts';
import { GlobalOrdersRequest } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  supplierNotificationId: string;
  applicationId: string;
  onSaved: () => void;
}

export const useCreateEditDAPOrdersSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditDAPOrdersSidebar: React.FC<ComponentProps> = ({ supplierNotificationId, applicationId, onSaved }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [apiRequest, setApiRequest] = useState(false);
    const { detailDAPSupplierNotification, updateOrdersDAPSupplierNotification } = useContext(ApiContext);

    const form = useForm<{
      orders: Order[];
    }>(createOrdersComponentValidationSchema(dapOrderConfig, tr), { orders: null }, d => save(d), false, false);

    const save = (values: { orders: Order[] }) => {
      updateOrdersDAPSupplierNotification(
        supplierNotificationId,
        {
          orders: form.values.orders.map(
            o =>
              ({
                orderName: o.orderName,
                items: o.items.map(i => ({
                  amount: i.amount,
                  cargoTemplateCode: i.skuCode,
                  width: i.width,
                  height: i.height,
                  length: i.length,
                  weight: i.weight,
                  note: i.itemNote,
                  stackable: i.stackable,
                })),
                cargoAmount: o.orderAmount,
                cargoDescription: o.orderDescription,
                orderValue: { price: o.orderValue, currency: o.orderValueCurrency },
              } as GlobalOrdersRequest),
          ),
        },
        () => {
          setVisible(false);
          onSaved();
        },
        null,
        {
          onValidationFailed: setBeValidationErrors,
        },
      );
    };

    useEffect(() => {
      const ac = new AbortController();

      detailDAPSupplierNotification(
        supplierNotificationId,
        d => {
          if (ac.signal.aborted) return;
          form.setFieldValue(
            'orders',
            d.orders.map(t => ({
              orderName: t.orderName,
              items: t.items.map(i => ({
                amount: i.amount,
                skuCode: i.cargoTemplate.code,
                stackable: i.stackable,
                itemNote: i.note,
                height: i.height,
                width: i.width,
                length: i.length,
                weight: i.weight,
              })),
              orderNote: t.orderNote,
              orderValue: t.orderValue?.price,
              orderValueCurrency: t.orderValue?.currency,
              orderAmount: t.cargoAmount,
              orderDescription: t.cargoDescription,
            })),
          );
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
      return () => {
        ac.abort();
      };
    }, [supplierNotificationId]);

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
            <RowWithSpaceBetween>
              <div>{tr('EditReservationOrdersSidebar.title', 'Edit reservation orders')}</div>
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map(t => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}
        >
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr(`EditReservationSlotSidebar.forReservation`, `For reservation`)}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />

            <HorizontalLine variant={'tertiary'} />
            <OrdersComponent form={form} config={dapOrderConfig} />
          </div>
        </RingilSidebar>
      </>
    );
  };

  return { EditDAPOrdersSidebar, visible, setVisible };
};
