import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import Heading from '@components/ringil3/Heading/Heading';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { EntityTypeEnum, ExpeditionDetailV3OutDTO, ExpeditionStatusEnum, FeaturesEnum } from '@api/logsteo-api.v2';
import { Panel, Ringil3BadgeRow } from '@components/ringil3/styles';
import DistanceBadge from '@components/ringil3/Badges/DistanceBadge';
import WeightBadge from '@components/ringil3/Badges/WeightBadge';
import PiecesBadge from '@components/ringil3/Badges/PiecesBadge';
import LocationFromBadge from '@components/ringil3/Badges/LocationFromBadge';
import LocationToBadge from '@components/ringil3/Badges/LocationToBadge';
import StateTag from '@components/ringil3/Tags/StateTag';
import ManipulationTag from '@components/ringil3/Tags/ManipulationTag';
import TruckTag from '@components/ringil3/Tags/TruckTag';
import CargoTypeTag from '@components/ringil3/Tags/CargoTypeTag';
import LabelsTag from '@components/ringil3/Tags/LabelsTag';
import RequirementsTag from '@components/ringil3/Tags/RequirementsTag';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import NavigationBreadCrumb from '@components/ringil3/BreadCrumb/NavigationBreadCrumb';
import ExpeditionLocation from '@components/ringil3/ExpeditionLocation/ExpeditionLocation';
import ExtraCosts from '@components/ringil3/Features/Expedition/ExtraCosts/ExtraCosts';
import LinkedSNBadge from '@components/ringil3/Badges/LinkedSNBadge';
import CarrierNoteTag from '@components/ringil3/Tags/CarrierNoteTag';
import AuditLog from '@components/ringil3/Features/Expedition/AuditLog/AuditLog';
import TabExpeditionStateNew from '@components/ringil3/Features/Expedition/TabExpeditionStateNew';
import TabExpeditionQuotes from '@components/ringil3/Features/Expedition/TabExpeditionQuotes';
import TabExpeditionAssignedPlus from '@components/ringil3/Features/Expedition/TabExpeditionAssignedPlus';
import SvgRoute from '@components/ringil3/icons/heading/Route';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent';
import SubscribeComponent from '@components/obsolete/Subscribe/SubscribeComponent.tsx';
import CustomerChatComponent from '@components/obsolete/Chat/CustomerChatComponent.tsx';
import { useCreateRingilExpeditionTabs } from '@components/ringil3/RingilTabs/RingilTabs.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import CustomsDetail from '@app/pages/Customs/CustomsDetail.tsx';
import { useWebSocket } from '@hooks/useWebSocket/useWebSocket.tsx';

interface ComponentProps {
  expeditionId: string;
}

interface ExpeditionMessage {
  expeditionId: string;
}

const ExpeditionDetail: React.FC<ComponentProps> = ({ expeditionId }) => {
  const { getExpeditionV3Detail, listExtraCostsForExpedition } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [expedition, setExpedition] = useState<ExpeditionDetailV3OutDTO>();
  const nav = useNavigation();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { listen, unsubscribe, connected } = useWebSocket<ExpeditionMessage>();

  useEffect(() => {
    if (connected) {
      listen(`expedition-changed/${expeditionId}`, message => {
        console.log('refresing...');
        load(new AbortController());
      });

      // Cleanup při odhlášení
      return () => {
        unsubscribe(`expedition/${expeditionId}`);
      };
    }
  }, [connected, expeditionId, listen, unsubscribe]);

  const [showDialog, setShowDialog] = useState(false);

  const { selectedPanel, RingilTabs } = useCreateRingilExpeditionTabs();
  const { load: loadAttachments, AttachmentComponent } = useCreateAttachmentComponent(false);

  const [selectedCarrierId, setSelectedCarrierId] = useState(undefined);

  useEffect(() => {
    const ac = new AbortController();
    if (isNotNullOrUndefined(expeditionId)) {
      load(new AbortController());
    }
    return () => {
      ac.abort();
    };
  }, [expeditionId]);

  const load = (ac: AbortController) => {
    getExpeditionV3Detail(expeditionId, d => {
      if (!ac.signal.aborted) {
        setExpedition(d);
      }
    });
  };

  const reloadExtraCosts = () => {
    listExtraCostsForExpedition(expedition.customerId, expedition.applicationId, data => {
      setExpedition(prev => {
        return { ...prev, extraCosts: data };
      });
    });
  };

  if (isNullOrUndefined(expedition)) return <></>;

  const hasQuotes = expedition.expeditionStatus !== ExpeditionStatusEnum.NEW && expedition.quotes.length > 0;
  const isAssignedPlus = [
    ExpeditionStatusEnum.ASSIGNED,
    ExpeditionStatusEnum.ACTIVE,
    ExpeditionStatusEnum.WAITING_FOR_DOCS,
    ExpeditionStatusEnum.READY_TO_SHIPMENT,
    ExpeditionStatusEnum.FINISHED,
    ExpeditionStatusEnum.CANCELED,
  ].includes(expedition.expeditionStatus);

  const isOwner = expedition.customerId === loggedUser?.companyId;

  return (
    <Test>
      <BreadCrumbComponent
        items={[
          {
            label: tr(`ExpeditionDetail.expeditionList`, `Expedition list`),
            url: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionList()),
          },
          { label: tr(`ExpeditionDetail.expeditionDetail`, `Expedition detail`) },
        ]}
      />
      <Heading
        title={expedition.applicationId}
        headingVariant={'h1'}
        labels={<LabelsTag objectId={expedition.id} objectType={EntityTypeEnum.EXPEDITION} resolveLabelsForType={EntityTypeEnum.EXPEDITION} />}
        buttons={[<SubscribeComponent key={1} customerId={expedition.customerId} applicationId={expedition.applicationId} />]}
      />
      <Ringil3BadgeRow>
        <LinkedSNBadge supplierNotificationLink={expedition.supplierNotificationLink} />
        <DistanceBadge distance={expedition.distance} />
        <PiecesBadge piecesCount={2} />
        <LocationFromBadge locationName={expedition.locations[0].name} />
        <LocationToBadge locationName={expedition.locations[expedition.locations.length - 1].name} />
        <WeightBadge weight={0} />
      </Ringil3BadgeRow>
      <Ringil3BadgeRow>
        <StateTag expeditionState={expedition.expeditionStatus} />
        <ManipulationTag loadingTypes={expedition.loadingTypes} />
        <TruckTag truckType={expedition.truckType} />
        <CargoTypeTag cargoTypeCode={expedition.cargoTypeCode} />
      </Ringil3BadgeRow>
      <Ringil3BadgeRow>
        <RequirementsTag valueCodes={expedition.requirementCodes} onChange={() => {}} expeditionId={expedition.id} onReload={() => load(new AbortController())} />
      </Ringil3BadgeRow>
      <Ringil3BadgeRow>
        <CarrierNoteTag carrierNote={expedition.carrierNote} />
      </Ringil3BadgeRow>
      <RingilTabs
        onMessagesClick={() => setSelectedCarrierId(expedition.assignedCarrierId)}
        showCustoms={loggedUser?.features?.some(t => t == FeaturesEnum.CUSTOMS && isOwner === true)}
      />
      {selectedPanel === 'expedition' && (
        <>
          {expedition.expeditionStatus === ExpeditionStatusEnum.NEW && <TabExpeditionStateNew expedition={expedition} onReload={() => load(new AbortController())} />}
          {/* assigning */}
          {expedition.expeditionStatus === 'ASSIGNING' && <TabExpeditionQuotes expedition={expedition} onReload={() => load(new AbortController())} />}
          {/* assigning */}
          {isAssignedPlus && <TabExpeditionAssignedPlus expedition={expedition} onReload={() => load(new AbortController())} />}
          <Panel>
            <COHeadline variant={'h2'} title={tr(`ExpeditionDetail.route`, `Route`)} icon={<SvgRoute />}></COHeadline>
            {expedition.locations.map((location, locationIndex) => {
              return (
                <React.Fragment key={locationIndex}>
                  <NavigationBreadCrumb />
                  <ExpeditionLocation location={location}></ExpeditionLocation>
                </React.Fragment>
              );
            })}
          </Panel>
        </>
      )}
      {selectedPanel === 'customs' && <>{isOwner && <CustomsDetail expeditionId={expedition.id} />}</>}
      {selectedPanel === 'internalInfoAndHistory' && (
        <>
          <ExtraCosts costs={expedition.extraCosts} companyId={expedition.customerId} applicationId={expedition.applicationId} onRemoved={() => reloadExtraCosts()} />
          <AuditLog applicationId={expedition.applicationId} customerId={expedition.customerId} />
          {[ExpeditionStatusEnum.NEW, ExpeditionStatusEnum.ASSIGNING].indexOf(expedition.expeditionStatus) === -1 && (
            <TabExpeditionQuotes
              expedition={expedition}
              onReload={() => load(new AbortController())}
              heading={tr('ExpeditionDetail.historyOfTheTenderProcedure', 'History of the tender procedure')}
            />
          )}
        </>
      )}
      {selectedPanel === 'attachments' && (
        <>
          <AttachmentComponent entityId={expeditionId} entityType={EntityTypeEnum.EXPEDITION} />
        </>
      )}
      <CustomerChatComponent
        companyId={expedition.customerId}
        entityId={expeditionId}
        entityType={EntityTypeEnum.EXPEDITION}
        visible={isNotNullOrUndefined(selectedCarrierId)}
        selectedCarrierId={selectedCarrierId}
        onHide={() => setSelectedCarrierId(undefined)}
        readOnly={false}
      />
    </Test>
  );
};

const Test = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--ringil3-brand-color);
    background: var(--ringil3-brand-color);
  }
`;

export default ExpeditionDetail;
