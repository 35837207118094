import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { InputText } from 'primereact/inputtext';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { AttachmentsUploadedOutDTO, CompanyModuleEnum } from '@api/logsteo-api.v2.tsx';
import * as yup from 'yup';
import InputCompanyModule from '@components/framework/input/InputCompanyModule/InputCompanyModule.tsx';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import { HorizontalLine } from '@components/styles.tsx';
import InputActionButton from '@components/framework/buttons/InputActionButton.tsx';
import InputLanguage from '@components/framework/input/InputLanguage/InputLanguage.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import UploadPanel from '@components/framework/uploadpanel/UploadPanel.tsx';
import SvgDocument from '@app/pages/attachment/Document.tsx';
import SvgDeleteIcon from '@app/pages/attachment/DeleteIcon.tsx';
import COItemDisplay from '@app/pages/COItemDisplay/COItemDisplay.tsx';

interface ComponentProps {
  onComplete: () => void;
}

interface Form {
  module: CompanyModuleEnum;
  since: string;
  till: string;
  messages: Message[];
  attachments: AttachmentsUploadedOutDTO[];
}

interface Message {
  subject: string;
  body: string;
  language: string;
}

export const useCreateCreateCompanyNotificationSidebar = () => {
  const [visible, setVisible] = useState(false);

  const CreateCompanyNotificationSidebar: React.FC<ComponentProps> = ({ onComplete }) => {
    const { createCustomerNotification } = useContext(ApiContext);
    const { tr } = useTranslationLgs();

    const form = useForm<Form>(
      yup.object().shape({
        module: yup.string().required(),
        since: yup.string().required(),
        till: yup.string().required(),
        messages: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              subject: yup.string().min(1).required(),
              language: yup.string().min(1).required(),
              body: yup.string().min(1).max(255).required(),
            }),
          ),
      }),
      {
        module: CompanyModuleEnum.EXPEDITION,
        since: '',
        till: '',
        messages: [{ subject: '', body: '', language: 'cs' }],
        attachments: [],
      },
      value => {
        save(value);
      },
      false,
      false,
      null,
      'scroll',
    );

    const save = (value: Form) => {
      createCustomerNotification(
        {
          companyModule: value.module,
          since: value.since,
          till: value.till,
          messages: value.messages,
          attachmentIds: value.attachments?.map(t => t.attachmentId),
        },
        () => {
          setVisible(false);
          onComplete();
        },
      );
    };

    const deleteFile = (index: number) => {
      form.setFieldValue(
        form.names.attachments,
        form.values.attachments.filter((_, i) => i !== index),
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          heading={tr(`CreateCompanyNotificationSidebar.header`, `Create company notification`)}
          onContinue={() => {
            form.validateAndSend();
          }}
          onBack={() => setVisible(false)}
        >
          <Field label={tr(`CreateCompanyNotificationSidebar.module`, `Modul`)} required errorMessage={form.errors.module}>
            <InputCompanyModule
              value={form.values.module}
              onChange={v => form.setFieldValue(form.names.module, v)}
              permittedModules={[CompanyModuleEnum.SUPPLIER_NOTIFICATION, CompanyModuleEnum.TIMESLOTS, CompanyModuleEnum.SHIPMENT, CompanyModuleEnum.EXPEDITION]}
            />
          </Field>
          <div className={'grid grid-cols-2 gap-4'}>
            <Field label={tr(`CreateCompanyNotificationSidebar.validSince`, `Platnost od`)} required errorMessage={form.errors.since}>
              <RingilCalendar value={form.values.since} onChange={v => form.setFieldValue(form.names.since, v)} />
            </Field>
            <Field label={tr(`CreateCompanyNotificationSidebar.validTill`, `Platnost do`)} required errorMessage={form.errors.till}>
              <RingilCalendar value={form.values.till} onChange={v => form.setFieldValue(form.names.till, v)} />
            </Field>
          </div>
          {/*<Tabs />*/}
          {form.values.messages.map((m, mIndex) => {
            return (
              <div className={'flex flex-col bg-white mx-[-15px] my-[15px] px-[15px] py-4'} key={mIndex}>
                <Field
                  label={tr(`CreateCompanyNotificationSidebar.labguage`, `Jazyk`)}
                  required
                  errorMessage={findValidationMessage(form.validationErrors, `messages[${mIndex}].language`, tr)}
                  buttonsMode={'actionButton'}
                  actionButtons={
                    <InputActionButton
                      title={tr('CreateCompanyNotificationSidebar.delete', 'Delete')}
                      onClick={() =>
                        form.setFieldValue(
                          form.names.messages,
                          form.values.messages.filter((_, index) => index != mIndex),
                        )
                      }
                    />
                  }
                >
                  <InputLanguage value={m.language} onChange={v => form.setFieldValue(`messages[${mIndex}].language`, v)} />
                </Field>
                <Field
                  label={tr(`CreateCompanyNotificationSidebar.subject`, `Zpráva`)}
                  required
                  errorMessage={findValidationMessage(form.validationErrors, `messages[${mIndex}].subject`, tr)}
                >
                  <InputText
                    placeholder={tr(`CreateCompanyNotificationSidebar.subjectPlaceholder`, `Předmět zprávy`)}
                    value={m.subject}
                    onChange={e => form.setFieldValue(`messages[${mIndex}].subject`, e.target.value)}
                  />
                </Field>
                <Field required errorMessage={findValidationMessage(form.validationErrors, `messages[${mIndex}].body`, tr)}>
                  <RingilTextArea
                    maxLength={255}
                    value={m.body}
                    onChange={v => {
                      form.setFieldValue(`messages[${mIndex}].body`, v);
                    }}
                    placeholder={tr(`CreateCompanyNotificationSidebar.textAreaPlaceholder`, `Text zprávy`)}
                  />
                </Field>
                <div className={'py-4'}>
                  <HorizontalLine variant={'tertiary'} />
                </div>
              </div>
            );
          })}

          <div className={'my-4'}>
            <AddButton
              title={tr(`CreateCompanyNotificationSidebar.addLanguage`, `Další jazyk`)}
              onClick={() => {
                form.setFieldValue('messages', [...form.values.messages, { subject: '', body: '', language: '' }]);
              }}
            />
          </div>
          <div>
            {form.values.attachments.map((attachment, attIndex) => {
              return (
                <div key={attIndex}>
                  <COItemDisplay
                    icon={<SvgDocument />}
                    label={attachment.fileName}
                    rightIcon={
                      <SvgDeleteIcon
                        className={'cursor-pointer'}
                        onClick={() => {
                          deleteFile(attIndex);
                        }}
                      />
                    }
                  />
                </div>
              );
            })}
          </div>
          <UploadPanel
            onSave={attachments => {
              form.setFieldValue('attachments', [...form.values.attachments, ...attachments]);
            }}
          />
        </RingilSidebar>
      </>
    );
  };

  return { CreateCompanyNotificationSidebar, visible, setVisible };
};

const enum Langs {
  cs = 'cs',
  en = 'en',
}
