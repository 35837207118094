import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { AdminHTTPRequestSettingsUpdateRequest, ListHTTPRequestResponse, RequestLogElastic } from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { isNullOrUndefined } from '@utils/utils';
import { mapFromAPIToDateLong } from '@utils/date';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import useForm from '@hooks/useForm/useForm.tsx';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { RingilH2, RowWithSpaceBetween } from '@components/styles.tsx';
import { ApiContext } from '@api/api.tsx';
import { Link } from 'react-router-dom';

interface ComponentProps {}

interface Filter {
  filterTrcUUID?: string;
  filterUserInfo?: string;
  filterUri?: string;
  filterStatuses?: string[];
  filterMethods?: string[];
}

const useSettings = () => {
  const [visible, setVisible] = useState(false);

  interface Props {
    onComplete: () => void;
  }

  const Settings: React.FC<Props> = ({ onComplete }) => {
    const { adminHttpRequestGetSettings, adminHttpRequestChangeSettings } = useContext(ApiContext);

    const form = useForm<AdminHTTPRequestSettingsUpdateRequest>(
      yup.object().shape({}),
      { enabled: false, disabledHttpStatuses: [], ignoredUrls: [] },
      d => save(d),
      false,
      false,
    );

    const save = (d: AdminHTTPRequestSettingsUpdateRequest) => {
      adminHttpRequestChangeSettings(d, () => {
        setVisible(false);
        onComplete();
      });
    };

    useEffect(() => {
      if (visible) {
        adminHttpRequestGetSettings(d => {
          form.overwriteValues({
            enabled: d.enabled,
            ignoredUrls: d.ignoredUrls,
            disabledHttpStatuses: d.disabledHttpStatuses,
          });
        });
      }
    }, [visible]);

    return (
      <>
        <RingilDialog
          visible={visible}
          onHide={() => setVisible(false)}
          onSave={() => {
            form.validateAndSend();
          }}
          headerText={'HTTP filter logging settings'}
        >
          <HorizontalFieldValue label={'Enabled'} value={<InputYesNoPlain value={form.values.enabled} onChange={v => form.setFieldValue(form.names.enabled, v)} />} />
        </RingilDialog>
      </>
    );
  };

  return {
    setVisible,
    Settings,
  };
};

const AllHTTPRequests: React.FC<ComponentProps> = () => {
  const { adminListHttpRequest } = useContext(ApiContext);
  const [data, setData] = useState<ListHTTPRequestResponse>();
  const { setVisible, Settings } = useSettings();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage } = useFilter<Filter>((page, filter) => {
    load(page.pageSize, page.pageNumber);
  });

  const load = (pageSize: number, pageNumger: number) => {
    adminListHttpRequest(page, filter, d => {
      setData(d);
    });
  };

  if (isNullOrUndefined(data)) return <></>;

  const methodRendered = (row: RequestLogElastic) => {
    return (
      <>
        <div>
          <span>{row.httpMethod}</span>
          <span>{row.uri}</span>
        </div>
        <div>HTTP {row.status}</div>
      </>
    );
  };

  const uuidRenderer = (row: RequestLogElastic) => {
    return (
      <>
        <div>
          <Link to={`/admin/requests/${row.id}`}>{row.trcUUID}</Link>
        </div>
        <div>
          <Link to={`/admin/requests/${row.id}`}>{row.userInfo}</Link>
        </div>
      </>
    );
  };

  const durationRenderer = (row: RequestLogElastic) => {
    return (
      <>
        <div>{row.durationInMS}</div>
      </>
    );
  };
  const dateRenderer = (row: RequestLogElastic) => {
    return (
      <>
        <div>{mapFromAPIToDateLong(row.trcRequestDate)}</div>
      </>
    );
  };

  return (
    <>
      <RingilH2>Requests</RingilH2>
      <RowWithSpaceBetween>
        <Row>
          <InputText
            value={filter.filterTrcUUID}
            onChange={e => applyFilterValue('filterTrcUUID', e.target.value)}
            style={{ width: '350px' }}
            placeholder={'Input trcUUID'}
          />
          <InputText
            value={filter.filterUserInfo}
            onChange={e => applyFilterValue('filterUserInfo', e.target.value)}
            style={{ width: '350px' }}
            placeholder={'Input userInfo'}
          />
          <SelectButton
            options={[
              { label: 'GET', value: 'GET' },
              { label: 'PUT', value: 'PUT' },
              { label: 'DELETE', value: 'DELETE' },
              { label: 'POST', value: 'POST' },
            ]}
            value={filter.filterMethods}
            onChange={e => applyFilterValue('filterMethods', e.value)}
            optionValue={'value'}
            optionLabel={'label'}
            multiple={true}
          />
          <SelectButton
            options={[
              { label: '500', value: '500' },
              { label: '200', value: '200' },
            ]}
            value={filter.filterStatuses}
            onChange={e => applyFilterValue('filterStatuses', e.value)}
            optionValue={'value'}
            optionLabel={'label'}
            multiple={true}
          />
        </Row>
        <Button icon="pi pi-sliders-v" onClick={() => setVisible(true)} />
      </RowWithSpaceBetween>
      {/*// @ts-ignore*/}
      <DataTable
        value={data.data}
        rows={page.pageSize}
        paginator={true}
        lazy={true}
        totalRecords={data.totalElements}
        onPage={onPage}
        first={(page.pageNumber - 1) * page.pageSize}
        paginatorPosition={'both'}
      >
        <Column header={'Date'} body={dateRenderer} style={{ width: '150px' }}></Column>
        <Column header={'Request UUID'} body={uuidRenderer} style={{ width: '370px' }}></Column>
        <Column header={'[ms]'} body={durationRenderer} style={{ width: '80px', textAlign: 'right' }}></Column>
        <Column header={'uri'} body={methodRendered}></Column>
      </DataTable>
      <Settings onComplete={() => {}} />
    </>
  );
};

const Row = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export default AllHTTPRequests;
