import React, { useContext, useEffect } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import SvgLkw from '@components/ringil3/icons/heading/Lkw.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SectionPanel from '@components/framework/panels/SectionPanel.tsx';
import SvgETransportSend from '@components/features/eTransport/icons/ETransportSend.tsx';
import Card from '@components/framework/card/Card.tsx';
import SvgMapPin from '@components/framework/icons/MapPin.tsx';
import SvgInfoCircle from '@components/framework/icons/InfoCircle.tsx';
import SvgTruck from '@components/framework/icons/Truck.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgSendIcon from '@components/ringil3/icons/SendIcon.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import { ETransportDTO } from '@api/logsteo-api.v2.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import ETransportReferenceNoTag from '@components/features/eTransport/tags/ETransportReferenceNoTag.tsx';
import ETransportTransportCompany from '@components/features/eTransport/tags/ETransportTransportCompany.tsx';
import ETransportLicencePlateTag from '@components/features/eTransport/tags/ETransportLicencePlateTag.tsx';
import ETransportDocumentTypeTag from '@components/features/eTransport/tags/ETransportDocumentType.tsx';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';
import ETransportInputTransportMode from '@components/features/eTransport/input/ETransportInputTransportMode.tsx';
import { dumpVars, isNullOrUndefined, parseNumberOrUndefined } from '@utils/utils.tsx';
import EtransportBorderCrossingDropdown from '@components/features/eTransport/input/EtransportBorderCrossingDropdown.tsx';
import ETransportInputRegion from '@components/features/eTransport/input/ETransportInputRegion.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import InputPriceWithCurrency from '@components/framework/input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import DeleteButton from '@components/ringil3/Buttons/DeleteButton.tsx';
import { useCreateInputPasteTabular } from '@components/framework/input/InputPasteTabular/InputPasteTabular.tsx';
import { ApiContext } from '@api/api.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { findValidationMessage, findValidationMessageRaw } from '@utils/validation.tsx';
import { validationSchema } from '@components/features/eTransport/validation.ts';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import { useCreateSendDocumentEtransportRO } from '@components/features/eTransport/dialog/useCreateSendDocument.tsx';

interface ComponentProps {
  reservationId: string;
  applicationId: string;
  onCompleted: () => void;
  initialData: ETransportDTO;
}

interface PasteObject {
  name: string;
  ean: string;
  nomenclature: string;
  quantity: number;
  weightNetto: string;
  price: string;
  currency: string;
}

const ETransportCreate: React.FC<ComponentProps> = ({ reservationId, onCompleted, initialData, applicationId }) => {
  const { tr } = useTranslationLgs();
  const { createETransport, updateETransport } = useContext(ApiContext);
  const { showToastMessage } = useContext(ToastContext);
  const { SendETransportToRO, visible, setVisible } = useCreateSendDocumentEtransportRO();

  const form = useForm<ETransportDTO>(validationSchema(tr), initialData, d => save(d), false, false, null, 'toast');
  const { InputPasteTabular, PasteTabularButton } = useCreateInputPasteTabular<PasteObject>([
    'name',
    'ean',
    'nomenclature',
    'quantity',
    'weightNetto',
    'price',
    'currency',
  ]);

  const fixNumber = (n: string) => {
    return n.replace(',', '.');
  };

  const convertPastedData = (row: PasteObject) => {
    return {
      ean: row.ean,
      name: row.name,
      nomenclature: row.nomenclature,
      quantity: row.quantity,
      weightNetto: parseNumberOrUndefined(fixNumber(row.weightNetto)),
      priceExVat: { price: parseNumberOrUndefined(fixNumber(row.price)), currency: row.currency },
    };
  };

  const save = (d: ETransportDTO) => {
    setVisible(true);
    /*if (isNullOrUndefined(d.id)) {
      createETransport(reservationId, d, () => {
        showToastMessage(
          tr(`ETransportCreate.successSummary`, `Transport was created`),
          tr(`ETransportCreate.successDetail`, `Dokument jste odeslali. Nyní čekáme na potvzení. `),
          'success',
        );
        onCompleted();
      });
    } else {
      updateETransport(reservationId, d, () => {
        showToastMessage(
          tr(`ETransportCreate.successUpdateSummary`, `Transport was updated`),
          tr(`ETransportCreate.successUpdateDetail`, `Dokument jste odeslali. Nyní čekáme na potvzení. `),
          'success',
        );
        onCompleted();
      });
    }*/
  };

  const originCrossing = form.values.transportMode == 1 || form.values.transportMode == 4;
  const destinationCrossing = form.values.transportMode == 3 || form.values.transportMode == 4;

  useEffect(() => {
    if (originCrossing == false) {
      form.setFieldValue('originAddress.borderId', null);
    } else {
      form.setFieldValue('originAddress.town', null);
      form.setFieldValue('originAddress.street', null);
      form.setFieldValue('originAddress.regionId', null);
    }
    if (destinationCrossing == false) {
      form.setFieldValue('destinationAddress.borderId', null);
    } else {
      form.setFieldValue('destinationAddress.town', null);
      form.setFieldValue('destinationAddress.street', null);
      form.setFieldValue('destinationAddress.regionId', null);
    }
  }, [originCrossing, destinationCrossing]);

  const productValidationMessage = findValidationMessageRaw(form.validationErrors, form.names.products);
  const findProductErrorMessage = (index: number, name: string) => {
    const field = form.names.products + '[' + index + '].' + name;
    return findValidationMessage(form.validationErrors, field, tr);
  };

  return (
    <div className={'flex flex-col gap-4'}>
      <COHeadline variant={'h2'} title={tr(`ETransportCreate.transportInfo`, `Informace k přepravě`)} icon={<SvgLkw />} />
      <div className={'flex flex-row gap-2'}>
        <ETransportReferenceNoTag
          value={form.values.referenceNumber}
          onChange={v => form.setFieldValue(form.names.referenceNumber, v)}
          errorMessage={findValidationMessage(form.validationErrors, form.names.referenceNumber, tr)}
        />

        <ETransportTransportCompany
          value={form.values.transportCompany}
          onChange={v => form.setFieldValue(form.names.transportCompany, v)}
          validationErrors={form.validationErrors}
          validationPrefix={form.names.transportCompany}
        />

        <ETransportLicencePlateTag
          value={form.values.licencePlate}
          onChange={v => form.setFieldValue(form.names.licencePlate, v)}
          errorMessage={findValidationMessage(form.validationErrors, form.names.licencePlate, tr)}
        />

        <ETransportDocumentTypeTag
          value={form.values.document}
          onChange={v => form.setFieldValue(form.names.document, v)}
          validationErrors={form.validationErrors}
          validationPrefix={form.names.document}
        />
      </div>
      <SectionPanel icon={<SvgETransportSend />} heading={tr(`ETransportCreate.transportInfo`, `O transportu`)}>
        <div className={'flex flex-row gap-4'}>
          <Card>
            <div className={'flex flex-col gap-4'}>
              <COHeadline title={tr(`ETransportCreate.about`, `About`)} variant={'h4'} icon={<SvgMapPin />} />
              <Field
                label={tr('ETransportCreate.supplierName', 'Supplier name')}
                required={true}
                errorMessage={findValidationMessage(form.validationErrors, form.names.supplierName, tr)}
              >
                <InputText value={form.values.supplierName} onChange={e => form.setFieldValue(form.names.supplierName, e.target.value)} />
              </Field>
              <Field
                label={tr('ETransportCreate.dateOfTransport', 'Date of transport')}
                required={true}
                errorMessage={findValidationMessage(form.validationErrors, form.names.transportDate, tr)}
              >
                <RingilCalendar value={form.values.transportDate} onChange={v => form.setFieldValue(form.names.transportDate, v)} fullWidth />
              </Field>
            </div>
          </Card>
          <Card>
            <div className={'flex flex-col gap-4'}>
              <COHeadline title={tr(`ETransportCreate.transportModeHeading`, `Transport mode`)} variant={'h4'} icon={<SvgInfoCircle />} />
              <Field
                label={tr(`ETransportCreate.transportMode`, `Transport mode`)}
                required={false}
                errorMessage={findValidationMessage(form.validationErrors, form.names.transportMode, tr)}
              >
                <ETransportInputTransportMode value={form.values.transportMode} onChange={v => form.setFieldValue(form.names.transportMode, v)} fullWidth />
              </Field>
              <COHeadline title={tr(`ETransportCreate.addressOrigion`, `Address origion`)} variant={'h4'} icon={<SvgMapPin />} />

              <Field
                label={tr(`ETransportCreate.borderCrossing`, `Border crossing`)}
                required={originCrossing}
                disabled={!originCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'originAddress.borderId', tr)}
              >
                <EtransportBorderCrossingDropdown value={form.values.originAddress?.borderId} onChange={v => form.setFieldValue('originAddress.borderId', v)} />
              </Field>
              <Field
                label={tr(`ETransportCreate.region`, `Region`)}
                required={!originCrossing}
                disabled={originCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'originAddress.regionId', tr)}
              >
                <ETransportInputRegion value={form.values.originAddress?.regionId} onChange={v => form.setFieldValue('originAddress.regionId', v)} />
              </Field>
              <Field
                label={tr(`ETransportCreate.town`, `Town`)}
                required={!originCrossing}
                readOnly={originCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'originAddress.town', tr)}
              >
                <RingilInputText value={form.values.originAddress?.town} onChange={e => form.setFieldValue('originAddress.town', e)} fullWidth />
              </Field>
              <Field
                label={tr(`ETransportCreate.streetHouseNo`, `Street, house no.`)}
                required={!originCrossing}
                readOnly={originCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'originAddress.street', tr)}
              >
                <RingilInputText value={form.values.originAddress?.street} onChange={e => form.setFieldValue('originAddress.street', e)} fullWidth />
              </Field>

              <COHeadline title={tr(`ETransportCreate.addressDestination`, `Address destination`)} variant={'h4'} icon={<SvgMapPin />} />
              <Field
                label={tr(`ETransportCreate.borderCrossing`, `Border crossing`)}
                required={destinationCrossing}
                disabled={!destinationCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'destinationAddress.borderId', tr)}
              >
                <EtransportBorderCrossingDropdown value={form.values.destinationAddress?.borderId} onChange={v => form.setFieldValue('destinationAddress.borderId', v)} />
              </Field>
              <Field
                label={tr(`ETransportCreate.region`, `Region`)}
                required={!destinationCrossing}
                readOnly={destinationCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'destinationAddress.regionId', tr)}
              >
                <ETransportInputRegion value={form.values.destinationAddress?.regionId} onChange={v => form.setFieldValue('destinationAddress.regionId', v)} />
              </Field>
              <Field
                label={tr(`ETransportCreate.town`, `Town`)}
                required={!destinationCrossing}
                readOnly={destinationCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'destinationAddress.town', tr)}
              >
                <RingilInputText value={form.values.destinationAddress?.town} onChange={e => form.setFieldValue('destinationAddress.town', e)} fullWidth />
              </Field>
              <Field
                label={tr(`ETransportCreate.streetHouseNo`, `Street, house no.`)}
                required={!destinationCrossing}
                readOnly={destinationCrossing}
                errorMessage={findValidationMessage(form.validationErrors, 'destinationAddress.street', tr)}
              >
                <RingilInputText value={form.values.destinationAddress?.street} onChange={e => form.setFieldValue('destinationAddress.street', e)} fullWidth />
              </Field>
            </div>
          </Card>
        </div>

        <div>
          <Card>
            <div className={'flex flex-col gap-4'}>
              <div className={'flex flex-row justify-between'}>
                <COHeadline title={tr(`ETransportCreate.manipulation`, `Manipulation`)} variant={'h4'} icon={<SvgTruck />} />
                <PasteTabularButton />
              </div>
              {form.values.products?.length > 0 && (
                <div className={'grid  grid-cols-[1fr,1fr,1fr,5rem,5rem,1fr] gap-2'}>
                  {form.values.products?.map((p, pIndex) => {
                    const prefix = form.names.products + '[' + pIndex + '].';
                    return (
                      <React.Fragment key={pIndex}>
                        <Field label={tr(`ETransportCreate.productNameRo`, `Product name (RO)`)} errorMessage={findProductErrorMessage(pIndex, 'name')}>
                          <InputTextarea value={p.name} onChange={e => form.setFieldValue(prefix + 'name', e.target.value)} />
                        </Field>
                        <Field label={tr(`ETransportCreate.ean`, `EAN`)} errorMessage={findProductErrorMessage(pIndex, 'ean')}>
                          <InputText value={p.ean} onChange={e => form.setFieldValue(prefix + 'ean', e.target.value)} />
                        </Field>
                        <Field label={tr(`ETransportCreate.customsNomencalture`, `Customs nomencalture`)} errorMessage={findProductErrorMessage(pIndex, 'nomenclature')}>
                          <InputText value={p.nomenclature} onChange={e => form.setFieldValue(prefix + 'nomenclature', e.target.value)} />
                        </Field>
                        <Field label={tr(`ETransportCreate.quantity`, `Quantity`)} errorMessage={findProductErrorMessage(pIndex, 'quantity')}>
                          <InputText value={p.quantity} onChange={e => form.setFieldValue(prefix + 'quantity', e.target.value)} />
                        </Field>
                        <Field label={tr(`ETransportCreate.netWeight`, `Net weight`)} errorMessage={findProductErrorMessage(pIndex, 'weightNetto')}>
                          <InputText value={p.weightNetto} onChange={e => form.setFieldValue(prefix + 'weightNetto', e.target.value)} />
                        </Field>
                        <Field
                          label={tr(`ETransportCreate.valueWithoutVat`, `Value without VAT`)}
                          errorMessage={findProductErrorMessage(pIndex, 'priceExVat')}
                          actionButtons={
                            <DeleteButton
                              onClick={() =>
                                form.setFieldValue(
                                  form.names.products,
                                  form.values.products.filter((_, index) => index !== pIndex),
                                )
                              }
                            />
                          }
                        >
                          <InputPriceWithCurrency
                            price={p.priceExVat?.price}
                            currency={p.priceExVat?.currency}
                            changePrice={v =>
                              form.setFieldValue(
                                form.names.products,
                                form.values.products.map((t, index) =>
                                  index == pIndex
                                    ? {
                                        ...t,
                                        priceExVat: { price: v, currency: t.priceExVat.currency },
                                      }
                                    : t,
                                ),
                              )
                            }
                            changeCurrency={v => {
                              form.setFieldValue(
                                form.names.products,
                                form.values.products.map((t, index) =>
                                  index == pIndex
                                    ? {
                                        ...t,
                                        priceExVat: { price: t.priceExVat.price, currency: v },
                                      }
                                    : t,
                                ),
                              );
                            }}
                          />
                        </Field>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}

              {form.values.products?.length > 0 && <hr className={'bg-content-tertiary h-[1px] border-0'} />}
              {productValidationMessage && <COInlineMessage variant={'error'}>{productValidationMessage.message}</COInlineMessage>}

              <AddButton
                title={tr(`ETransportCreate.product`, `Product`)}
                onClick={() => {
                  form.setFieldValue(form.names.products, [
                    ...(form.values.products || []),
                    {
                      ean: '',
                      name: '',
                      nomenclature: '',
                      quantity: 0,
                      weightNetto: 0,
                      priceExVat: { price: 0, currency: 'CZK' },
                    },
                  ]);
                }}
              />

              {/*<hr className={'bg-content-tertiary h-[1px] border-0'} />
                <Field label={'Exchange rate 1 EUR'} readOnly={true}>
                 <InputText />
                </Field>
                <Field label={'Exchange rate 1 EUR'} readOnly={true}>
                 <InputText />
                </Field>*/}
            </div>
          </Card>
        </div>
      </SectionPanel>
      <div className={'flex flex-row gap-8 items-center'}>
        <RingilButton label={tr(`ETransportCreate.send`, `Odeslat`)} mode={'filled-big'} leftIcon={<SvgSendIcon />} onClick={() => form.validateAndSend()} />
      </div>
      <InputPasteTabular
        onSave={data =>
          form.setFieldValue(
            form.names.products,
            data.map(t => convertPastedData(t)),
          )
        }
      />
      <SendETransportToRO
        onConfirm={() => {
          if (isNullOrUndefined(form.values.id)) {
            onCompleted();
            createETransport(reservationId, form.values, () => {
              showToastMessage(
                tr(`ETransportCreate.successSummary`, `Transport was created`),
                tr(`ETransportCreate.successDetail`, `Dokument jste odeslali. Nyní čekáme na potvzení. `),
                'success',
              );
            });
          } else {
            onCompleted();
            updateETransport(reservationId, form.values, () => {
              showToastMessage(
                tr(`ETransportCreate.successUpdateSummary`, `Transport was updated`),
                tr(`ETransportCreate.successUpdateDetail`, `Dokument jste odeslali. Nyní čekáme na potvzení. `),
                'success',
              );
            });
          }
        }}
        applicationId={applicationId}
      />
    </div>
  );
};
export default ETransportCreate;
