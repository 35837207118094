import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tag/ButtonTag.tsx';
import SvgETransportDocument from '@components/features/eTransport/icons/ETransportDocument.tsx';
import { DocumentTypeEnum, ETransportDocumentDTO } from '@api/logsteo-api.v2.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import { dumpVars, formatLocalDate, isNullOrUndefined, parseNumberOrUndefined } from '@utils/utils.tsx';
import ETransportInputDocumentType, { InputDocumentTypeEnum } from '@components/features/eTransport/input/ETransportInputDocumentType.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { InputText } from 'primereact/inputtext';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';
import { ValidationError } from 'yup';
import { findValidationMessage, findValidationMessageRaw, hasValidationProblemForPrefix } from '@utils/validation.tsx';

interface ComponentProps {
  value: ETransportDocumentDTO; // comma separated values
  onChange: (value: ETransportDocumentDTO) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

const ETransportDocumentTypeTag: React.FC<ComponentProps> = ({ value, onChange, readonly, validationPrefix, validationErrors }) => {
  const [internalValue, setInternalValue] = useState<ETransportDocumentDTO>(value);
  const { tr } = useTranslationLgs();

  const mapToText = (value: number): string => {
    return tr(`ETransportDocumentTypeTag.${InputDocumentTypeEnum[value]}`, `${InputDocumentTypeEnum[value]}`);
  };

  const printValue = (value: ETransportDocumentDTO) => {
    if (isNullOrUndefined(value?.documentType)) return null;

    return (
      <MultipleValues
        values={[
          mapToText(value.documentType),
          value.documentType == InputDocumentTypeEnum.Other ? value.documentName : null,
          value.documentNumber,
          formatLocalDate(value.documentDate),
        ].filter(t => t != null)}
      ></MultipleValues>
    );
  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  return (
    <>
      <ButtonTag
        label={tr(`ETransportDocumentTypeTag.title`, `Typ dokumentu`)}
        icon={<SvgETransportDocument />}
        required={true}
        value={printValue(value)}
        readonly={readonly}
        errorMessage={hasError ? tr(`ETransportTransportCompany.validationError`, `Validation error`) : undefined}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        popupWidth={'350px'}
      >
        <>
          <ETransportInputDocumentType
            value={internalValue?.documentType?.toString()}
            errorMessage={findValidationMessage(validationErrors, validationPrefix + '.documentType', tr)}
            onChange={v => setInternalValue({ ...internalValue, documentType: parseNumberOrUndefined(v) })}
          />
          {internalValue?.documentType === InputDocumentTypeEnum.Other && (
            <Field
              label={tr(`ETransportDocumentTypeTag.documentName`, `Název dokumentu`)}
              errorMessage={findValidationMessage(validationErrors, validationPrefix + '.documentName', tr)}
            >
              <InputText value={internalValue?.documentName} onChange={e => setInternalValue({ ...internalValue, documentName: e.target.value })} />
            </Field>
          )}

          <Field
            label={tr(`ETransportDocumentTypeTag.documentNumber`, `Číslo dokumentu`)}
            errorMessage={findValidationMessage(validationErrors, validationPrefix + '.documentNumber', tr)}
          >
            <InputText value={internalValue?.documentNumber} onChange={e => setInternalValue({ ...internalValue, documentNumber: e.target.value })} />
          </Field>

          <Field
            label={tr(`ETransportDocumentTypeTag.documentDate`, `Datum dokumentu`)}
            errorMessage={findValidationMessage(validationErrors, validationPrefix + '.documentDate', tr)}
          >
            <RingilCalendar value={internalValue?.documentDate} onChange={v => setInternalValue({ ...internalValue, documentDate: v })} fullWidth />
          </Field>
        </>
      </ButtonTag>
    </>
  );
};

export default ETransportDocumentTypeTag;
