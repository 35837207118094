import React, { useEffect, useState } from 'react';
import COText from '@app/pages/COText/COText.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface PaginationProps {
  selectedPage: number;
  pageSize: number;
  total: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ selectedPage, pageSize, total, onPageChange, onPageSizeChange }) => {
  const totalPages = Math.ceil(total / pageSize);
  const maxDisplayedPages = 7;
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    setCurrentPage(selectedPage);
  }, [selectedPage]);

  const getDisplayedPages = () => {
    const pages = [];
    const halfRange = Math.floor(maxDisplayedPages / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, currentPage + halfRange);

    // Adjust start and end if the range is smaller than maxDisplayedPages
    if (endPage - startPage + 1 < maxDisplayedPages) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);
      } else if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxDisplayedPages + 1);
      }
    }

    // Add first page if not included
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push('...');
      }
    }

    // Add page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add last page if not included
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push('...');
      }
      pages.push(totalPages);
    }

    return pages;
  };

  const handlePageClick = (page: number | string) => {
    if (typeof page === 'number' && page !== currentPage) {
      setCurrentPage(page);
      onPageChange(page);
    }
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(event.target.value);
    onPageSizeChange(newSize);
    setCurrentPage(1); // Reset to first page when page size changes
    onPageChange(1);
  };

  return (
    <div className="flex flex-row items-center gap-4 justify-between py-4">
      <div className="flex items-center space-x-2">
        <button
          className={`px-4 py-2 border rounded ${currentPage === 1 ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white text-blue-500 hover:bg-blue-100'}`}
          onClick={() => currentPage > 1 && handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <COText label={tr('Pagination.previous', 'Previous')} />
        </button>
        {getDisplayedPages().map((page, index) => (
          <button
            key={index}
            className={`px-4 py-2 border rounded ${page === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 hover:bg-blue-100'} ${
              page === '...' ? 'cursor-default' : ''
            }`}
            onClick={() => handlePageClick(page)}
            disabled={page === '...'}
          >
            {page}
          </button>
        ))}
        <button
          className={`px-4 py-2 border rounded ${
            currentPage === totalPages ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white text-blue-500 hover:bg-blue-100'
          }`}
          onClick={() => currentPage < totalPages && handlePageClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <COText label={tr('Pagination.next', 'Next')} />
        </button>
        <COText label={tr(`Pagination.totalRecordsTotal`, `Total Records: {{total}}`, { total })} />
      </div>
      {/*<div className="flex items-center space-x-4">
         <label className="flex flex-row gap-2 items-center">
           <COText label={'Records per page:'} />
           <select value={pageSize} onChange={handlePageSizeChange} className="ml-2 border rounded px-2 py-1">
             <option value={10}>10</option>
             <option value={20}>20</option>
             <option value={50}>50</option>
           </select>
         </label>
        </div>*/}
    </div>
  );
};

export default Pagination;
