import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Trans, useTranslation } from 'react-i18next';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

export interface RejectSidebarData {
  visible: boolean;
  company?: string;
  quoteId?: string;
}

interface ComponentProps {
  rejectSidebarData: RejectSidebarData;
  onReject: (quoteId: string, reasonText: string) => void;
  onBackButton: () => void;
}

const RejectSidebar: React.FC<ComponentProps> = ({ rejectSidebarData, onReject, onBackButton }) => {
  const { t } = useTranslation('common');
  const [isVisibleText, setIsVisible] = useState<boolean>(false);
  const [rejectReasonText, setRejectReasonText] = useState<string>('');

  return (
    <>
      {/*//
      @ts-ignore*/}
      <Sidebar
        visible={rejectSidebarData.visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onBackButton()}
        className="sidebar-modal"
        style={{ width: '750px' }}
      >
        <h4>{t('customerExpeditionDetail.tabCarrierPending.rejectAPriceQuote', 'Reject a price quote')}</h4>
        <p>
          <Trans i18nKey="customerExpeditionDetail.tabCarrierPending.rejectAPriceQuoteWarning" values={{ company: rejectSidebarData.company }}></Trans>
        </p>

        {!isVisibleText && (
          <Button
            label={t('customerExpeditionDetail.tabCarrierPending.addRejectReason', '+ Add message')}
            className="p-button-rounded p-button-outlined p-mb-3"
            onClick={() => setIsVisible(!isVisibleText)}
          />
        )}

        {isVisibleText && (
          <div className="p-field p-grid p-mb-4">
            <div className="p-col-12">
              <InputTextarea
                rows={5}
                cols={30}
                value={rejectReasonText}
                style={{ width: '100%' }}
                onChange={e => {
                  setRejectReasonText(e.target.value);
                }}
              />
            </div>
          </div>
        )}

        <ButtonLayout>
          <Button label={t('global.continue')} className="p-mr-2" onClick={() => onReject(rejectSidebarData.quoteId, rejectReasonText)} />
          <Button label={t('global.back')} className="p-button-text" onClick={() => onBackButton()} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export const createInitialRejectData = (): RejectSidebarData => {
  return { visible: false };
};

export default RejectSidebar;
