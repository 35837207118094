import React, { useContext, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  EntityTypeEnum,
  ExpeditionPreviewOutDTO,
  ExpeditionStatusEnum,
  ExpeditionTypeEnum,
  FeaturesEnum,
  LovDTO,
  PageResponseExpeditionPreviewOutDTO,
  SupplierNotificationTypeEnum,
  ValidDistributionEnum,
} from '@api/logsteo-api.v2';
import {
  convertToArray,
  convertToStrignArray,
  dumpVars,
  formatPrice,
  isNotBlank,
  isNotNullOrUndefined,
  isNullOrUndefined,
  parseURLQUeryDate,
  translateQuantityCode,
  useLocalStorage,
  weightAndDimensions,
} from '@utils/utils';
import { dateFormatDateTime, mapFromAPIDateTime, shortDateFormat } from '@utils/date';
import styled from 'styled-components';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ApiContext } from '@api/api';
import { Toast } from 'primereact/toast';
import dayjs from 'dayjs';
import useNavigation from '@hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons.tsx';
import useCustomerCarriers from '@hooks/customer-carrier/customer-carriers.tsx';
import PublishSidebar, { ExpeditionCoord } from '@app/pages/customer/expedition-list/sidebars/PublishSidebar.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useMAExpUpdIntInformation } from '@components/obsolete/MAExpUpdIntInformation/MAExpUpdIntInformation.tsx';
import { useMAExp } from '@components/obsolete/MAExp/MAExp.tsx';
import { useDachserExport } from '@components/obsolete/MassActionDachserExport/MassActionDachser.tsx';
import useMassAction from '@hooks/useMassAction/useMassAction.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { Col } from '@components/styles.tsx';
import { Link } from 'react-router-dom';
import { LabelDiv, LabelWrapper } from '@components/obsolete/Labels/Labels.tsx';
import { formatTimeslotInterval } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import ExpeditionStateSpan from '@components/obsolete/StateSpan/ExpeditionStateSpan.tsx';
import CircleWithOnHoverText from '@components/obsolete/CircleWithOnHoverText/CircleWithOnHoverText.tsx';
import { ButtonMoreNavigation } from '@components/obsolete/ButtonMoreNavigation/ButtonMoreNavigation.tsx';
import NoteIcon from '@components/obsolete/Icons/NoteIcon.tsx';
import ExtraCostIcon from '@components/obsolete/Icons/ExtraCostIcon.tsx';
import CarDriverIcon from '@components/obsolete/Icons/CarDriverIcon.tsx';
import { toBoolean } from '@app/pages/customer/supplier-notification/utils.ts';
import InputLabel from '@components/obsolete/Form/InputLabel/InputLabel.tsx';
import InputCountryPlain from '@components/obsolete/Form/InputCountry/InputCountryPlain.tsx';
import MassActionFileUploadDialog from '@components/obsolete/MassActionFileUploadDialog/MassActionFileUploadDialog.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';

interface ComponentProps {
  customerId: string;
}

interface MassActionExpedition {
  expeditionId: string;
  applicationId: string;
  companyId: string;
  locationFrom: string;
  locationTo: string;
  state: string;
  isPublished: boolean;
}

interface AllExpeditionCustomerFilter {
  onlyManual?: boolean;
  applicationId?: string;
  responsiblePersonIds?: string[];
  carrierIds?: string[];
  statusEnums?: ExpeditionStatusEnum[];

  loadingDateFromISO?: string;
  loadingDateToISO?: string;
  unloadingDateFromISO?: string;
  unloadingDateToISO?: string;

  loadingDates: string;
  unloadingDates: string;

  expeditionType?: ExpeditionTypeEnum;
  loadingLocationName?: string;
  unLoadingLocationName?: string;
  validDistribution?: ValidDistributionEnum;
  onlyUnpublished?: boolean;
  labelValues?: string[];
  showArchived?: boolean;
  pairingSymbol?: string;
  countryCode?: string;
  allLocationName?: string[];
}

const AllExpedition: React.FC<ComponentProps> = ({ customerId }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const [pageData, setPageData] = useState<PageResponseExpeditionPreviewOutDTO>();
  const { cuListExpeditionsV3 } = useContext(ApiContext);
  const responsiblePersons = useResponsiblePersons();
  const { carriers } = useCustomerCarriers();
  const [unpublishedCount, setUnpublishedCount] = useState(0);
  const [publishingIds, setPublishingIds] = useState<ExpeditionCoord[]>([]);
  const [publishSidebarVisible, setPublishSidebarVisible] = useState(false);
  const [visibleUploadDialog, setVisibleUploadDialog] = useState(false);
  const toast = useRef(null);
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const navigation = useNavigation();

  const { setVisibleMAExpeditionIntInfo, MassActionExpeditionUpdateInternalInformation } = useMAExpUpdIntInformation();
  const { setVisible: setVisibleMA, MassActionExpedition } = useMAExp();
  const { setVisible: setVisibleDachserExport, MassActionDachser } = useDachserExport();

  const { MassActionCheckboxAndState, toggleCheckbox, isSelected, setSelectedObjects, selectedObjects, clear } = useMassAction<MassActionExpedition>(
    (a, b) => {
      return a.expeditionId === b.expeditionId;
    },
    selectedObjects => (
      <>
        {loggedUser.securityRoles.indexOf('ROLE_EXP_RW') >= 0 && (
          <>
            <Button
              label={tr(`AllExpedition.nahratDokumenty`, `Nahrat dokumenty`)}
              disabled={selectedObjects.length == 0}
              onClick={e => {
                setVisibleUploadDialog(true);
              }}
            />
            <Button
              label={tr(`AllExpedition.odeslatCekajici`, `Odeslat cekajici`)}
              disabled={selectedObjects.length == 0 || selectedObjects.some(t => t.isPublished === true || t.state !== ExpeditionStatusEnum.ASSIGNING)}
              onClick={e => {
                setPublishingIds(
                  selectedObjects.map(t => {
                    return {
                      id: t.expeditionId,
                      applicationId: t.applicationId,
                      companyId: t.companyId,
                    };
                  }),
                );
                setPublishSidebarVisible(true);
              }}
            />

            <Button
              label={tr(`AllExpedition.internalInformation`, `Internal information`)}
              disabled={selectedObjects.length == 0}
              onClick={e => {
                setVisibleMAExpeditionIntInfo(true);
              }}
            />
            <Button
              label={tr(`AllExpedition.massAction`, `Mass action`)}
              disabled={selectedObjects.length == 0}
              onClick={e => {
                setVisibleMA(true);
              }}
            />
            {loggedUser?.features?.some(t => t == 'DACHSER_XML_EXPORT') && (
              <Button
                label={tr(`AllExpedition.dachserExport`, `Dachser export`)}
                disabled={selectedObjects.length == 0}
                onClick={e => {
                  setVisibleDachserExport(true);
                }}
              />
            )}
          </>
        )}
      </>
    ),
    () => renderDataSelectedItems(),
  );

  const { applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort, applyFilter } = useFilter<AllExpeditionCustomerFilter>(
    (page, filter) => {
      loadData(page.pageSize, page.pageNumber, filter);
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  const buildLoadingDates = (filter: AllExpeditionCustomerFilter) => {
    if (typeof filter.loadingDates === 'string') {
      return {
        loadingDateFromISO: isNotBlank(filter?.loadingDates) ? filter?.loadingDates : null,
        loadingDateToISO: isNotBlank(filter?.loadingDates) ? filter?.loadingDates : null,
      };
    }
    if (typeof filter.loadingDates === 'object') {
      return {
        loadingDateFromISO: isNotBlank(filter?.loadingDates) ? filter?.loadingDates : null,
        loadingDateToISO: isNotBlank(filter?.loadingDates) ? filter?.loadingDates : null,
      };
    }
    if (Array.isArray(filter.loadingDates)) {
      const arr = Array.from(filter.loadingDates);

      return {
        loadingDateFromISO: arr.length > 0 && filter?.loadingDates[0] !== null ? filter?.loadingDates[0] : null,
        loadingDateToISO: arr.length > 1 && filter?.loadingDates[1] !== null ? filter?.loadingDates[1] : null,
      };
    }
  };

  const buildUnloadingDates = (filter: AllExpeditionCustomerFilter) => {
    if (typeof filter.unloadingDates === 'string') {
      return {
        unloadingDateFromISO: isNotBlank(filter?.unloadingDates) ? filter?.unloadingDates : null,
        unloadingDateToISO: isNotBlank(filter?.unloadingDates) ? filter?.unloadingDates : null,
      };
    }

    if (typeof filter.unloadingDates === 'object') {
      return {
        unloadingDateFromISO: isNotBlank(filter?.unloadingDates) ? filter?.unloadingDates : null,
        unloadingDateToISO: isNotBlank(filter?.unloadingDates) ? filter?.unloadingDates : null,
      };
    }

    if (Array.isArray(filter.unloadingDates)) {
      const arr = Array.from(filter.unloadingDates);

      return {
        unloadingDateFromISO: arr.length > 0 && filter?.unloadingDates[0] !== null ? filter?.unloadingDates[0] : null,
        unloadingDateToISO: arr.length > 1 && filter?.unloadingDates[1] !== null ? filter?.unloadingDates[1] : null,
      };
    }
  };

  const [loading, setLoading] = useState(false);
  const loadData = (pageSize: number, pageNumber: number, filter: AllExpeditionCustomerFilter) => {
    setLoading(true);
    cuListExpeditionsV3(
      // @ts-ignore
      {
        ...filter,
        /*loadingDateFromISO: filter?.loadingDates?.length > 0 && filter?.loadingDates[0] !== null ? filter?.loadingDates[0] : null,
        loadingDateToISO: filter?.loadingDates?.length > 1 && filter?.loadingDates[1] !== null ? filter?.loadingDates[1] : null,
        unloadingDateFromISO: filter?.unloadingDates?.length > 0 && filter?.unloadingDates[0] !== null ? filter?.unloadingDates[0] : null,
        unloadingDateToISO: filter?.unloadingDates?.length > 1 && filter?.unloadingDates[1] !== null ? filter?.unloadingDates[1] : null,*/
        ...buildLoadingDates(filter),
        ...buildUnloadingDates(filter),
        /*loadingDateFromISO: isNotBlank(filter?.loadingDates) ? filter?.loadingDates : null,
        loadingDateToISO: isNotBlank(filter?.loadingDates) ? filter?.loadingDates : null,*/
        /*unloadingDateFromISO: isNotBlank(filter?.unloadingDates) ? filter?.unloadingDates : null,
        unloadingDateToISO: isNotBlank(filter?.unloadingDates) ? filter?.unloadingDates : null,*/
      },
      {
        pageSize,
        pageNumber: pageNumber,
      },
      data => {
        setLoading(false);
        setPageData(draft => {
          return data.expeditions;
        });
      },
    );
  };

  const loadFirstPage = () => {
    applyPaging({ pageNumber: 1, pageSize: page.pageSize });
  };

  const [revealTabTexts, setRevealTabTexts] = useLocalStorage<boolean>('ringil_tags_expanded', false);

  const renderDataSelectedItems = (): any[] => {
    return [
      <Column header={tr(`AllExpedition.applicationId`, `applicationId`)} body={(row: MassActionExpedition) => <div>{row.applicationId}</div>} />,
      <Column header={tr(`AllExpedition.state`, `state`)} body={(row: MassActionExpedition) => <div>{row.state}</div>} />,
      <Column header={tr(`AllExpedition.from`, `from`)} body={(row: MassActionExpedition) => <div>{row.locationFrom}</div>} />,
      <Column header={tr(`AllExpedition.to`, `to`)} body={(row: MassActionExpedition) => <div>{row.locationTo}</div>} />,
    ];
  };

  const idAndResponsiblePersonTemplate = (row: ExpeditionPreviewOutDTO) => {
    return (
      <>
        <span className="p-column-title">{tr(`AllExpedition.expeditinoTitle`, `Expedice`)}</span>
        <Col>
          {/*<Link href={`/customer/expedition/${row.applicationId}`}>*/}
          <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionDetail(row.applicationId))}>
            <span className={'text-blue'} style={{ cursor: 'pointer' }}>
              {row.applicationId}
            </span>
          </Link>
          {row.supplierNotificationId && (
            /*<Link href={`/supplier-notifications/${row.supplierNotificationId}`}>*/
            <Link
              to={navigation.createNavigationLink(navigation.urlFunctions.createSupplierNotificationDetail(row.supplierNotificationId, SupplierNotificationTypeEnum.FCA))}
            >
              <span className={'text-blue'} style={{ cursor: 'pointer' }}>
                {row.supplierNotificationAppId}
              </span>
            </Link>
          )}

          {row.supplierManifestId && (
            /*<Link href={`/supplier-manifest/${row.supplierManifestId}`}>*/
            <Link to={navigation.createNavigationLink(navigation.urlFunctions.createSupplierManifestDetail(row.supplierManifestId))}>
              <span className={'text-blue'} style={{ cursor: 'pointer' }}>
                {row.supplierManifestAppId}
              </span>
            </Link>
          )}
        </Col>
        <SmallerAndGrayDiv>{row.responsiblePerson}</SmallerAndGrayDiv>
        <LabelWrapper>
          {row.labels
            .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
            .map((t, tIndex) => (
              <LabelDiv
                bgColor={t.labelColor}
                key={tIndex}
                onClick={e => {
                  setRevealTabTexts(v => !v);
                }}
              >
                {revealTabTexts && <div>{t.labelValue}</div>}
              </LabelDiv>
            ))}
        </LabelWrapper>
      </>
    );
  };

  const firstLocationTemplate = (row: ExpeditionPreviewOutDTO) => {
    return (
      <>
        <span className="p-column-title">{tr(`AllExpedition.firstLocationTitle`, `Výchozí lokace`)}</span>
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {row.locations[0].name}
        </div>
        <SmallerAndGrayDiv>{formatTimeslotInterval(row.locations[0].timeslots)}</SmallerAndGrayDiv>
      </>
    );
  };

  const lastLocationTemplate = (row: ExpeditionPreviewOutDTO) => {
    return (
      <>
        <span className="p-column-title">{tr(`AllExpedition.lastLocationTitle`, `Cílová lokace`)}</span>
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <span>{row.locations[row.locations.length - 1].name}</span>
        </div>
        <SmallerAndGrayDiv>{formatTimeslotInterval(row.locations[row.locations.length - 1].timeslots)}</SmallerAndGrayDiv>
      </>
    );
  };

  const stateAndManualModeTemplate = (row: ExpeditionPreviewOutDTO) => {
    return (
      <>
        <span className="p-column-title">{tr(`AllExpedition.stateTitle`, `Stav`)}</span>
        <div>
          <ExpeditionStateSpan expeditionStage={row.expeditionStatus} isManual={row.isManualExpedition} />
        </div>
        {row.isManualExpedition && <SmallerAndGrayDiv>{tr(`AllExpedition.manual`, `Manual`)}</SmallerAndGrayDiv>}
      </>
    );
  };

  const hasNewReservationFeature = loggedUser?.features?.indexOf(FeaturesEnum.NEW_RESERVATION) !== -1;

  const demandQuotesTemplate = (row: ExpeditionPreviewOutDTO) => {
    return (
      <>
        <span className="p-column-title">{tr(`AllExpedition.demandsQuotes`, `Nabídek/Poptávek`)}</span>
        {row.distribution && (
          <div>
            {row.distribution.quotesOfferCount} / {row.distribution.quotesCount}
            {!row.distribution.isExpiredDistribution && row.expeditionStatus === ExpeditionStatusEnum.ASSIGNING && (
              <>
                {/*   <Circle id={`circle_${row.applicationId}`}></Circle>
                <Tooltip target={`#circle_${row.applicationId}`}>
                  <span>
                    {tr('AllExpedition.demandExpire', 'Poptávka vyprší {{when}}', {
                      when: dateFormatDateTime(mapFromAPIDateTime(row.distribution.expiredUntil)),
                    })}
                  </span>
                </Tooltip>*/}
                <CircleWithOnHoverText id={row.applicationId}>
                  {tr('AllExpedition.demandExpire', 'Poptávka vyprší {{when}}', {
                    when: dateFormatDateTime(mapFromAPIDateTime(row.distribution.expiredUntil)),
                  })}
                </CircleWithOnHoverText>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const priceAndWinnerTemplate = (row: ExpeditionPreviewOutDTO) => {
    return (
      <>
        <span className="p-column-title">{tr(`AllExpedition.acceptedPriceCarrier`, `Akceptovaná cena/Dopravce`)}</span>
        {row.assignedCarrierName && (
          <>
            <div>{formatPrice(row.expeditionPrice + (row.extraCosts?.reduce((a, b) => a + b.price, 0) ?? 0), row.expeditionCurrency)}</div>
            <SmallerAndGrayDiv>{row.assignedCarrierName}</SmallerAndGrayDiv>
          </>
        )}
      </>
    );
  };

  const navBodyTemplate = (rowData: ExpeditionPreviewOutDTO) => {
    return (
      <div>
        <ActionButtons>
          {hasNewReservationFeature && (
            <Link to={navigation.createNavigationLink(navigation.urlFunctions.createExpeditionDetailV2(rowData.id))}>
              {/*<Button className="p-mr-2 btn-detail p-button p-button-rounded p-button-outlined secondary-btn">
                {t(`AllExpedition.detail2`, `Detail 2`)}
              </Button>*/}
              <RingilButton label={t(`AllExpedition.detail2`, `Detail 2`)} onClick={() => {}} />
            </Link>
          )}
          {loggedUser.securityRoles.indexOf('ROLE_EXP_RW') >= 0 && (
            <ButtonMoreNavigation
              expeditionId={rowData.id}
              customerId={rowData.companyId}
              applicationId={rowData.applicationId}
              isExpeditionCanceled={rowData.expeditionStatus === ExpeditionStatusEnum.CANCELED}
              onCanceledExpedition={() => {
                loadData(page.pageSize, page.pageNumber, filter);
              }}
              onSwitchedToManualExpedition={(applicationId: string) => {
                alert('Removed obsolete function');
              }}
              isDisabledByManualMode={rowData.isManualExpedition && !isNullOrUndefined(rowData.expeditionToId)}
              currency={rowData.currency}
              onAddedExtraCosts={() => {
                /* we dont need to refresh as there are no extracosts displayed on list */
              }}
              showExtraCosts={
                rowData.expeditionStatus !== ExpeditionStatusEnum.NEW &&
                rowData.expeditionStatus !== ExpeditionStatusEnum.ASSIGNING &&
                rowData.expeditionStatus !== ExpeditionStatusEnum.CANCELED &&
                !(rowData.isManualExpedition && !isNullOrUndefined(rowData.expeditionToId))
              }
              inStateNew={rowData.expeditionStatus === ExpeditionStatusEnum.NEW}
              onManuallyAssignedCarrier={() => {
                loadData(page.pageSize, page.pageNumber, filter);
              }}
              assignedDispatcherEmail={rowData.carrierDispatcherEmail}
              isArchived={rowData.isArchived}
              onArchivedChanged={() => {
                loadData(page.pageSize, page.pageNumber, filter);
              }}
              canBeFinished={rowData.expeditionStatus !== ExpeditionStatusEnum.FINISHED && rowData.expeditionStatus !== ExpeditionStatusEnum.CANCELED}
              isDistributionCreated={isNotNullOrUndefined(rowData.distribution)}
              linkedToSN={isNotNullOrUndefined(rowData.supplierNotificationId)}
            />
          )}
        </ActionButtons>
      </div>
    );
  };

  const enc = (applicationId: string) => btoa(applicationId).replace(/[/=]/g, '');

  const additionalInfoTemplate = (row: ExpeditionPreviewOutDTO) => {
    return (
      <>
        <span className="p-column-title">{tr(`AllExpedition.additionalInfoTitle`, `Další informace`)}</span>
        {row.expeditionType == ExpeditionTypeEnum.FULL_TRUCK_LOAD && <span>FTL</span>}
        {row.expeditionType == ExpeditionTypeEnum.LESS_TRUCK_LOAD && (
          <span>
            <span>LTL</span>{' '}
          </span>
        )}
        {row.orders && (
          <Icon>
            <span id={`books_${row.applicationId}`} className={'pi pi-book'}></span>
            <TooltipWrapper>
              {/*// @ts-ignore*/}
              <Tooltip target={`#books_${enc(row.applicationId)}`} style={{ maxWidth: '530px' }}>
                <div style={{ whiteSpace: 'nowrap' }}>
                  {row.orders.map((order, orderIndex) => (
                    <div key={orderIndex}>
                      <div>Obj.: {order.orderName}</div>
                      {order.cargos.map((orderItem, orderItemIndex) => (
                        <div key={orderItemIndex}>
                          {orderItem.name} ({orderItem.quantity}
                          {translateQuantityCode(orderItem.quantityUnit, tr)}),
                          {weightAndDimensions(orderItem.height, orderItem.length, orderItem.width, orderItem.weight, t)}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </Tooltip>
            </TooltipWrapper>
          </Icon>
        )}
        <Icon>
          <span id={`route_${row.applicationId}`} className={'pi pi-map'}></span>
          {/*// @ts-ignore*/}
          <Tooltip target={`#route_${enc(row.applicationId)}`} style={{ maxWidth: '530px' }}>
            {row.locations.map((location, locationIndex) => {
              return (
                <div key={locationIndex}>
                  {tr(`AllExpedition.locationInfo`, `Location {{order}}: {{locationName}}`, {
                    order: locationIndex + 1,
                    locationName: location.name,
                  })}
                </div>
              );
            })}
          </Tooltip>
        </Icon>
        {!row.isPublished && row.expeditionStatus === ExpeditionStatusEnum.ASSIGNING && (
          <Icon>
            <span id={`notPublished_${row.applicationId}`} className={'pi pi-eye-slash'}></span>
            {/*// @ts-ignore*/}
            <Tooltip target={`#notPublished_${row.applicationId}`}>{tr(`AllExpedition.expeditionIsNotVisibleYet`, `Expedition is not visible yet`)}</Tooltip>
          </Icon>
        )}
        <NoteIcon applicationId={row.applicationId} note={row.note} />
        <ExtraCostIcon extraCosts={row.extraCosts} applicationId={row.applicationId} />
        <CarDriverIcon carInfo={row.carInfo} applicationId={row.applicationId} />
        {row.locations.length - 2 > 0 && (
          <SmallerAndGrayDiv>
            {tr(`AllExpedition.stops`, 'Mezizastávky: {{stops}}', {
              stops: row.locations.length - 2,
            })}
          </SmallerAndGrayDiv>
        )}
        {row.isInvoiced && <span className={'pi pi-check'} title={tr('AllExpedition.invoiced', 'Invoiced')}></span>}
      </>
    );
  };

  if (isNullOrUndefined(filter)) return <>Loading....</>;

  return (
    <>
      <JustifiedRow>
        <h2 className="h2-title p-m-0">{t(`AllExpedition.allExpeditions`, `Všechny expedice`)}</h2>
        <div>
          {loggedUser.securityRoles.indexOf('ROLE_EXP_RW') >= 0 && (
            /*<Link href="/customer/expedition">*/
            <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerNewExpedition())}>
              <a className="btn-detail btn-detail-icon p-button p-button-rounded secondary-btn on-large-only">
                <span className="icon pi pi-plus"></span> <span className="text">{t(`AllExpedition.newExpedition`, `Nová expedice`)}</span>
              </a>
            </Link>
          )}
          {/*<Button
            icon="pi pi-filter"
            className="filter-button p-button-outlined"
            onClick={() => {}}
          />*/}
        </div>
      </JustifiedRow>
      <div className="p-mt-3 p-mb-2">
        <div className="p-formgroup-inline">
          <div className="p-field">
            <MultiSelect
              filter={true}
              value={convertToStrignArray(filter.responsiblePersonIds)}
              options={responsiblePersons}
              onChange={e => {
                /*setFilter((draft) => {
                                  draft.responsiblePersonIds = e.value;
                                });*/
                applyFilterValue('responsiblePersonIds', e.value);
              }}
              showClear={true}
              optionLabel="name"
              optionValue="id"
              itemTemplate={(option: LovDTO) => (
                <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
              )}
              placeholder={tr(`AllExpedition.responsiblePerson`, `Odpovědná osoba`)}
            />
          </div>
          <div className="p-field">
            <Calendar
              locale={'cs'}
              selectionMode={'single'}
              value={
                isNullOrUndefined(filter.loadingDates)
                  ? null
                  : Array.isArray(parseURLQUeryDate(filter.loadingDates))
                  ? dayjs(filter.loadingDates[0]).toDate()
                  : dayjs(filter.loadingDates).toDate()
              }
              onChange={e => {
                applyFilterValue('loadingDates', e.value as Date[]);
              }}
              dateFormat={shortDateFormat}
              readOnlyInput
              showButtonBar={true}
              showIcon
              onClearButtonClick={() =>
                /*                setFilter((draft) => {
                  draft.loadingDates = [];
                })*/
                applyFilterValue('loadingDates', null)
              }
              placeholder={tr(`AllExpedition.loadingDate`, `Nakládka`)}
            />
          </div>
          <div className="p-field">
            <Calendar
              locale={'cs'}
              selectionMode={'single'}
              onChange={e => {
                /*setFilter((draft) => {
                  setFilter((draft) => {
                    draft.unloadingDates = e.value as Date[];
                  });
                });*/
                applyFilterValue('unloadingDates', e.value as Date[]);
              }}
              //value={parseURLQUeryDate(filter.unloadingDates)}
              value={
                isNullOrUndefined(filter.unloadingDates)
                  ? null
                  : Array.isArray(parseURLQUeryDate(filter.unloadingDates))
                  ? dayjs(filter.unloadingDates[0]).toDate()
                  : dayjs(filter.unloadingDates).toDate()
              }
              readOnlyInput
              dateFormat={shortDateFormat}
              showIcon
              onClearButtonClick={() =>
                /*setFilter((draft) => {
                  draft.unloadingDates = [];
                })*/
                applyFilterValue('unloadingDates', null)
              }
              showButtonBar={true}
              placeholder={tr(`AllExpedition.unloadingTime`, `Vykládka`)}
            />
          </div>

          <div className="p-field">
            <div className="p-d-flex p-flex-wrap">
              <div className="p-field-checkbox p-mr-3">
                <InputSwitch
                  className="expedition-filters-switch"
                  checked={toBoolean(filter.onlyManual)}
                  onChange={e =>
                    /* setFilter((draft) => {
                      draft.onlyManual = e.value;
                    })*/
                    applyFilterValue('onlyManual', e.value)
                  }
                />
                <label>{tr(`AllExpedition.onlyManual`, `Pouze Manuální`)}</label>
              </div>
            </div>
          </div>
          <div className="p-field">
            <div className="p-d-flex p-flex-wrap">
              <div className="p-field-checkbox p-mr-3">
                <InputSwitch
                  className="expedition-filters-switch"
                  checked={toBoolean(filter.onlyUnpublished)}
                  onChange={e =>
                    /*setFilter((draft) => {
                      draft.onlyUnpublished = e.value;
                    })*/
                    applyFilterValue('onlyUnpublished', e.value)
                  }
                />
                <label>{tr(`AllExpedition.onlyUnpublished`, `Only unpublished`)}</label>
              </div>
            </div>
          </div>
          <div className="p-field">
            <div className="p-d-flex p-flex-wrap">
              <div className="p-field-checkbox p-mr-3">
                <InputSwitch
                  className="expedition-filters-switch"
                  checked={toBoolean(filter.showArchived)}
                  onChange={e =>
                    /*setFilter((draft) => {
                      draft.showArchived = e.value;
                    })*/
                    applyFilterValue('showArchived', e.value)
                  }
                />
                <label>{tr(`AllExpedition.onlyArchived`, `Only archived`)}</label>
              </div>
            </div>
          </div>
          <div className="p-field">
            <div className="p-d-flex p-flex-wrap">
              <div className="p-field-checkbox p-mr-3">
                <InputLabel
                  values={convertToArray(filter.labelValues)}
                  onChange={v => {
                    /*setFilter((draft) => {
                      draft.labelValues = v ? v.map((t) => t.labelValue) : [];
                    });*/
                    applyFilterValue('labelValues', v ? v.map(t => t.labelValue) : []);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-field">
            <div className="p-d-flex p-flex-wrap">
              <div className="p-mr-3">
                <InputText
                  value={filter.pairingSymbol}
                  onChange={v => {
                    /*setFilter((draft) => {
                      draft.pairingSymbol = v.target.value;
                    });*/
                    applyFilterValue('pairingSymbol', v.target.value);
                  }}
                  placeholder={tr(`AllExpedition.pairingSymbol`, `Pairing symbol`)}
                />
              </div>
            </div>
          </div>
          <div className="p-field">
            <div className="p-d-flex p-flex-wrap">
              <div className="p-mr-3">
                <InputText
                  value={filter.allLocationName}
                  onChange={v => {
                    /*setFilter((draft) => {
                      draft.pairingSymbol = v.target.value;
                    });*/
                    applyFilterValue('allLocationName', v.target.value);
                  }}
                  placeholder={tr(`AllExpedition.allLocationName`, `Všechny lokace`)}
                />
              </div>
            </div>
          </div>
          <div className="p-field">
            <div className="p-d-flex p-flex-wrap">
              <div className="p-mr-3">
                <InputCountryPlain
                  required={false}
                  showClear={true}
                  codeValue={filter.countryCode}
                  onChange={v =>
                    /*setFilter((d) => {
                      d.countryCode = v;
                    })*/
                    applyFilterValue('countryCode', v)
                  }
                  placeholder={tr(`AllExpedition.country`, `Country`)}
                />
              </div>
            </div>
          </div>
        </div>
        {/*{!visibleFilters && (
          <Button
            label={tr(`AllExpeditionTableFilters.nextFilters`, `Další filtry`)}
            className="p-pl-0 p-pr-0 p-button-text"
            onClick={() => setVisibleFilters(true)}
            style={{ flexShrink: 0 }}
          />
        )}*/}
      </div>
      {pageData && (
        <>
          <div className="datatable-filter p-datatable-customers">
            <MassActionCheckboxAndState
              onSelectAll={() => {
                setSelectedObjects(
                  pageData.data.map(row => {
                    return {
                      expeditionId: row.id,
                      companyId: row.companyId,
                      applicationId: row.applicationId,
                      locationFrom: row.locations[0].name,
                      locationTo: row.locations[row.locations.length - 1].name,
                      state: row.expeditionStatus,
                      isPublished: row.isPublished,
                    };
                  }),
                );
              }}
            />
            {/*// @ts-ignore*/}
            <DataTable
              filterDisplay="row"
              emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
              value={pageData.data}
              rows={pageData.size}
              className="p-datatable-customers"
              paginator={true}
              lazy={true}
              paginatorPosition={'both'}
              totalRecords={pageData.total}
              onPage={onPage}
              loading={loading}
              first={(pageData.page - 1) * pageData.size}
              selectionMode="single"
            >
              <Column
                header={''}
                body={(row: ExpeditionPreviewOutDTO) => (
                  <Checkbox
                    onChange={e => {
                      toggleCheckbox({
                        expeditionId: row.id,
                        companyId: row.companyId,
                        applicationId: row.applicationId,
                        locationFrom: row.locations[0].name,
                        locationTo: row.locations[row.locations.length - 1].name,
                        state: row.expeditionStatus,
                        isPublished: row.isPublished,
                      });
                    }}
                    checked={isSelected({
                      expeditionId: row.id,
                      companyId: row.companyId,
                      applicationId: row.applicationId,
                      locationFrom: row.locations[0].name,
                      locationTo: row.locations[row.locations.length - 1].name,
                      state: row.expeditionStatus,
                      isPublished: row.isPublished,
                    })}
                  />
                )}
                style={{ width: 30 }}
              ></Column>
              <Column
                header={tr(`AllExpedition.expediceHeaderTitle`, `Expedice`)}
                body={idAndResponsiblePersonTemplate}
                filter={true}
                filterElement={options => {
                  return (
                    <InputText
                      value={filter.applicationId}
                      onChange={e => {
                        const { value } = e.target as HTMLInputElement;
                        /*setFilter((draft) => {
                          draft.applicationId = value;
                        });*/
                        applyFilterValue('applicationId', value);
                      }}
                      placeholder={tr(`AllExpedition.enterExpeditionId`, `ID expedice`)}
                    />
                  );
                }}
              ></Column>
              <Column
                header={tr(`AllExpedition.firstLocationHeaderTitle`, `Výchozí lokalita`)}
                body={firstLocationTemplate}
                filter={true}
                filterElement={
                  <InputText
                    value={filter.loadingLocationName}
                    onChange={e => {
                      const { value } = e.target as HTMLInputElement;
                      /*setFilter((draft) => {
                        draft.loadingLocationName = value;
                      });*/
                      applyFilterValue('loadingLocationName', value);
                    }}
                    placeholder={tr(`AllExpedition.loadingLocationNamePlaceholder`, `Výchozí lokalita`)}
                    style={{ maxWidth: '100%' }}
                  />
                }
              />
              <Column
                header={tr(`AllExpedition.lastLocationHeaderTitle`, `Cílová lokalita`)}
                body={lastLocationTemplate}
                filter={true}
                filterElement={
                  <InputText
                    value={filter.unLoadingLocationName}
                    onChange={e => {
                      const { value } = e.target as HTMLInputElement;
                      /*setFilter((draft) => {
                        draft.unLoadingLocationName = value;
                      });*/
                      applyFilterValue('unLoadingLocationName', value);
                    }}
                    placeholder={tr(`AllExpedition.unloadingLocationNamePlaceholder`, `Cílová lokalita`)}
                    style={{ maxWidth: '100%' }}
                  />
                }
              />
              <Column
                header={tr(`AllExpedition.stateHeaderTitle`, `Stav`)}
                body={stateAndManualModeTemplate}
                filter={true}
                filterElement={
                  <MultiSelect
                    value={convertToArray(filter.statusEnums)}
                    options={Object.keys(ExpeditionStatusEnum).map(i => {
                      return { name: tr(`AllExpedition.${i}`, i), value: i };
                    })}
                    onChange={e => {
                      /* setFilter((draft) => {
                        draft.statusEnums = e.value;
                      });*/
                      applyFilterValue('statusEnums', e.value);
                    }}
                    optionLabel="name"
                    optionValue="value"
                    showClear={true}
                    placeholder={tr(`AllExpedition.expeditionState`, `Stav expedice`)}
                    style={{ width: '100%' }}
                  />
                }
              />
              <Column
                header={tr(`AllExpedition.quotesDemansHeaderTitle`, `Nabídek / Poptávek`)}
                body={demandQuotesTemplate}
                filter={true}
                filterElement={
                  <Dropdown
                    value={filter.validDistribution}
                    options={Object.keys(ValidDistributionEnum).map(i => {
                      return {
                        name: tr(`ValidDistributionEnum.${i}`, i),
                        value: i,
                      };
                    })}
                    onChange={e => {
                      /*setFilter((draft) => {
                        draft.validDistribution = e.value;
                      });*/
                      applyFilterValue('validDistribution', e.value);
                    }}
                    style={{ maxWidth: '100%' }}
                    optionValue={'value'}
                    optionLabel={'name'}
                    showClear={true}
                    placeholder={tr(`AllExpedition.validDistribution`, `Platnost poptávky`)}
                  />
                }
              />
              <Column
                header={tr(`AllExpedition.acceptedPriceCarrierHeaderTitle`, `Akceptovaná cena / Dopravce`)}
                body={priceAndWinnerTemplate}
                filter={true}
                filterElement={
                  <MultiSelect
                    value={convertToArray(filter.carrierIds)}
                    options={carriers}
                    onChange={e => {
                      /*setFilter((draft) => {
                        draft.carrierIds = e.value;
                      });*/
                      applyFilterValue('carrierIds', e.value);
                    }}
                    style={{ maxWidth: '100%' }}
                    optionValue={'id'}
                    optionLabel={'name'}
                    dataKey={'id'}
                    showClear={true}
                    placeholder={tr(`AllExpedition.carriersPlaceholder`, `Dopravce`)}
                  />
                }
              />
              <Column
                header={tr(`AllExpedition.additionalInfoHeaderTitle`, `Další informace`)}
                body={additionalInfoTemplate}
                filter={true}
                filterElement={
                  <Dropdown
                    value={filter.expeditionType}
                    options={Object.keys(ExpeditionTypeEnum).map(i => {
                      return {
                        name: tr(`ExpeditionTypeEnum.${i}`, i),
                        value: i,
                      };
                    })}
                    onChange={e => {
                      /*setFilter((draft) => {
                        draft.expeditionType = e.value;
                      });*/
                      applyFilterValue('expeditionType', e.value);
                    }}
                    style={{ maxWidth: '100%' }}
                    optionValue={'value'}
                    optionLabel={'name'}
                    showClear={true}
                    placeholder={tr(`AllExpedition.ltlFtlPlaceholder`, `FTL/LTL`)}
                  />
                }
              ></Column>
              <Column body={navBodyTemplate}></Column>
            </DataTable>
            <MassActionFileUploadDialog
              visible={visibleUploadDialog}
              onHide={() => {
                setVisibleUploadDialog(false);
              }}
              entityType={EntityTypeEnum.EXPEDITION}
              entityIds={selectedObjects.map(t => t.expeditionId)}
              onComplete={count => {
                loadFirstPage();
                setVisibleUploadDialog(false);
                toast.current.show({
                  severity: 'success',
                  summary: tr(`AllExpedition.dokumentyBylyNahrany`, `Dokumenty byly nahrany`),
                  detail: tr(`AllExpedition.nahraneDokumenty`, `K expedidici byly nahrany {{count}} dokumenty`, { count }, count),

                  life: 3000,
                });
                clear();
              }}
            />
            <MassActionExpeditionUpdateInternalInformation
              onComplete={() => {
                clear();
                loadFirstPage();
              }}
              selectedExpeditions={selectedObjects.map(t => {
                return {
                  expeditionId: t.expeditionId,
                  applicationId: t.applicationId,
                };
              })}
            />

            <MassActionExpedition
              onComplete={() => {
                clear();
                loadFirstPage();
              }}
              selectedExpeditions={selectedObjects.map(t => {
                return {
                  expeditionId: t.expeditionId,
                  applicationId: t.applicationId,
                };
              })}
            />

            <MassActionDachser
              onComplete={() => {
                clear();
              }}
              selectedExpeditions={selectedObjects.map(t => {
                return {
                  expeditionId: t.expeditionId,
                  applicationId: t.applicationId,
                  fromLocation: t.locationFrom,
                  toLocation: t.locationTo,
                };
              })}
            />

            <Toast ref={toast} />
            <PublishSidebar
              visible={publishSidebarVisible}
              onHide={() => setPublishSidebarVisible(false)}
              expeditionsToPublish={publishingIds}
              onComplete={() => {
                loadFirstPage();
                setPublishingIds([]);
                clear();
                setPublishSidebarVisible(false);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

const SmallerAndGrayDiv = styled.div`
  color: #999;
  font-size: smaller;
`;

const ActionButtons = styled.div`
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
`;

const TooltipWrapper = styled.div`
  .tooltip .ui-tooltip-text {
    width: 600px !important;
  }
`;

const JustifiedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.span`
  padding: 3px;
`;

const Row = styled.div`
  display: flex;

  label {
    margin-left: 5px;
    font-size: small;
  }
`;

export default AllExpedition;
