import React, { useContext, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import Label from '@components/obsolete/Form/Label/Label';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { EditTemplateInternalInformation } from '@api/logsteo-api.v2';
import useTranslation from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  templateId: string;
  onComplete: () => void;
}

const initialValues: EditTemplateInternalInformation = {
  subscribers: [],
  responsiblePersonId: null,
  internalNote: '',
};

const validationSchema = yup.object().shape({});

const EditInternalInformationSidebar: React.FC<ComponentProps> = ({ visible, onHide, templateId, onComplete }) => {
  const { tr } = useTranslation();
  const { cuUpdateEditInternalInformation, cuGetEditInternalInformation } = useContext(ApiContext);
  const formik = useFormik<EditTemplateInternalInformation>({
    initialValues,
    validationSchema,
    onSubmit: (v: any) => save(v),
  });
  const responsiblePersons = useResponsiblePersons();

  const save = (v: EditTemplateInternalInformation) => {
    cuUpdateEditInternalInformation(templateId, v, () => {
      onComplete();
    });
  };

  useEffect(() => {
    if (visible) {
      cuGetEditInternalInformation(templateId, d => {
        formik.setFieldValue(
          'subscribers',
          d.subscribers.map(t => t.userId),
        );
        formik.setFieldValue('responsiblePersonId', d.responsiblePerson?.userId);
        formik.setFieldValue('internalNote', d.internalNote);
      });
    }
  }, [visible]);

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal sidebar-modal-md sidebar-modal-edit-price"
        style={{ width: '750px' }}
      >
        <form onSubmit={formik.handleSubmit}>
          <h3>{tr(`EditInternalInformationSidebar.editInternalInformation`, `Edit internal information`)}</h3>
          <div className="p-field">
            <Label title={tr(`EditInternalInformationSidebar.responsiblePerson`, `Responsible person`)} />
            <div className="p-inputgroup">
              <Dropdown
                options={responsiblePersons}
                optionValue={'id'}
                optionLabel={'name'}
                value={formik.values.responsiblePersonId}
                dataKey={'id'}
                onChange={e => formik.setFieldValue('responsiblePersonId', e.target.value)}
              />
            </div>
          </div>
          <div className="p-field">
            <Label title={tr(`EditInternalInformationSidebar.subscribers`, `Subscribers`)} />
            <div className="p-inputgroup">
              <MultiSelect
                options={responsiblePersons}
                optionValue={'id'}
                optionLabel={'name'}
                value={formik.values.subscribers}
                dataKey={'id'}
                onChange={e => formik.setFieldValue('subscribers', e.target.value)}
              />
            </div>
          </div>
          <div className="p-field">
            <Label title={tr(`EditInternalInformationSidebar.internalNote`, `Internal note`)} />
            <div className="p-inputgroup">
              <InputTextarea value={formik.values.internalNote} onChange={formik.handleChange} name={'internalNote'} />
            </div>
          </div>
          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
            <Button label={tr('labelSave')} className="p-mr-3" type={'submit'} />
            <Button label={tr('labelCancel')} className="p-button-outlined p-button-text" onClick={() => onHide()} />
          </ButtonLayout>
        </form>
      </Sidebar>
    </>
  );
};

export default EditInternalInformationSidebar;
