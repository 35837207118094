import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  loadingReference: string;
}

const LoadingReferenceBadge: React.FC<ComponentProps> = ({ loadingReference }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(loadingReference) && (
        <Badge
          filled={false}
          value={loadingReference}
          label={tr(`LoadingReferenceBadge.referenceProNakladku`, `Reference pro nakladku`)}
          highlightedValue={false}
        ></Badge>
      )}
    </>
  );
};

export default LoadingReferenceBadge;
