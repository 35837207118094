import React from 'react';
import { RingilButtonProps } from '@components/framework/buttons/type.ts';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

const RingilButton: React.FC<RingilButtonProps> = ({ label, disabled = false, mode = 'filled-big', leftIcon, onClick }) => {
  return (
    <>
      {mode.startsWith('filled') && (
        <div
          onClick={onClick}
          className={twMerge(
            clsx(
              'flex inline-flex gap-2 bg-content-brand items-center text-white cursor-pointer self-start',
              { 'px-[24px] py-[16px] rounded-[48px] h-[48px] border-0': mode === 'filled-big' },
              { 'bg-content-tertiary cursor-text': disabled },
              { 'w-[48px] p-0 justify-center': !(label?.length > 0) },
              { 'w-[28px] p-0 justify-center': !(label?.length > 0) && mode.endsWith('-small') },
              { 'h-[28px] rounded-[48px] px-[12px] py-[8px]': mode.endsWith('-small') },
            ),
          )}
        >
          {isNotNullOrUndefined(leftIcon) && <div>{React.cloneElement(leftIcon, { color: 'var(--ringil3-base-secondary-color)' })}</div>}
          {label?.length > 0 && <div className={'text-white font-medium'}>{label}</div>}
        </div>
      )}

      {mode.startsWith('outlined') && (
        <div
          onClick={onClick}
          className={twMerge(
            clsx(
              'flex inline-flex gap-2 bg-white items-center text-content-brand cursor-pointer border-2 border-solid border-content-brand self-start',
              { 'px-[24px] py-[16px] rounded-[48px] h-[48px]': mode === 'outlined-big' },
              { 'bg-white cursor-text border-content-tertiary': disabled },
              { 'w-[48px] p-0 justify-center': !(label?.length > 0) },
              { 'w-[28px] p-0 justify-center': !(label?.length > 0) && mode.endsWith('-small') },
              { 'h-[28px] rounded-[48px] px-[12px] py-[8px]': mode.endsWith('-small') },
            ),
          )}
        >
          {isNotNullOrUndefined(leftIcon) && (
            <div>{React.cloneElement(leftIcon, { color: disabled ? 'var(--ringil3-content-tertiary-color)' : 'var(--ringil3-brand-color)' })}</div>
          )}
          {label?.length > 0 && <div className={twMerge(clsx('text-content-brand font-medium', { 'text-content-tertiary': disabled }))}>{label}</div>}
        </div>
      )}

      {mode === 'rect-small' && (
        <div>
          <div
            onClick={onClick}
            style={{ boxShadow: '0px 4px 4px 0px rgba(254, 80, 0, 0.15' }}
            className={twMerge(
              clsx(
                'flex inline-flex gap-2 border border-solid border-content-gr2 bg-white px-[6px] py-[9px] items-center leading-3 h-[32px] cursor-pointer self-start rounded',
                {
                  'text-content-tertiary cursor-text': disabled,
                },
              ),
            )}
          >
            {isNotNullOrUndefined(leftIcon) && <div>{React.cloneElement(leftIcon, { color: disabled ? 'var(--ringil3-content-tertiary-color)' : null })}</div>}
            {label?.length > 0 && (
              <div className={twMerge(clsx('text-content-secondary text-sm font-medium leading-3', { 'text-content-tertiary': disabled }))}>{label}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RingilButton;
