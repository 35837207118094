import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import { Col } from '@components/styles';
import LinkButton from '@components/ringil3/Buttons/LinkButton';
import { CreateTimeslotRequest } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  onCreateReservationClick: (request: CreateTimeslotRequest) => void;
  onAskCarrierClick: () => void;
}

export const useCreateCreateReservationDialog = () => {
  const [createTimeslotRequest, setCreateTimeslotRequest] = useState<CreateTimeslotRequest>(undefined);

  const CreateReservationDialog: React.FC<ComponentProps> = ({ onCreateReservationClick, onAskCarrierClick }) => {
    const { tr } = useTranslationLgs();
    return (
      <>
        {/*
        // @ts-ignore*/}
        <Dialog
          header={<div>{tr(`CreateReservationDialog.title`, `Make reservation`)}</div>}
          visible={isNotNullOrUndefined(createTimeslotRequest)}
          onHide={() => setCreateTimeslotRequest(undefined)}
          style={{ width: '450px' }}
        >
          <Col>
            <LinkButton
              label={tr('CreateReservationDialog.iWillPlanItMyself', 'I will plan it myself')}
              onClick={() => {
                onCreateReservationClick(createTimeslotRequest);
                setCreateTimeslotRequest(undefined);
              }}
              rightIcon={'/images/icons/ringil3/buttons/link.svg'}
            />
            <LinkButton
              label={tr('CreateReservationDialog.askTheCarrier', 'Ask the carrier')}
              onClick={() => {
                onAskCarrierClick();
                setCreateTimeslotRequest(undefined);
              }}
              rightIcon={'/images/icons/ringil3/buttons/link.svg'}
            />
          </Col>
        </Dialog>
      </>
    );
  };

  return { CreateReservationDialog, setCreateTimeslotRequest };
};
