import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SvgETransport from '@components/ringil3/RingilTabs/icons/ETransport.tsx';
import Tab from '@components/framework/tabs/Tab.tsx';
import styled from 'styled-components';
import SvgTabExpedition from '@components/ringil3/icons/TabExpedition.tsx';
import SvgTabInternalInfo from '@components/ringil3/icons/TabInternalInfo.tsx';
import SvgTabAttachments from '@components/ringil3/icons/TabAttachments.tsx';

interface ComponentProps {
  onPaneChange?: (pane: RingilReservationTab) => void;
  showEtransport: boolean;
}

export type RingilReservationTab = 'reservation' | 'internalinfo' | 'attachments' | 'eTransport';

export const useCreateRingilReservationTabs = () => {
  const [selectedPanel, setSelectedPanel] = useState<RingilReservationTab>('reservation');

  const ReservationTabs: React.FC<ComponentProps> = ({ showEtransport, onPaneChange = () => {} }) => {
    const { tr } = useTranslationLgs();
    return (
      <TabsInner>
        <Tab
          icon={<SvgTabExpedition />}
          label={tr(`RingilTabs.expedition`, `Expedition`)}
          active={selectedPanel === 'reservation'}
          onClick={() => {
            setSelectedPanel('reservation');
            onPaneChange('reservation');
          }}
        ></Tab>
        {showEtransport === true && (
          <Tab
            icon={<SvgETransport />}
            label={tr(`RingilTabs.eTransprt`, `eTransport`)}
            active={selectedPanel === 'eTransport'}
            onClick={() => {
              setSelectedPanel('eTransport');
              onPaneChange('eTransport');
            }}
          ></Tab>
        )}

        <Tab
          icon={<SvgTabInternalInfo />}
          label={tr(`RingilTabs.internalInfo`, `Internal info`)}
          active={selectedPanel === 'internalinfo'}
          onClick={() => {
            setSelectedPanel('internalinfo');
            onPaneChange('internalinfo');
          }}
        ></Tab>
        <Tab
          icon={<SvgTabAttachments />}
          label={tr(`RingilTabs.attachments`, `Attachments`)}
          active={selectedPanel === 'attachments'}
          onClick={() => {
            setSelectedPanel('attachments');
            onPaneChange('attachments');
          }}
        ></Tab>
      </TabsInner>
    );
  };
  return { ReservationTabs, selectedPanel };
};

const TabsInner = styled.div`
  display: flex;
  margin: 1.5rem 0 0.5rem 0;
  gap: 0.5rem;
  border-bottom: 2px solid var(--ringil3-brand-color);
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--ringil3-base-tertiary-color);
`;
