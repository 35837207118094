import React, { PropsWithChildren, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { ApiContext } from '../../api/api';
import { useAuth0 } from '@auth0/auth0-react';
import { PermissionTypesEnum, UserInfoOutDTO } from '../../api/logsteo-api.v2';
import { dumpVars, isNullOrUndefined } from '../../utils/utils.tsx';

interface ComponentProps {}

interface AuthenticateUserType {
  hasModule: (moduleName: string, inheritingModule: boolean) => boolean;
  loggedUser?: UserInfoOutDTO;
  isAdmin: () => boolean;
  isCustomerAdmin: () => boolean;
  isCarrier: () => boolean;
  isCompanyAdmin: () => boolean;
  isCarrierAdmin: () => boolean;
  isCustomer: () => boolean;
  isReady: boolean;
  hasPermission: (permission: PermissionTypesEnum[]) => boolean;
  hasPermissionAndModule: (moduleName: string, inheritingModule: boolean, oneOfPermission: PermissionTypesEnum[]) => boolean;
}

// @ts-ignore
export const AuthenticatedUserContext = React.createContext<AuthenticateUserType>(null);

const AuthenticatedUser: React.FC<PropsWithChildren<ComponentProps>> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const { getUserInfo } = useContext(ApiContext);
  const [loggedUser, setLoggedUser] = useState<UserInfoOutDTO>();
  const [isReady, setIsReady] = useState(false);

  useLayoutEffect(() => {
    if (isAuthenticated) {
      getUserInfo(d => {
        setLoggedUser(d);
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setIsReady(!isNullOrUndefined(loggedUser));
  }, [loggedUser]);

  const isAdmin = () => loggedUser != null && loggedUser.securityRoles.some(t => t === 'ROLE_ADMIN');
  const isCompanyAdmin = () => loggedUser != null && loggedUser.securityRoles.some(t => t === 'ROLE_COMPANY_ADMIN');
  const isCustomerAdmin = () => loggedUser != null && loggedUser.securityRoles.some(t => t === 'ROLE_CUSTOMER_ADMIN');
  const isCarrier = () => loggedUser != null && loggedUser.securityRoles.some(t => t === 'ROLE_CARRIER');
  const isCustomer = () => loggedUser != null && loggedUser.securityRoles.some(t => t === 'ROLE_CUSTOMER');
  const isCarrierAdmin = () => loggedUser != null && loggedUser.securityRoles.some(t => t === 'ROLE_CARRIER_ADMIN');
  const hasPermissionAndModule = (moduleName: string, inheritingModule: boolean, oneOfPermission: PermissionTypesEnum[]): boolean => {
    return (
      loggedUser != null &&
      loggedUser.modules.some(t => t.companyModule === moduleName && (!isNullOrUndefined(inheritingModule) ? t.inheritingModule === inheritingModule : true)) &&
      loggedUser.permissionTypes.some(t => oneOfPermission.some(p => p === t))
    );
  };
  const hasPermission = (permission: PermissionTypesEnum[]): boolean => {
    return loggedUser != null && loggedUser.permissionTypes.some(userPermission => permission.some(p => p === userPermission));
  };

  const hasModule = (moduleName: string, inheritingModule: boolean): boolean => {
    return (
      loggedUser != null &&
      loggedUser.modules.some(t => t.companyModule === moduleName && (!isNullOrUndefined(inheritingModule) ? t.inheritingModule === inheritingModule : true))
    );
  };

  return (
    <AuthenticatedUserContext.Provider
      value={{
        loggedUser,
        isAdmin,
        isCustomerAdmin,
        isCarrier,
        isCustomer,
        isCarrierAdmin,
        isReady,
        hasModule,
        isCompanyAdmin,
        hasPermissionAndModule,
        hasPermission,
      }}
    >
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

export default AuthenticatedUser;
