import React from 'react';
import { MenuItemModel } from './types.ts';
import SvgMenuRight from '../../app/icons/menu/MenuRight.tsx';
import SubMenuItem from './SubMenuItem.tsx';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import SvgMenuDown from '@app/icons/menu/MenuDown.tsx';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils.tsx';

const MenuItem: React.FC<MenuItemModel> = ({ title, icon, note, subMenu, elementCount, url, mode = 'open-inside', isOpen = false, smallMenu = false, onClick }) => {
  const location = useLocation();

  return (
    <>
      <li className={'group relative cursor-pointer select-none'}>
        <div
          onClick={e => onClick(e)}
          className={twMerge(
            clsx('flex p-4 h-[48px]  hover:bg-base-base items-center justify-between', { 'justify-center': smallMenu }, { 'text-content-brand': false }),
          )}
        >
          <Link to={url} className={'flex gap-4 items-center text-content-primary'}>
            {icon && <>{icon}</>}
            {!smallMenu && (
              <>
                {note ? (
                  <div className={'flex-col flex'}>
                    <span>{title}</span>
                    <span className={'text-xs text-gray-400'}>{note}</span>
                  </div>
                ) : (
                  <div className={'flex-col flex'}>
                    <span>{title}</span>
                  </div>
                )}
              </>
            )}
          </Link>
          <div className={'flex flex-row'}>
            {elementCount > 0 && elementCount < 10 && (
              /*              <div className="h-4 px-1 bg-[#ff5a00] rounded-full flex-col justify-center items-center inline-flex">
                <div className="text-center text-[#f6f7f8] text-xs font-normal font-['Open Sans']">1</div>
              </div>*/
              <div className="flex items-center justify-center w-[18px] h-[18px] px-2 bg-content-brand text-xs text-white rounded-full">{elementCount}</div>
            )}
            {elementCount > 9 && elementCount < 100 && (
              <div className="flex items-center justify-center  px-2 bg-content-brand text-white rounded-full">{elementCount}</div>
            )}
            {elementCount > 99 && <div className="flex items-center justify-center  px-2 bg-content-brand text-white rounded-full">99+</div>}
            {subMenu && Array.isArray(subMenu) && !smallMenu && (
              <>
                {mode == 'open-inside' && subMenu && subMenu?.length > 0 && !isOpen && <SvgMenuRight />}
                {mode == 'open-inside' && subMenu && subMenu?.length > 0 && isOpen && <SvgMenuDown />}
                {mode != 'open-inside' && subMenu && subMenu?.length > 0 && isOpen && <SvgMenuRight />}
              </>
            )}
            {subMenu && !Array.isArray(subMenu) && !smallMenu && <>{mode != 'open-inside' && subMenu && <SvgMenuRight />}</>}
          </div>
        </div>

        {subMenu && !smallMenu && (
          <ul
            className={twMerge(
              clsx(
                'w-[270px]',
                {
                  'hidden absolute group-hover:block left-[270px] top-0 shadow-md bg-base-tertiary z-20': mode === 'open-outside-top',
                },
                {
                  'hidden absolute group-hover:block left-[270px] bottom-0 shadow-md bg-base-tertiary z-20': mode === 'open-outside-bottom',
                },
                { hidden: mode === 'open-inside' && !isOpen },
              ),
            )}
          >
            {Array.isArray(subMenu) && (
              <>
                {subMenu
                  .filter(t => t.render)
                  .map((subItem, index) => {
                    return <SubMenuItem key={index} title={subItem.title} onClick={subItem.onClick} url={subItem.url} />;
                  })}
              </>
            )}
            {isNotNullOrUndefined(subMenu) && !Array.isArray(subMenu) && <>{subMenu}</>}
          </ul>
        )}
      </li>
    </>
  );
};

export default MenuItem;
