import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

interface ComponentProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const RingilInputSwitch: React.FC<ComponentProps> = ({ value, label, onChange }) => {
  return (
    <div className={'flex flex-row gap-2 items-center'}>
      <InputSwitch checked={value} onChange={e => onChange(e.target.value)} />
      <label>{label}</label>
    </div>
  );
};

export default RingilInputSwitch;
