import React, { useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { RingilDialog } from '@components/obsolete/RingilDialog/RingilDialog.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps<T> {
  onSave: (rows: T[]) => void;
}

export const useCreateInputPasteTabular = <T,>(keys: (keyof T)[]) => {
  const [visible, setVisible] = useState(false);
  const { tr } = useTranslationLgs();

  const PasteTabularButton: React.FC<{}> = () => {
    return <RingilButton onClick={() => setVisible(true)} label={tr(`InputPasteTabular.pasteTabularData`, `Paste tabular data`)} mode={'outlined-small'}></RingilButton>;
  };

  const convertArrayToObjects = <T,>(keys: (keyof T)[], data: any[][]): T[] => {
    return data.map(row => {
      const obj: Partial<T> = {};
      keys.forEach((key, index) => {
        obj[key] = isNullOrUndefined(row[index]) ? null : row[index].trim();
      });
      return obj as T;
    });
  };

  const InputPasteTabular: React.FC<ComponentProps<T>> = ({ onSave }) => {
    const [rows, setRows] = useState<T[]>([]);
    const { tr } = useTranslationLgs();

    const handlePaste = async () => {
      try {
        const clipboardText = await navigator.clipboard.readText();
        console.log(clipboardText);

        // Zpracování CSV dat
        const rows = clipboardText.trim().split('\n');
        console.log(rows);
        const parsedData = rows.map(row => row.split('\t'));
        console.log(parsedData);

        // Aktualizace dat
        setRows(convertArrayToObjects(keys, parsedData));
      } catch (error) {
        console.error('Failed to read from clipboard: ', error);
      }
    };

    const { Column, Table } = useCreateTable();

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
          event.preventDefault();
          handlePaste();
        }
      };

      if (visible == true) window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [visible]);

    return (
      <RingilDialog
        visible={visible}
        headerText={'Paste content'}
        onHide={() => setVisible(false)}
        onSave={() => {
          onSave(rows);
          setVisible(false);
        }}
        width={'1280px'}
      >
        <div className={'w-full'}>
          <Table value={rows}>
            {keys.map((key, indexKey) => (
              <Column key={indexKey} header={key as string} body={row => <>{`${row[key]}`}</>}></Column>
            ))}
          </Table>
        </div>
      </RingilDialog>
    );
  };

  return { InputPasteTabular, PasteTabularButton, visible, setVisible };
};
