import React, { PropsWithChildren } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  headerText?: string;
  visible: boolean;
  onHide: () => void;
  onSave: () => void;
  showButtonBar?: boolean;
  wide?: boolean;
  saveButtonDisabled?: boolean;
  width?: string;
}

export const RingilDialog: React.FC<PropsWithChildren<ComponentProps>> = ({
  headerText,
  visible,
  onHide,
  onSave,
  children,
  showButtonBar = true,
  wide = false,
  saveButtonDisabled = false,
  width,
}) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {/*
        // @ts-ignore*/}
      <Dialog
        onHide={() => onHide()}
        visible={visible}
        closeOnEscape={false}
        blockScroll={true}
        header={headerText}
        position={'center'}
        style={{
          width: width ? width : wide ? 'var(--default-wide-dialog-width)' : 'var(--default-dialog-width)',
        }}
      >
        {children}
        {showButtonBar && (
          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
            <Button label={tr(`RingilDialog.save`, `Save`)} className="p-mr-2" onClick={e => onSave()} disabled={saveButtonDisabled} />
            <Button label={tr(`RingilDialog.cancel`, `Cancel`)} className="p-button-text" onClick={() => onHide()} />
          </ButtonLayout>
        )}
      </Dialog>
    </>
  );
};
