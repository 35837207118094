import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Card } from 'primereact/card';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import {
  AllReservationsFilter,
  PageResponseReservationListPreviewOutDTO,
  PermissionTypesEnum,
  ReservationDataExportResponseOutDTO,
  ReservationListPreviewOutDTO,
} from '@api/logsteo-api.v2';
import { isNotNullOrUndefined, isNullOrUndefined, useLocalStorage, visualiseWorkflowStateState } from '@utils/utils';
import { Column } from 'primereact/column';
import { dayJsToDate, mapFromAPIDateTime, mapFromAPIToDateLong, mapFromAPIToDateShort, mapFromAPIToTime, mapToAPIDateTime, shortDateFormat } from '@utils/date';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { exportExcel } from '@utils/exports';
import { NoWrapText, RowWithGap } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { RecycleButton } from '@components/obsolete/Buttons/RecycleButton/RecycleButton.tsx';
import { Link } from 'react-router-dom';
import DeleteButton from '@components/obsolete/DeleteButton/DeleteButton.tsx';
import { useDuplicateTimeslot } from '@components/obsolete/timeslot/duplicate/duplicate.tsx';

interface ComponentProps {}

export const ListReservations: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const navigation = useNavigation();

  const { getReservations, deleteReservation, exportReservations } = useContext(ApiContext);
  const { loggedUser, hasPermission } = useContext(AuthenticatedUserContext);
  const [storedFilter, setStoredFilter] = useLocalStorage('TIMESLOT_LIST_FILTER', undefined);

  const [data, setData] = useState<PageResponseReservationListPreviewOutDTO>();

  const [revealTabTexts, setRevealTabTexts] = useLocalStorage<boolean>('ringil_tags_expanded', false);

  const { setTimeslotIdForDuplication, DuplicateTimeslot } = useDuplicateTimeslot();
  const { CreateTimeslotSidebar, createTimeslotRequest, setCreateTimeslotRequest } = useCreateTimeslotSidebar();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort, applyFilter } = useFilter<AllReservationsFilter>(
    (page, filter) => {
      load();
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  useEffect(() => {
    if (isNotNullOrUndefined(storedFilter)) {
      applyFilter(storedFilter);
    }
  }, [storedFilter]);

  const canEdit = (data: ReservationListPreviewOutDTO) => {
    /*alert(JSON.stringify({ since: data.timeslot?.since, after: dayjs().add(60, 'minutes') }));*/
    // jsem vlastnik
    if (data.customerId == loggedUser.companyId) return true;

    if (data.customerCode !== 'MAGNA-CARTECH') return true;

    // neni zadany datum
    if (isNullOrUndefined(data.timeslot)) return true;

    // neni mene jak 60 minut do zacatku akce
    if (data.customerCode === 'MAGNA-CARTECH' && mapFromAPIDateTime(data.timeslot?.since).isAfter(dayjs().add(60, 'minutes'))) return true;

    return false;
  };

  const load = () => {
    getReservations(
      {
        pageSize: page.pageSize,
        pageNumber: page.pageNumber,
        sortField: sort.sortField,
        sortDirection: sort.sortDirection,
      },
      filter,
      d => {
        setData(d);
      },
    );
  };

  const hasReservation = (data: ReservationListPreviewOutDTO): boolean => {
    if (isNullOrUndefined(data?.timeslot)) return false;
    if (isNullOrUndefined(data?.timeslot.since)) return false;
    if (isNullOrUndefined(data?.timeslot.till)) return false;

    return true;
  };

  const renderTimeslot = (data: ReservationListPreviewOutDTO) => (
    <>
      {hasReservation(data) ? (
        <div>
          <div>{mapFromAPIToDateShort(data.timeslot.since)}</div>
          <div>
            {mapFromAPIToTime(data.timeslot.since)} - {mapFromAPIToTime(data.timeslot.till)}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
  const renderLocationAndRamp = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.locationName}</div>
      <div>{data.rampName}</div>
    </>
  );

  const renderCustomer = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.customerName}</div>
    </>
  );

  const renderCarrier = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.carrierName}</div>
      <div>{[data.driverName, data.carPlate].filter(t => t != null).join(', ')}</div>
    </>
  );
  const renderReference = (data: ReservationListPreviewOutDTO) => (
    <>
      <div>{data.reservationName}</div>
      <div>{data.orders.map(t => t.orderName).join(', ')}</div>
    </>
  );
  const renderManipulationUnits = (data: ReservationListPreviewOutDTO) => (
    <>
      {data?.orders && (
        <div>
          {data.orders
            .map(t => t.items)
            .flat(1)
            .reduce((acc, val) => {
              if (acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code) === -1) {
                acc.push({
                  cargoTemplate: val.cargoTemplate,
                  amount: val.amount,
                });
              } else {
                const index = acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code);
                acc[index].amount += val.amount;
              }
              return acc;
            }, [])
            .map(t => {
              return `${t.amount} x ${t.cargoTemplate.name}`;
            })
            .join(', ')}
        </div>
      )}
    </>
  );
  const renderApplicationId = (data: ReservationListPreviewOutDTO) => (
    <>
      {/*<Link href={`/timeslots/${data.id}`}>*/}
      <Link to={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(data.id))}>
        <TextAndIcon>
          <span className={'text-blue'} style={{ cursor: 'pointer' }}>
            {data.applicationId}
            {data.hasETransportRO ? <>(E)</> : <></>}
          </span>
          {data?.customerId == loggedUser.companyId && <span>{visualiseWorkflowStateState(data?.state, tr, false)}</span>}
        </TextAndIcon>
      </Link>

      {loggedUser && loggedUser.companyId === data.customerId && (
        <LabelWrapper>
          {data.labels
            .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
            .map((t, tIndex) => (
              <LabelDiv
                color={t.labelColor}
                key={tIndex}
                onClick={e => {
                  setRevealTabTexts(v => !v);
                }}
              >
                {revealTabTexts && <div>{t.labelValue}</div>}
              </LabelDiv>
            ))}
        </LabelWrapper>
      )}
    </>
  );

  const deleteRow = (reservationId: string) => {
    deleteReservation(reservationId, () => {
      load();
    });
  };

  const actionsButtons = (data: ReservationListPreviewOutDTO) => (
    <>
      <NoWrapText>
        {data.customerId == loggedUser.companyId && data.timeslot?.since && hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DUPLICATE]) && (
          <RecycleButton
            onClick={() => {
              setTimeslotIdForDuplication(data.id);
            }}
            mode={'round'}
          />
        )}

        {canEdit(data) && hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DELETE]) && (
          <DeleteButton
            onDelete={() => deleteRow(data.id)}
            deleteDialogTitle={tr('reservations.confirmDeteleAction', 'Confirm detele action')}
            deleteDialogMessage={tr('reservations.doYouWantToDeleteTheReservation', 'Do you want to delete the reservation?')}
          />
        )}
      </NoWrapText>
    </>
  );

  const exportExcelHandler = () => {
    exportReservations(filter, d => {
      exportExcel(d.map(mapToExport), 'reservations');
    });
  };

  const mapToExport = (reservation: ReservationDataExportResponseOutDTO) => {
    return {
      applicationId: reservation.applicationId,
      locationName: reservation.locationName,
      since: mapFromAPIToDateLong(reservation?.since),
      till: mapFromAPIToDateLong(reservation?.till),
      carrierName: reservation.carrierName,
      reference: reservation.reference,
      carPlate: reservation.carPlate,
    };
  };

  const saveFilter = () => {
    setStoredFilter(filter);
  };

  const deleteFilter = () => {
    setStoredFilter(undefined);
    applyFilter({
      applicationId: null,
      locationName: null,
      timeslotDaySince: null,
      timeslotDayTill: null,
      carrierName: null,
      referenceOrderId: null,
      reservationName: null,
      driverName: null,
      carPlate: null,
    });
  };

  if (isNullOrUndefined(filter)) return <>no filter</>;

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Card>
        <Row>
          <Heading>{tr(`reservations.reservations`, `Reservations`)}</Heading>
          {hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_CREATE]) && (
            <Button
              label={tr(`reservations.newReservation`, `New reservation`)}
              onClick={e => {
                /*router.push('/timeslots/create-reservation');*/
                //navigation.navigate(navigation.urlFunctions.createReservation());
                setCreateTimeslotRequest({
                  applicationId: null,
                  companyLocationId: null,
                  preferredDate: '',
                  companyLocationCustomerId: null,
                  expeditionLocationId: null,
                  expeditionId: null,
                });
              }}
            ></Button>
          )}
        </Row>
        <FilterRow>
          <div className={'field'}>
            <InputText
              value={filter?.applicationId ? filter?.applicationId : ''}
              placeholder={tr('reservations.applicationId', 'Application ID')}
              onChange={e => applyFilterValue('applicationId', e.target.value)}
            />
          </div>
          <div className={'field'}>
            <InputText
              value={filter?.locationName ? filter?.locationName : ''}
              placeholder={tr('reservations.locality', 'Locality')}
              onChange={e => applyFilterValue('locationName', e.target.value)}
            />
          </div>
          <div className={'field'}>
            <Calendar
              dateFormat={shortDateFormat}
              value={filter.timeslotDaySince ? dayJsToDate(dayjs(filter.timeslotDaySince)) : null}
              placeholder={tr('reservations.since', 'Since')}
              onChange={(e: any) => applyFilterValue('timeslotDaySince', mapToAPIDateTime(dayjs(e.value as Date)))}
              style={{ width: '140px' }}
              locale={'cs'}
              showButtonBar={true}
              showIcon
              onClearButtonClick={() => applyFilterValue('timeslotDaySince', null)}
            />
          </div>
          <div className={'field'}>
            <Calendar
              dateFormat={shortDateFormat}
              value={filter.timeslotDayTill ? dayJsToDate(dayjs(filter.timeslotDayTill)) : null}
              placeholder={tr('reservations.till', 'Till')}
              onChange={(e: any) => applyFilterValue('timeslotDayTill', mapToAPIDateTime(dayjs(e.value as Date)))}
              style={{ width: '140px' }}
              locale={'cs'}
              showButtonBar={true}
              showIcon
              onClearButtonClick={() => applyFilterValue('timeslotDayTill', null)}
            />
          </div>
          <div className={'field'}>
            <InputText
              value={filter?.carrierName ? filter?.carrierName : ''}
              placeholder={tr('reservations.carrierName', 'Carrier name')}
              onChange={e => applyFilterValue('carrierName', e.target.value)}
            />
          </div>
          <div className={'field'}>
            <InputText
              value={filter?.referenceOrderId ? filter?.referenceOrderId : ''}
              placeholder={tr('reservations.referenceID', 'Reference')}
              onChange={e => applyFilterValue('referenceOrderId', e.target.value)}
            />
          </div>
          <div className={'field'}>
            <InputText
              value={filter?.reservationName ? filter?.reservationName : ''}
              placeholder={tr('reservations.reservationName', 'Reservation name')}
              onChange={e => applyFilterValue('reservationName', e.target.value)}
            />
          </div>
          <div className={'field'}>
            <InputText
              value={filter?.driverName ? filter?.driverName : ''}
              placeholder={tr('reservations.driverName', 'Driver name')}
              onChange={e => applyFilterValue('driverName', e.target.value)}
            />
          </div>
          <div className={'field'}>
            <InputText
              value={filter?.carPlate ? filter?.carPlate : ''}
              placeholder={tr('reservations.carPlate', 'Car plate')}
              onChange={e => applyFilterValue('carPlate', e.target.value)}
            />
          </div>
          <Row>
            <Button type="button" icon="pi pi-file-excel" onClick={exportExcelHandler} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
            <RowWithGap>
              <Button label={tr(`Reservations.saveFilter`, `Save filter`)} onClick={e => saveFilter()} />
              {isNotNullOrUndefined(storedFilter) && <Button label={tr(`Reservations.clearFilter`, `Clear filter`)} onClick={e => deleteFilter()} />}
            </RowWithGap>
          </Row>
        </FilterRow>
        <TableWrapper>
          {data?.data && (
            <>
              {loggedUser.companyCode != 'Svijany' && (
                <>
                  {/*
                    // @ts-ignore*/}
                  <DataTable
                    value={data.data}
                    rows={data.size}
                    paginator={true}
                    lazy={true}
                    totalRecords={data.total}
                    onPage={onPage}
                    first={(data.page - 1) * data.size}
                    sortMode={'single'}
                    onSort={e => {
                      applySorting(e.sortField, e.sortOrder === 1 ? 'ASC' : 'DESC');
                    }}
                    sortField={sort.sortField}
                    sortOrder={sort.sortDirection === 'ASC' ? 1 : -1}
                  >
                    <Column body={renderApplicationId} header={'ID'} style={{ width: '8rem' }} sortable={true} sortField={'applicationId'} />
                    <Column body={renderLocationAndRamp} header={tr(`reservations.expeditionPlace`, `Expedition place`)} />
                    <Column body={renderTimeslot} header={tr(`reservations.timeslot`, `Timeslot`)} sortable={true} sortField={'timeslotDaySince'} />
                    <Column body={renderCustomer} header={tr(`reservations.customer`, `Customer`)} />
                    <Column body={renderCarrier} header={tr(`reservations.carrier`, `Carrier`)} />
                    <Column body={renderReference} header={tr(`reservations.reference2`, `Reference`)} />
                    <Column body={renderManipulationUnits} header={tr(`reservations.manipulationUnits`, `Manipulatin units`)} />
                    <Column body={actionsButtons} header={tr(`reservations.actions`, `Actions`)} />
                  </DataTable>
                </>
              )}
              {loggedUser.companyCode == 'Svijany' && (
                <>
                  {/*
        // @ts-ignore*/}
                  <DataTable
                    value={data.data}
                    rows={data.size}
                    paginator={true}
                    lazy={true}
                    totalRecords={data.total}
                    onPage={onPage}
                    first={(data.page - 1) * data.size}
                  >
                    <Column body={renderReference} header={tr(`reservations.reference2`, `Reference`)} />
                    <Column body={renderCarrier} header={tr(`reservations.carrier`, `Carrier`)} />
                    <Column body={renderApplicationId} header={'ID'} style={{ width: '8rem' }} />
                    <Column body={renderLocationAndRamp} header={tr(`reservations.expeditionPlace`, `Expedition place`)} />
                    <Column body={renderTimeslot} header={tr(`reservations.timeslot`, `Timeslot`)} />
                    <Column body={renderCustomer} header={tr(`reservations.customer`, `Customer`)} />
                    <Column body={renderManipulationUnits} header={tr(`reservations.manipulationUnits`, `Manipulatin units`)} />
                    <Column body={actionsButtons} header={tr(`reservations.actions`, `Actions`)} />
                  </DataTable>
                </>
              )}
            </>
          )}
        </TableWrapper>
      </Card>
      {/*<ConfirmDialog
          visible={reservation2Delete}
          dialogTitle={tr('reservations.confirmDeteleAction', 'Confirm detele action')}
          dialogMessage={tr('reservations.doYouWantToDeleteTheReservation', 'Do you want to delete the reservation?')}
          onConfirm={() => deleteRow(reservation2Delete)}
          onCancel={() => setReservation2Delete(undefined)}
          onAcceptButtonText={tr(`reservations.confirm`, `Confirm`)}
          onCancelButtonText={tr(`reservations.back`, `Back`)}
        />*/}
      <DuplicateTimeslot
        onComplete={() => {
          load();
        }}
      />
      {createTimeslotRequest && (
        <CreateTimeslotSidebar
          onContinue={reservation => {
            load();
          }}
          headerDisabled={false}
        />
      )}
    </>
  );
};

const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const TableWrapper = styled.div`
  display: flex;
  margin-top: 25px;
`;

const Heading = styled.h1`
  margin: auto 0;
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
`;
const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.3rem 1rem;
  color: white;
  background-color: ${props => props.color || 'black'};
  cursor: pointer;
  margin-top: 0.5rem;
`;

const TextAndIcon = styled.div`
  display: flex;
  gap: 0.5rem;
`;
