import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { ComputeReservationDurationConfiguration, UpsertTimeslotModuleSettingRequestInDTO } from '@api/logsteo-api.v2.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { InputNumber } from 'primereact/inputnumber';
import useForm from '@hooks/useForm/useForm.tsx';
import * as yup from 'yup';
import RingilInputSwitch from '@components/framework/input/RingilInputSwitch/RingilInputSwitch.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgCoButtonPlus from '@components/framework/buttons/icons/CoButtonPlus.tsx';
import InputSKU from '@components/ringil3/Input/InputSKU/InputSKU.tsx';
import SvgCoButtonDelete from '@components/framework/buttons/icons/CoButtonDelete.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';

interface ComponentProps {
  companyId: string;
}

const ModuleSettingsTimeslot: React.FC<ComponentProps> = ({ companyId }) => {
  const { getCreateEditReservationSettings, upsertTimeslotModuleSettings } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [loadedData, setLoadedData] = useState<ComputeReservationDurationConfiguration>();
  const { showToastMessage } = useContext(ToastContext);

  const form = useForm<UpsertTimeslotModuleSettingRequestInDTO>(yup.object().shape({}), null, v => save(v), false, false);

  const save = (data: UpsertTimeslotModuleSettingRequestInDTO) => {
    upsertTimeslotModuleSettings(companyId, data, () => {
      showToastMessage('Saved', 'Timeslots settings saved', 'success');
    });
  };

  useEffect(() => {
    getCreateEditReservationSettings(companyId, d => {
      setLoadedData(d);
      form.overwriteValues({
        units: d.units.map(u => ({ cargoItemType: u.cargoItemType, duration: u.duration })),
        ...d,
      });
    });
  }, []);

  const add = () => {
    form.setFieldValue(form.names.units, [...form.values.units, { cargoItemType: '', duration: 0 }]);
  };

  const deleteHandler = (index: number) => {
    form.setFieldValue(
      form.names.units,
      form.values.units.filter((_, i) => i !== index),
    );
  };

  if (isNullOrUndefined(form?.values)) return <></>;

  return (
    <>
      <div>
        <BreadCrumbComponent items={[{ label: 'Timeslot module settings' }, { label: loadedData?.companyName }]} />
        <COHeadline title={'Timeslot module settings'} variant={'h2'} />
        <div className={'flex flex-col gap-4 w-[500px]'}>
          <Field label={'Default duration'} required>
            <InputNumber value={form.values.defaultDuration} onChange={e => form.setFieldValue(form.names.defaultDuration, e.value)} />
          </Field>
          <Field label={'Minimal duration'} required>
            <InputNumber value={form.values.minimalDuration} onChange={e => form.setFieldValue(form.names.minimalDuration, e.value)} />
          </Field>
          <HorizontalLine variant={'tertiary'} />
          <COHeadline title={'Editing reservation'} variant={'h3'} />
          <RingilInputSwitch value={form.values.ownerCanEdit} onChange={v => form.setFieldValue(form.names.ownerCanEdit, v)} label={'Owner can edit'} />
          <RingilInputSwitch
            value={form.values.counterPartyCanEdit}
            onChange={v => form.setFieldValue(form.names.counterPartyCanEdit, v)}
            label={'Counter party can edit'}
          />

          <HorizontalLine variant={'tertiary'} />
          <COHeadline title={'Required slots'} variant={'h3'} />
          <RingilInputSwitch value={form.values.slotsRequired} onChange={v => form.setFieldValue(form.names.slotsRequired, v)} label={'Owner have to choose slots'} />
          <RingilInputSwitch
            value={form.values.slotsRequiredCounterParty}
            onChange={v => form.setFieldValue(form.names.slotsRequiredCounterParty, v)}
            label={'Counter party have to choose slots'}
          />

          <HorizontalLine variant={'tertiary'} />
          <COHeadline title={'Edit duration'} variant={'h3'} />
          <RingilInputSwitch value={form.values.editDuration} onChange={v => form.setFieldValue(form.names.editDuration, v)} label={'Owner can edit duration'} />
          <RingilInputSwitch
            value={form.values.editDurationCounterParty}
            onChange={v => form.setFieldValue(form.names.editDurationCounterParty, v)}
            label={'Counter party can change edit duration'}
          />

          <HorizontalLine variant={'tertiary'} />
          <COHeadline title={'Offer historical slots'} variant={'h3'} />
          <RingilInputSwitch
            value={form.values.showHistoricalFreeSlots}
            onChange={v => form.setFieldValue(form.names.showHistoricalFreeSlots, v)}
            label={'Owner can choose historical slots'}
          />
          <RingilInputSwitch
            value={form.values.showHistoricalFreeSlotsCounterParty}
            onChange={v => form.setFieldValue(form.names.showHistoricalFreeSlotsCounterParty, v)}
            label={'Counter party can choose historical slots'}
          />

          <HorizontalLine variant={'tertiary'} />
          <div className={'flex flex-row justify-between items-center'}>
            <COHeadline title={'Units'} variant={'h3'} />
            <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonPlus />} onClick={add} />
          </div>
          {form.values?.units?.map((unit, unitIndex) => {
            return (
              <div className={'grid grid-cols-2 gap-3'} key={unitIndex}>
                <Field label={'Manipulation unit'} required>
                  <InputSKU skuCode={unit.cargoItemType} onChange={v => form.setFieldValue(`units[${unitIndex}].cargoItemType`, v)} fullWidth />
                </Field>
                <Field
                  label={'Time for manipulation unit'}
                  required
                  actionButtons={<RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} onClick={() => deleteHandler(unitIndex)} />}
                >
                  <InputNumber value={unit.duration} maxFractionDigits={2} onChange={e => form.setFieldValue(`units[${unitIndex}].duration`, e.value)} />
                </Field>
              </div>
            );
          })}
          <RingilButton label={'Save'} onClick={() => form.validateAndSend()} />
        </div>
      </div>
    </>
  );
};

export default ModuleSettingsTimeslot;
