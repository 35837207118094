import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { CarrierGroupDetailOutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import { useTranslation } from 'react-i18next';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import InputWrapper from '@components/obsolete/Form/InputWrapper/InputWrapper.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  carrierGroupId?: string;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const CreateOrUpdateCarrierGroup: React.FC<ComponentProps> = ({ carrierGroupId, visible, onHide, onSuccess }) => {
  const { t } = useTranslation('common');
  const { cuCreateCarrierGroup, cuDetailCarrierGroup } = useContext(ApiContext);
  const [carrierGroup, setCarrierGroup] = useState<CarrierGroupDetailOutDTO>(undefined);

  useEffect(() => {
    if (visible) {
      if (carrierGroupId) {
        cuDetailCarrierGroup(carrierGroupId, data => {
          setCarrierGroup(data);
        });
      } else {
        setCarrierGroup({ carrierGroupName: null, carrierGroupId });
      }
    }
  }, [visible]);

  // FIXME: edit TBD @JakubElias
  const saveOrUpdateCarrierGroup = () => {
    cuCreateCarrierGroup(
      {
        carrierGroupName: carrierGroup.carrierGroupName,
      },
      () => {
        onSuccess();
      },
    );
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal  sidebar-modal-add-new-carrier"
        style={{ width: '750px' }}
      >
        <h3 className="p-mb-4">{t(`CreateOrUpdateCarrierGroup.createANewCarrierGroup`, `Create a new carrier group`)}</h3>
        {carrierGroup && (
          <>
            <InputLayout>
              <InputWrapper
                label={t(`CreateOrUpdateCarrierGroup.carrierGroupName`, `Carrier group name`)}
                type="text"
                classNameWrapper="p-col-12 p-md-6"
                value={carrierGroup.carrierGroupName}
                onChange={e =>
                  setCarrierGroup({
                    carrierGroupName: (e.target as HTMLInputElement).value,
                    carrierGroupId,
                  })
                }
                error="Hodnota musí být zadána"
                required={true}
              />
            </InputLayout>
          </>
        )}

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`CreateOrUpdateCarrierGroup.create`, `Create`)} className="p-mr-2" onClick={e => saveOrUpdateCarrierGroup()} />
          <Button label={t(`CreateOrUpdateCarrierGroup.cancel`, `Cancel`)} className="p-button-text" onClick={onHide} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default CreateOrUpdateCarrierGroup;
