import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import * as yup from 'yup';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import { CameraTypeEnum, CompanyModuleEnum } from '@api/logsteo-api.v2.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import InputCompanyLocation from '@components/obsolete/Form/InputEntityOwner/InputCompanyLocation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import InputSelectCamType from '@components/framework/input/InputSelectCamType/InputSelectCamType.tsx';
import { findValidationMessage } from '@utils/validation.tsx';

interface ComponentProps {
  onSaved: () => void;
}

interface Form {
  companyLocationId: string;
  externalId: string;
  cameraType: CameraTypeEnum;
  name: string;
}

export const useCreateCameraSidebar = () => {
  const [visible, setVisible] = useState(false);

  const AddCameraSidebar: React.FC<ComponentProps> = ({ onSaved }) => {
    const { saveUpdateCamera } = useContext(ApiContext);
    const { tr } = useTranslationLgs();
    const { setBeValidationErrors, BackendValidationComponent } = useBEValidation();
    const { loggedUser } = useContext(AuthenticatedUserContext);

    const form = useForm<Form>(
      yup.object().shape({
        companyLocationId: yup.string().required().min(1),
        externalId: yup.string().required().min(1),
        cameraType: yup.string().required().min(1),
        name: yup.string().required().min(1),
      }),
      {
        companyLocationId: null,
        externalId: null,
        cameraType: CameraTypeEnum.ENTRY,
        name: null,
      },
      d => save(d),
      false,
      false,
    );

    const save = (data: Form) => {
      saveUpdateCamera(
        data,
        () => {
          onSaved();
        },
        null,
        {
          onValidationFailed: setBeValidationErrors,
        },
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          onContinue={() => {
            form.validateAndSend();
          }}
          onBack={() => {
            setVisible(false);
          }}
          heading={tr('AddCarPlateSidebar.pridatSpz', 'Přidat SPZ')}
        >
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <Field
              label={tr('AddCameraSidebar.lokalita', 'Lokalita')}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, form.names.companyLocationId, tr)}
            >
              <InputCompanyLocation
                companyLocationId={form.values.companyLocationId}
                companyId={loggedUser.companyId}
                onChange={v => form.setFieldValue(form.names.companyLocationId, v?.id)}
                moduleName={CompanyModuleEnum.TIMESLOTS}
              />
            </Field>
            <Field
              label={tr('AddCameraSidebar.externiIdKamery', 'Externí ID kamery')}
              required
              errorMessage={findValidationMessage(form.validationErrors, form.names.externalId, tr)}
            >
              <RingilInputText value={form.values.externalId} onChange={v => form.setFieldValue(form.names.externalId, v)} />
            </Field>
            <Field label={tr('AddCameraSidebar.typ', 'Typ')} errorMessage={findValidationMessage(form.validationErrors, form.names.cameraType, tr)}>
              <InputSelectCamType value={form.values.cameraType} onChange={v => form.setFieldValue(form.names.cameraType, v)} />
            </Field>
            <Field label={tr('AddCameraSidebar.pojmenovani', 'Pojmenování')} required errorMessage={findValidationMessage(form.validationErrors, form.names.name, tr)}>
              <RingilInputText value={form.values.name} onChange={v => form.setFieldValue(form.names.name, v)} />
            </Field>
          </div>
        </RingilSidebar>
      </>
    );
  };

  return { AddCameraSidebar, visible, setVisible };
};
