import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useContext, useEffect, useState } from 'react';
import {
  EntityTypeEnum,
  MessageTypeFilterEnum,
  NotificationPreviewListOutDTO,
  PageResponseNotificationPreviewListOutDTO,
  SupplierNotificationTypeEnum,
  TypeEnum,
} from '@api/logsteo-api.v2';
import { dateFormatDateTime, dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { Button } from 'primereact/button';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { SelectButton } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import ExpeditionLocation from '@components/obsolete/ExpeditionLocation/ExpeditionLocation.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { proxiedPropertiesOf } from '@hooks/useForm/useForm.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import InputNotificationType from '@components/obsolete/Form/InputNotificationType/InputNotificationType.tsx';
import useNavigation from '@hooks/useNavigation.tsx';

interface Filter {
  messageTypeFilter: MessageTypeFilterEnum;
  notificationType?: string;
}

const AllNotificationsList = () => {
  const { t } = useTranslation('common');
  const { cuListNotifications, cuReadNotification, cuMarkAllNotificationAsRead } = useContext(ApiContext);
  const [pageData, setPageData] = useState<PageResponseNotificationPreviewListOutDTO>();

  const nav = useNavigation();

  const { page, onPage, applyPaging, filter, Filter, applyFilterValue } = useFilter<Filter>((page, filter) => {
    loadData(page.pageSize, page.pageNumber, filter);
  });

  useEffect(() => {
    loadData(page.pageSize, page.pageNumber, filter);
  }, []);

  const maskAsReadAndRedirect = (notificationId: string, companyId: string, applicationId: string, entityId: string, entityType: EntityTypeEnum) => {
    cuReadNotification(notificationId, companyId, applicationId, () => {
      if (entityType == EntityTypeEnum.SUPPLIER_NOTIFICATION) {
        nav.navigate(nav.urlFunctions.createSupplierNotificationDetail(entityId, SupplierNotificationTypeEnum.FCA));
      } else if (entityType == EntityTypeEnum.GROUP_DEMAND) {
        nav.navigate(nav.urlFunctions.createMultiDemanDetail(entityId));
      } else {
        nav.navigate(nav.urlFunctions.createCustomerExpeditionDetail(applicationId));
      }
    });
  };

  const loadData = (pageSize: number, pageNumber: number, filter: Filter) => {
    cuListNotifications(
      {
        messageTypeFilter: filter?.messageTypeFilter,
        notificationType: filter?.notificationType,
      },
      { pageSize, pageNumber: pageNumber },
      data => {
        setPageData(data);
      },
    );
  };

  const markAllAsRead = () => {
    cuMarkAllNotificationAsRead(() => {
      loadData(page.pageSize, page.pageNumber, filter);
    });
  };

  const notificationStatusBodyTemplate = (rowData: NotificationPreviewListOutDTO) => {
    return (
      <div className="notification-status">
        <div className="notification-status-item notification-status-item__type text-blue">
          <strong>{rowData.name}</strong>
        </div>
        <div className="notification-status-item notification-status-item__name">
          <strong>{rowData.createdBy}</strong>
        </div>
        <div className="notification-status-item notification-status-item__date-time text-gray5">
          <span>{dateFormatDateTime(mapFromAPIDateTime(rowData.createAt))}</span>
        </div>
      </div>
    );
  };

  const expeditionBodyTemplate = (rowData: NotificationPreviewListOutDTO) => {
    return (
      <div className="notification-expedition">
        <div className="notification-expedition-item">
          <span className="text-gray5">{t(`AllNotificationsList.expedition`, `Expedition`)}</span> <span className="text-blue">{rowData.applicationId}</span>
        </div>
        <div className="notification-expedition-item">
          <span className="text-gray5">
            {t(`AllNotificationsList.loadingFrom`, {
              date: dateFormatOnlyDateShort(mapFromAPIDateTime(rowData.toLoadAt)),
            })}
          </span>
        </div>
      </div>
    );
  };

  const routeBodyTemplate = (rowData: NotificationPreviewListOutDTO) => {
    return (
      <div className="locations">
        <ExpeditionLocation locationPoint={t(`AllNotificationsList.from`, `From`)} locationAddress={rowData.locationNames[0]} wrapper="LARGE" />
        <ExpeditionLocation
          locationPoint={t(`AllNotificationsList.to`, `To`)}
          locationAddress={rowData.locationNames[rowData.locationNames.length - 1]}
          wrapper="LARGE"
        />
        {rowData.locationNames.length > 2 && (
          <ExpeditionLocation
            locationPoint={t(`AllNotificationsList.through`, `through`)}
            through={t(`AllNotificationList.through`, {
              count: rowData.locationNames.length - 2,
            })}
            /*through={`+ ${rowData.locationNames.length - 2} stops`}*/
            wrapper="LARGE"
          />
        )}
      </div>
    );
  };

  const navBodyTemplate = (rowData: NotificationPreviewListOutDTO) => {
    return (
      <div>
        <ButtonLayout>
          <Button
            className="btn-detail p-button p-button-rounded p-button-outlined secondary-btn"
            onClick={() => maskAsReadAndRedirect(rowData.id, rowData.companyId, rowData.applicationId, rowData.entityId, rowData.entityType)}
          >
            <i className="icon pi pi-ellipsis-h"></i> <span className="btn-name">{t('allShipments.detail')}</span>
          </Button>
        </ButtonLayout>
        <div className="notification-status">
          <strong>{rowData.read ? t('AllNotificationsList.read', 'přečteno') : t('AllNotificationsList.unread', 'nepřečteno')}</strong>
        </div>
      </div>
    );
  };
  const { tr } = useTranslationLgs();
  const names = proxiedPropertiesOf<Filter>();

  const model = [
    {
      name: t('AllNotificationsList.UNREAD', 'Unread'),
      code: MessageTypeFilterEnum.UNREAD,
    },
    {
      name: t('AllNotificationsList.READ', 'Read'),
      code: MessageTypeFilterEnum.READ,
    },
    {
      name: t('AllNotificationsList.BOTH', 'Both'),
      code: MessageTypeFilterEnum.BOTH,
    },
  ];

  return (
    <>
      <NavTitle wrapper="PADDING-0">
        <h2 className="h2-title p-m-0">{t(`AllNotificationsList.notifications`, `Notifications`)}</h2>
        <Button label={tr(`AllNotificationsList.markAllAsRead`, `Mark all as read`)} onClick={() => markAllAsRead()} />
      </NavTitle>
      <Filter />
      {filter && (
        <div className="p-mt-3 p-mb-2">
          <div className="p-formgroup-inline">
            <div className="p-field">
              {/*<InputText
                value={filter?.notificationType}
                placeholder={tr(`AllNotificationsList.typNotifikace`, `Typ notifikace`)}
                onChange={(e) => applyFilterValue(names.notificationType, e.target.value)}
              />*/}
              <InputNotificationType value={filter?.notificationType} onChange={v => applyFilterValue(names.notificationType, v)} />
            </div>
            <div className="p-field">
              <SelectButton
                value={filter?.messageTypeFilter}
                options={model}
                optionValue={'code'}
                optionLabel={'name'}
                onChange={e => applyFilterValue(names.messageTypeFilter, e.value)}
              />
            </div>
          </div>
        </div>
      )}
      {pageData && pageData.data && (
        <>
          {/*// @ts-ignore*/}
          <DataTable
            emptyMessage={t(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            className="default-table notification-table"
            value={pageData.data}
            rows={pageData.size}
            totalRecords={pageData.total}
            onPage={onPage}
            first={(pageData.page - 1) * pageData.size}
            paginatorPosition={'both'}
            lazy={true}
            paginator={true}
          >
            <Column className="notification-table__notification-status" body={notificationStatusBodyTemplate}></Column>
            <Column className="notification-table__expedition" body={expeditionBodyTemplate}></Column>
            <Column className="notification-table__route" body={routeBodyTemplate}></Column>
            <Column className="notification-table__nav" body={navBodyTemplate}></Column>
          </DataTable>
        </>
      )}
    </>
  );
};
export default AllNotificationsList;
