import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { RowWithSpaceBetween } from '@components/styles.tsx';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge.tsx';
import COText from '@app/pages/COText/COText.tsx';
import RingilForEntitySidebar from '@components/ringil3/Sidebar/RingilForEntitySidebar.tsx';
import { CompanyModuleEnum, EntityTypeEnum, GlobalTimeslotsDTO, LovCompanyLocationWithOpeningHours, OpeningEntityTypeEnum } from '@api/logsteo-api.v2.tsx';

import * as yup from 'yup';
import { Field } from '@components/framework/formfield/Field.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import InputInheriterCompany from '@components/obsolete/Form/InputEntityOwner/InputInheriterCompany.tsx';
import InputCompanyLocation from '@components/obsolete/Form/InputEntityOwner/InputCompanyLocation.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';

interface ComponentProps {
  supplierNotificationId: string;
  applicationId: string;
  onSaved: () => void;
  companyId: string;
}

interface Form {
  supplierId: string;
  supplierLocationId: string;
  loadingGlobalTimeslots: GlobalTimeslotsDTO;
  supplierLocation?: LovCompanyLocationWithOpeningHours;
}

export const useCreateEditDAPSupplierSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditDAPSupplierSidebar: React.FC<ComponentProps> = ({ supplierNotificationId, applicationId, companyId, onSaved }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [apiRequest, setApiRequest] = useState(false);
    const { detailDAPSupplierNotification, updateSupplierLocationDAPSupplierNotification } = useContext(ApiContext);

    const form = useForm<Form>(
      yup.object().shape({}),
      { supplierLocation: null, supplierLocationId: null, supplierId: null, loadingGlobalTimeslots: null },
      d => save(d),
      false,
      false,
    );

    const save = (values: Form) => {
      updateSupplierLocationDAPSupplierNotification(
        supplierNotificationId,
        {
          companyId: form.values.supplierId,
          locationId: form.values.supplierLocationId,
          timeslots: form.values.loadingGlobalTimeslots,
        },
        () => {
          setVisible(false);
          onSaved();
        },
      );
    };

    useEffect(() => {
      const ac = new AbortController();

      detailDAPSupplierNotification(
        supplierNotificationId,
        d => {
          if (ac.signal.aborted) return;
          form.overwriteValues({
            supplierLocation: null,
            supplierLocationId: d.supplierLocation.locationId,
            supplierId: d.supplierCompany.id,
            loadingGlobalTimeslots: d.supplierDeliverySlots,
          });
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
      return () => {
        ac.abort();
      };
    }, [supplierNotificationId]);

    return (
      <>
        <RingilForEntitySidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
            <RowWithSpaceBetween>
              <COText label={tr('EditDAPSupplierSidebar.nakladka', 'Nakládka')} />
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map(t => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}
          entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
          applicationId={applicationId}
        >
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <Field
              label={tr('EditDAPSupplierSidebar.odesilatel', 'Odesílatel')}
              errorMessage={findValidationMessage(form.validationErrors, 'supplierId', tr)}
              readOnly={true}
            >
              <InputInheriterCompany
                readonly={true}
                companyId={companyId}
                inheriterId={form.values.supplierId}
                onChange={v => form.setFieldValue('supplierId', v)}
                module={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
              />
            </Field>
            <Field label={tr('EditDAPSupplierSidebar.nazevLokace', 'Název lokace')} errorMessage={findValidationMessage(form.validationErrors, 'supplierLocationId', tr)}>
              <InputCompanyLocation
                moduleName={CompanyModuleEnum.SUPPLIER_NOTIFICATION}
                companyId={form.values.supplierId}
                companyLocationId={form.values.supplierLocationId}
                onChange={v => {
                  form.setFieldValue(form.names.supplierLocationId, v?.id);
                  form.setFieldValue(form.names.supplierLocation, v);
                }}
                style={{ width: '100%' }}
              />
            </Field>
            <Field
              label={tr('EditDAPSupplierSidebar.datumVyzvednuti', 'Datum vyzvednutí')}
              errorMessage={findValidationMessage(form.validationErrors, 'loadingGlobalTimeslots', tr)}
            >
              <ManageTimeslot
                externalLabel
                entityType={OpeningEntityTypeEnum.LOCATION}
                entityId={form.values.supplierLocationId}
                onChange={v => {
                  form.setFieldValue(form.names.loadingGlobalTimeslots, v);
                }}
                timeslot={form.values.loadingGlobalTimeslots}
                noOpeningHours={false}
                validationErrors={form.validationErrors}
                validationPrefix={'loadingGlobalTimeslots.'}
              />
            </Field>
          </div>
        </RingilForEntitySidebar>
      </>
    );
  };

  return { EditDAPSupplierSidebar, visible, setVisible };
};
