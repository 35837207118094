import React from 'react';
import { SubMenuItemModel } from './types.ts';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils.tsx';

const SubMenuItem: React.FC<SubMenuItemModel> = ({ title, onClick, active = false, url }) => {
  const checkIsActive = (url: string) => {
    const pathWithoutParams = url?.indexOf('?') > -1 ? url.split('?')[0] : url;

    if (isNotNullOrUndefined(url)) {
      try {
        const isActive = pathWithoutParams === location.pathname;
        return isActive;
      } catch (e) {
        // console.log("error", e);
      }
    }
    return false;
  };

  const isActive = checkIsActive(url);

  return (
    <>
      <Link
        to={url}
        className={twMerge(
          clsx('flex gap-4 items-center text-content-primary', {
            'text-content-brand': isActive,
          }),
        )}
      >
        <li className="pl-[60px] flex p-4  justify-between h-[48px] hover:bg-base-base items-center w-[270px]" onClick={onClick !== undefined ? onClick : () => {}}>
          <div className={twMerge(clsx('flex-col flex', { 'text-content-brand': active }))}>
            <span>{title}</span>
          </div>
        </li>
      </Link>
    </>
  );
};

export default SubMenuItem;
