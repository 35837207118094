import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { SupplierNotificationPreviewOutDTO, SupplierNotificationTypeEnum, TypeEnum } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined } from '@utils/utils';
import { ClickableCursor } from '@components/ringil3/styles';
import useNavigation from '@hooks/useNavigation';

interface ComponentProps {
  supplierNotificationLink?: SupplierNotificationPreviewOutDTO;
}

const LinkedSNBadge: React.FC<ComponentProps> = ({ supplierNotificationLink }) => {
  const { tr } = useTranslationLgs();
  const nav = useNavigation();

  return (
    <>
      {isNotNullOrUndefined(supplierNotificationLink) && (
        <ClickableCursor
          onClick={e => {
            nav.navigate(nav.urlFunctions.createSupplierNotificationDetail(supplierNotificationLink.supplierNotificationId, SupplierNotificationTypeEnum.FCA));
          }}
        >
          <Badge
            filled={true}
            value={supplierNotificationLink.supplierNotificationApplicationId}
            label={tr(`LinkedCNBadge.avizace`, `Avizace`)}
            highlightedValue={true}
          ></Badge>
        </ClickableCursor>
      )}
    </>
  );
};

export default LinkedSNBadge;
