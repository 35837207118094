import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { MultiSelect } from 'primereact/multiselect';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '@api/api.tsx';
import useValidation from '@hooks/validation-hook/useValidation.tsx';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import DropdownLayout from '@components/obsolete/Form/DropdownLayout/DropdownLayout.tsx';
import { carriersTemplate } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { LovDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  carrierGroupId: string;
  onComplete: () => void;
}

const AddCarrierToGroup: React.FC<ComponentProps> = ({ visible, onHide, carrierGroupId, onComplete }) => {
  const { t } = useTranslation('common');
  const { cuAddCarrierToGroup, cuLoVCarrierForGroup } = useContext(ApiContext);
  const [availableCarriers, setAvailableCarriers] = useState<LovDTO[]>(undefined);
  const [selectedCarrier, setSelectedCarrier] = useState<LovDTO[]>(undefined);

  useEffect(() => {
    if (visible) {
      cuLoVCarrierForGroup(carrierGroupId, data => {
        setAvailableCarriers(data.carriers);
      });
      setSelectedCarrier([]);
      clear();
    }
  }, [visible]);

  const addToGroup = () => {
    cuAddCarrierToGroup(carrierGroupId, { carrierIds: selectedCarrier.map(c => c.id) }, () => {
      onComplete();
    });
  };

  const validationSchema = yup.array().min(1);
  const { clear, validationErrors, validateAndSend } = useValidation(validationSchema, addToGroup);

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal  sidebar-modal-add-new-carrier"
        style={{ width: '750px' }}
      >
        <h3 className="p-mb-4">{t(`AddCarrierToGroup.addCarriersToThisGroup`, `Add carriers to this group`)}</h3>

        {availableCarriers && (
          <InputLayout>
            <DropdownLayout classNameWrapper="p-col-12 p-md-8">
              <Label title={t(`AddCarrierToGroup.carrierName`, `Carrier name`)} required={true} />
              <MultiSelect
                value={selectedCarrier}
                options={availableCarriers}
                onChange={e => setSelectedCarrier(e.value)}
                optionLabel="name"
                filter
                className="multiselect-custom"
                style={{ width: '100%' }}
                itemTemplate={carriersTemplate}
              />
              <ValidationDiv errors={validationErrors} path={''} />
            </DropdownLayout>
          </InputLayout>
        )}

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button label={t(`AddCarrierToGroup.add`, `Add`)} className="p-mr-2" onClick={() => validateAndSend(selectedCarrier)} />
          <Button label={t(`AddCarrierToGroup.cancel`, `Cancel`)} onClick={() => onHide()} className="p-button-text" />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default AddCarrierToGroup;
