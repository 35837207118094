import React, { useContext, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import { LovDTO } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import useDispatchers from '@hooks/dispatchers/dispatchers.tsx';
import useDrivers from '@hooks/drivers/drivers.tsx';
import InputPlate from '@components/obsolete/Form/InputPlate/InputPlate.tsx';
import { Label } from '@components/styles.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  onComplete: () => void;
  data: ComponentData;
  expeditionId: string;
  showDriver: boolean;
  showDispatcher: boolean;
}

export interface ComponentData {
  carPlate: string;
  dispatcherId: string;
  driverId: string;
}

export const useCreateChangeVehicleInfo = () => {
  const [visible, setVisible] = useState(false);

  const ChangeVehicleInfo: React.FC<ComponentProps> = ({ expeditionId, data, onComplete, showDispatcher, showDriver }) => {
    const { t } = useTranslation('common');
    const dispatchers = useDispatchers(expeditionId);
    const drivers = useDrivers(expeditionId);
    const { expEditCarInfo } = useContext(ApiContext);

    const validationSchema = yup.object().shape({
      carPlate: yup.string().required(),
      dispatcherId: showDispatcher ? yup.string().required() : yup.string().nullable(),
      driverId: showDriver ? yup.string().required() : yup.string().nullable(),
    });

    const form = useForm<ComponentData>(
      validationSchema,
      data,
      d => {
        save(d);
      },
      false,
      false,
    );

    const save = (d: ComponentData) => {
      expEditCarInfo(expeditionId, d, () => {
        setVisible(false);
        onComplete();
      });
    };

    const names = proxiedPropertiesOf<ComponentData>();

    return (
      <>
        {/*//
      @ts-ignore*/}
        <Sidebar
          visible={visible}
          position="right"
          baseZIndex={1000000}
          onHide={() => setVisible(false)}
          className="sidebar-modal sidebar-modal-md"
          style={{ width: '750px' }}
        >
          <h3 className="p-mb-5" style={{ fontWeight: 'normal' }}>
            {t(`ChangeVehicleInfo.assignedCar`, `Assigned car`)}
          </h3>
          <div className="group-wrapper group-assign-vehicle">
            <div className="p-field">
              <InputPlate
                label={t('ChangeVehicleInfo.plates', 'Plates')}
                value={form.values.carPlate}
                disabled={false}
                onChange={v => {
                  form.setFieldValue(names.carPlate, v);
                }}
              />
              <ValidationDiv errors={form.validationErrors} path={'carPlate'} />
            </div>
            {showDriver && (
              <div className="p-field">
                <Label title={t('ChangeVehicleInfo.driver', 'Driver')} />
                <Dropdown
                  value={form.values.driverId}
                  options={drivers}
                  dataKey={'id'}
                  onChange={e => form.setFieldValue(names.driverId, e.value)}
                  optionLabel="name"
                  optionValue="id"
                  filter={true}
                  itemTemplate={(option: LovDTO) => (
                    <>
                      {option.active ? (
                        <span>{option.name}</span>
                      ) : (
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'gray',
                          }}
                        >
                          {option.name}
                        </span>
                      )}
                    </>
                  )}
                  placeholder={t('ChangeVehicleInfo.vyberte', 'Vyberte')}
                />
                <ValidationDiv errors={form.validationErrors} path={names.driverId} />
              </div>
            )}

            {showDispatcher && (
              <div className="p-field">
                <Label title={t('ChangeVehicleInfo.dispatcher', 'Dispatcher')} />
                <Dropdown
                  value={form.values.dispatcherId}
                  options={dispatchers}
                  dataKey={'id'}
                  onChange={e => form.setFieldValue(names.dispatcherId, e.value)}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t('ChangeVehicleInfo.choose', 'Vyberte')}
                />
                <ValidationDiv errors={form.validationErrors} path={names.dispatcherId} />
              </div>
            )}
          </div>
          <ButtonLayout>
            <Button label={t('wayPage.form.buttonContinue')} className="p-mr-2" onClick={() => form.validateAndSend()} />
            <Button label={t('wayPage.form.buttonBack')} className="p-button-text" onClick={() => setVisible(false)} />
          </ButtonLayout>
        </Sidebar>
      </>
    );
  };

  return { ChangeVehicleInfo, setVisible };
};
