import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tag/ButtonTag.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { findValidationMessage, hasValidationProblemForPrefix } from '@utils/validation.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { isNotBlank, isNotNullOrUndefined } from '@utils/utils.tsx';
import SvgCoins from '@icons/Coins.tsx';
import { InputText } from 'primereact/inputtext';
import SvgNumber from '@icons/Number.tsx';

export interface CustomsLoadingNumberData {
  loadingNumber?: string;
}

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

export const CustomsLoadingNumberValidationSchema = () => {
  return yup.object().nullable().shape({});
};

const CustomsLoadingNumberTag: React.FC<ComponentProps> = ({ value, onChange, readonly, validationPrefix, validationErrors }) => {
  const { tr } = useTranslationLgs();

  const form = useForm<CustomsLoadingNumberData>(
    CustomsLoadingNumberValidationSchema(),
    isNotNullOrUndefined(value) ? { loadingNumber: value } : null,
    (d, onSuccess) => {
      if (isNotNullOrUndefined(onSuccess)) {
        onSuccess();
      }
      onChange(isNotBlank(d?.loadingNumber) ? d.loadingNumber : null);
    },
    false,
    false,
  );

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  return (
    <>
      <ButtonTag
        label={'Číslo příjmu'}
        icon={<SvgNumber />}
        value={value}
        readonly={readonly}
        popupWidth={'300px'}
        errorMessage={hasError ? tr(`CustomsLoadingNumbersTag.validationError`, `Validation error`) : undefined}
        onSave={onSuccess => {
          form.validateAndSendWithSuccess(onSuccess);
        }}
        onClose={() => {
          form.clear();
        }}
      >
        <>
          <Field label={'Číslo příjmu (číslo celního případu)'} errorMessage={findValidationMessage(validationErrors, 'status', tr)}>
            <InputText value={form.values?.loadingNumber} onChange={e => form.setFieldValue(form.names.loadingNumber, e.target.value)} />
          </Field>
        </>
      </ButtonTag>
    </>
  );
};

export default CustomsLoadingNumberTag;
