import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dateFormatDateTime, mapFromAPIDateTime, mapFromAPIToDateLong } from '@utils/date';
import { Button } from 'primereact/button';
import UploadAttachmentDialog, { AttachmentDocument } from './UploadAttachmentDialog';
import { Checkbox } from 'primereact/checkbox';
import { isNotNullOrUndefined } from '@utils/utils';
import ShareDocumentDialog from './ShareDocumentDialog/ShareDocumentDialog';
import CopyDocumentsDialog from './CopyDocumentsDialog/CopyDocumentsDialog';
import { AccessTypeEnum, AttachmentUploadedOutDTO, EntityTypeEnum } from '@api/logsteo-api.v2';
import useMassAction from '@hooks/useMassAction/useMassAction';
import { Clickable, Note, RingilH3 } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import Badge, { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import PDFViewerDialog from '@components/obsolete/PDFViewer/PDFViewerDialog.tsx';
import Labels from '@components/obsolete/Labels/Labels.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import TabularDeleteButton from '@components/ringil3/Buttons/TabularDeleteButton.tsx';

interface ComponentProps {
  onAddAttachment?: (attachments: AttachmentDocument[]) => void;
  onDeletedAttachment?: (attachmentId: string) => void;
  entityType: EntityTypeEnum;
  entityId: string;
  onChangeVisibility?: (attachmentId: string, internal: boolean) => void;
  disabled?: boolean;
}

export const useCreateAttachmentComponent = (
  enableCopyDocument: boolean,
  initialAttachments?: AttachmentUploadedOutDTO[],
  disabled: boolean = false,
  doNotShare: boolean = false,
) => {
  const { listDocumentsForEntity, downloadAttachment, genericUploadV2, deleteDocumentForEntity, changeDocumentVisibility } = useContext(ApiContext);
  const [attachments, setAttachments] = useState<AttachmentUploadedOutDTO[]>(initialAttachments);
  const [visibleShareDialog, setVisibleShareDialog] = useState(false);
  const [visibleUploadDialog, setVisibleUploadDialog] = useState(false);
  const [visibleCopyDocuments, setVisibleCopyDocuments] = useState(false);

  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    if (isNotNullOrUndefined(initialAttachments)) setAttachments(initialAttachments);
  }, [initialAttachments]);

  const load = (entityType: EntityTypeEnum, entityId: string) => {
    listDocumentsForEntity(entityType, entityId, d => {
      setAttachments(d);
    });
  };

  const downloadFile = (attachmentId: string) => {
    downloadAttachment(attachmentId, data => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = data.fileName;
      downloadLink.click();
    });
  };

  const [previewFileDataURL, setPreviewFileDataURL] = useState<string>();
  const viewDocument = (attachmentId: string) => {
    downloadAttachment(attachmentId, data => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      setPreviewFileDataURL(linkSource);
    });
  };

  const { selectedObjects, MassActionCheckboxAndState, setSelectedObjects, toggleCheckbox, isSelected } = useMassAction<AttachmentUploadedOutDTO>(
    (a1, a2) => a1.attachmentId === a2.attachmentId,
    selectedObjects => {
      return (
        <>
          <Button
            label={tr(`AttachementComponent.shareWithEmail`, `Share with email`)}
            disabled={selectedObjects.length === 0 || disabled || doNotShare}
            onClick={e => setVisibleShareDialog(true)}
          />
          {enableCopyDocument && (
            <Button
              label={tr(`AttachementComponent.copyAttachments`, `Copy attachments`)}
              disabled={selectedObjects.length === 0 || disabled}
              onClick={e => setVisibleCopyDocuments(true)}
            />
          )}
        </>
      );
    },
    () => [],
  );

  const AttachmentComponent: React.FC<ComponentProps> = ({ onAddAttachment, onDeletedAttachment, entityId, entityType, onChangeVisibility }) => {
    const { tr } = useTranslationLgs();

    /*
  Default handlers

   */
    const addAttachment = (attachments: AttachmentDocument[]) => {
      genericUploadV2(
        {
          entityType: entityType,
          entityIds: [entityId],
          attachments: attachments.map(t => {
            return {
              attachmentId: t.attachmentId,
              accessType: t.accessType,
              documentType: t.documentType?.documentType,
              userDate: t.userDate,
              userNote: t.userNote,
            };
          }),
          companyOwnerId: null,
        },
        () => {
          load(entityType, entityId);
        },
      );
    };

    const onDeleteAttachmentHandler = (attachmentIdToDelete: string) => {
      if (onDeletedAttachment != null) {
        onDeletedAttachment(attachmentIdToDelete);
      } else {
        deleteDocumentForEntity(entityType, entityId, attachmentIdToDelete, () => {
          load(entityType, entityId);
        });
      }
    };

    const onChangeVisibilityHandler = (attachmentId: string, internal: boolean) => {
      if (onChangeVisibility != null) {
        onChangeVisibility(attachmentId, internal);
      } else {
        changeDocumentVisibility(
          attachmentId,
          {
            accessType: internal ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL,
          },
          () => {
            load(entityType, entityId);
          },
        );
      }
    };

    return (
      <>
        <RingilH3>{tr(`AttachmentComponent.documents`, `Documents`)}</RingilH3>
        <MassActionCheckboxAndState
          onSelectAll={() => {
            setSelectedObjects(attachments);
          }}
          disabled={disabled}
        />
        {/*
        // @ts-ignore*/}
        <DataTable value={attachments}>
          <Column
            header={''}
            body={(row: AttachmentUploadedOutDTO) => (
              <Checkbox
                onChange={e => {
                  toggleCheckbox(row);
                }}
                checked={isSelected(row)}
                disabled={disabled}
              />
            )}
            style={{ width: 30 }}
          ></Column>
          <Column
            header={tr(`AttachmentComponent.documentName`, `Document name`)}
            body={(row: AttachmentUploadedOutDTO) => (
              <>
                <BadgeRow>
                  <Clickable
                    onClick={e => {
                      downloadFile(row.attachmentId);
                    }}
                  >
                    <div>{row.fileName}</div>
                  </Clickable>
                  {row.accessType === AccessTypeEnum.INTERNAL && <Badge text={tr(`AttachementComponent.internal`, `Internal`)} icon={<></>} />}
                  {row.fileName.endsWith('.pdf') && (
                    <Clickable>
                      <span className={'pi pi-eye'} onClick={e => viewDocument(row.attachmentId)}></span>
                    </Clickable>
                  )}
                </BadgeRow>
                <BadgeRow>
                  <div className={'p-mt-4'}>
                    <Labels objectType={EntityTypeEnum.ATTACHMENTS} objectId={row.attachmentId} disabled={disabled} />
                  </div>
                </BadgeRow>
              </>
            )}
          ></Column>
          <Column
            header={tr(`AttachmentComponent.uploadedBy`, `Uploaded by`)}
            body={(row: AttachmentUploadedOutDTO) => (
              <div>
                <div>{row.userName}</div>
                <Note>{row.companyName}</Note>
              </div>
            )}
          ></Column>
          <Column
            header={tr(`AttachmentComponent.userDate1`, `Date`)}
            body={(row: AttachmentUploadedOutDTO) => (
              <div>
                <div>{mapFromAPIToDateLong(row.userDate)}</div>
                <Note>{row.userNote}</Note>
              </div>
            )}
          ></Column>
          <Column header={tr(`AttachmentComponent.documentType`, `Document type`)} body={(row: AttachmentUploadedOutDTO) => <div>{row.documentTypeName}</div>}></Column>
          <Column
            header={tr(`AttachmentComponent.uploadedAt`, `Uploaded at`)}
            body={(row: AttachmentUploadedOutDTO) => <div>{dateFormatDateTime(mapFromAPIDateTime(row.uploadedAt))}</div>}
          ></Column>
          <Column
            header={'Internal'}
            body={(row: AttachmentUploadedOutDTO, props) => (
              <div>
                {row.companyId === loggedUser.companyId && (
                  <InputYesNoPlain
                    value={row.accessType === AccessTypeEnum.INTERNAL}
                    onChange={v => {
                      onChangeVisibilityHandler(row.attachmentId, v);
                    }}
                    disabled={disabled}
                  />
                )}
              </div>
            )}
          ></Column>
          <Column
            body={(row: AttachmentUploadedOutDTO) => (
              <div>
                {/* <Button
                  className=" p-button-outlined"
                  icon="pi pi-trash"
                  disabled={disabled}
                  onClick={() => {
                    onDeleteAttachmentHandler(row.attachmentId);
                  }}
                />*/}
                <TabularDeleteButton onClick={() => onDeleteAttachmentHandler(row.attachmentId)} disabled={disabled} />
              </div>
            )}
          ></Column>
        </DataTable>

        <AttachmentButtonWrapper>
          <Button
            label={tr('AttachementComponent.addAttachmentWithPlus', '+ Add attachment')}
            onClick={e => setVisibleUploadDialog(true)}
            className={'p-button-outlined'}
            disabled={disabled}
          />
        </AttachmentButtonWrapper>
        {visibleUploadDialog && (
          <UploadAttachmentDialog
            visible={visibleUploadDialog}
            onHide={() => setVisibleUploadDialog(false)}
            onCompleted={v => {
              if (onAddAttachment == null) {
                addAttachment(
                  v.map(t => {
                    return {
                      ...t,
                      documentType: {
                        documentType: t.documentType,
                        name: t.documentTypeName,
                      },
                    } as AttachmentDocument;
                  }),
                );
              } else {
                onAddAttachment(
                  v.map(t => {
                    return {
                      ...t,
                      documentType: {
                        documentType: t.documentType,
                        name: t.documentTypeName,
                      },
                    } as AttachmentDocument;
                  }),
                );
              }

              setVisibleUploadDialog(false);
            }}
          />
        )}

        <ShareDocumentDialog
          visible={visibleShareDialog}
          onHide={() => setVisibleShareDialog(false)}
          documents={selectedObjects}
          entityId={entityId}
          entityType={entityType}
        />

        <CopyDocumentsDialog
          visible={visibleCopyDocuments}
          onHide={() => setVisibleCopyDocuments(false)}
          documents={selectedObjects}
          entityId={entityId}
          entityType={entityType}
        />
        <PDFViewerDialog pdfData={previewFileDataURL} onHide={() => setPreviewFileDataURL(null)} />
      </>
    );
  };

  return { AttachmentComponent, load };
};

const AttachmentButtonWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`;
