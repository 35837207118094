import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { TabPanel, TabView } from 'primereact/tabview';
import { ExpeditionDetailTabPlanOutDTO, ExpeditionStatusEnum, LastExpeditionStatusEnum, LocationPreviewOutDTO } from '@api/logsteo-api.v2';

import { isExpeditionActiveByLast } from '@utils/handlers';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { Button } from 'primereact/button';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { useTranslation } from 'react-i18next';
import ExpeditionDetailHandlerProvider, { ExpeditionDetailHandlerContext } from '@app/pages/carrier/expedition-detail/ExpeditionDetailHandler.tsx';
import ExpeditionLocation from '@components/obsolete/ExpeditionLocation/ExpeditionLocation.tsx';
import AlertInfo from '@components/obsolete/Alert/AlertInfo.tsx';
import Panel from '@components/obsolete/Panel/Panel.tsx';
import CarrierExpeditionDetailHeader from '@components/obsolete/ExpeditionDetailHeader/CarrierExpeditionDetailHeader.tsx';
import { formatTimeslotInterval } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import { ExpeditionCostWrapper } from '@components/ExpeditionCost/ExpeditionCostWrapper.tsx';
import { ExpeditionCostItem } from '@components/ExpeditionCost/ExpeditionCostItem.tsx';
import TabShipmentAssigned from '@app/pages/carrier/expedition-detail/TabShipmentAssigned/TabShipmentAssigned.tsx';
import TabShipmentActive2Finish from '@app/pages/carrier/expedition-detail/TabShipmentActive2Finish/TabShipmentActive2Finish.tsx';
import TabExpeditionRoute from '@app/pages/carrier/expedition-detail/TabExpeditionRoute/TabExpeditionRoute.tsx';
import TabDocuments from '@app/pages/carrier/expedition-detail/TabDocuments/TabDocuments.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import { useParams } from 'react-router-dom';
import useNavigation from '@hooks/useNavigation.tsx';

interface ComponentProps {}

const ExpeditionDetailPageInner: React.FC<ComponentProps> = () => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { state, finishShipment } = useContext(ExpeditionDetailHandlerContext);
  const nav = useNavigation();

  const disabledInputs = state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED || state.header.isManualExpedition;

  const renderThrought = (locations: LocationPreviewOutDTO[]) => {
    const stops: LocationPreviewOutDTO[] = locations.filter((loc, locIndex, locs) => locIndex > 0 && locIndex < locs.length - 1);

    return (
      <ExpeditionLocation
        locationPoint={t('customerExpeditionDetail.labelThrough', 'Přes')}
        locationAddress={stops.length == 1 ? locations[1].name : t('customerExpeditionDetail.stops', { count: stops.length })}
        locationDisabled={false}
        render={stops.length > 0}
      />
    );
  };

  const carrierShipmentBreadCrumb = () => {
    return [
      {
        label: t('labelAllShipments'),
        url: /*"/carrier/shipments"*/ nav.createNavigationLink(nav.urlFunctions.createCarrierShipmentsList()),
      },
      {
        label: t('labelShipmentDetail'),
        disabled: true,
      },
    ];
  };

  const tabViewRef = useRef();

  const { BackendValidationComponent } = useBEValidation();

  return (
    <>
      {state && (
        <>
          <div className="p-grid p-jc-between p-ai-center p-mb-5">
            <div className="p-col-12 p-md-8">
              <BreadCrumbComponent items={carrierShipmentBreadCrumb()} />
            </div>
            {state.header.expeditionStatus !== ExpeditionStatusEnum.FINISHED && state.header.expeditionStatus !== ExpeditionStatusEnum.CANCELED && (
              <Button label={tr(`ExpeditionDetailPage.finish`, `Finish`)} disabled={disabledInputs} onClick={e => finishShipment()} />
            )}
          </div>
          <BackendValidationComponent />
          {state.header.expeditionStatus === ExpeditionStatusEnum.ASSIGNED && !disabledInputs && (
            <AlertInfo backgroundWrapper="orange2">
              <p>
                {t(
                  `ExpeditionDetailPage.selectAllTimeslotsInShipmentProgressSectionInOrderToInformTheCustomer`,
                  `Select all timeslots in Shipment progress section in order to inform the customer.`,
                )}
              </p>
            </AlertInfo>
          )}
          {/*//
      @ts-ignore*/}
          <Card className="offset-card">
            <Panel className="offset">
              <CarrierExpeditionDetailHeader
                applicationId={state.header.applicationId}
                distance={state.header.distance}
                expeditionType={state.header.expeditionType}
                expeditionState={state.header.expeditionStatus}
                isExpeditionCanceled={state.header.expeditionStatus === ExpeditionStatusEnum.CANCELED}
                isManualExpedition={state.header.isManualExpedition}
                expeditionToId={state.header.expeditionToId}
                expeditionFromId={state.header.expeditionFromId}
                handlingUnitCount={state.header.handlingUnitCount}
              />
              <div className="locations-expedition-main">
                <div className="p-grid p-mt-3 p-mb-3 locations">
                  <ExpeditionLocation
                    locationPoint={t('labelFrom')}
                    locationAddress={state.header.locations[0].name}
                    /*                  locationDate={beginAndEndDates(state.header.locations[0].timeslots)}*/
                    locationDate={formatTimeslotInterval(state.header.locations[0].timeslots)}
                    locationDisabled={false}
                  />
                  {renderThrought(state.header.locations)}
                  <ExpeditionLocation
                    locationPoint={t('labelTo')}
                    locationAddress={state.header.locations[state.header.locations.length - 1].name}
                    /* locationDate={beginAndEndDates(state.header.locations[state.header.locations.length - 1].timeslots)}*/
                    locationDate={formatTimeslotInterval(state.header.locations[state.header.locations.length - 1].timeslots)}
                    locationDisabled={false}
                  />
                </div>

                {state.header.sumPrice && (
                  <ExpeditionCostWrapper>
                    <ExpeditionCostItem
                      name={tr(`ExpeditionDetailPage.agreedPrice`, `Smluvená cena za přepravu`)}
                      price={state.header.sumPrice.agreedPrice}
                      currency={state.header.sumPrice.agreedPriceCurrency}
                    />
                    <ExpeditionCostItem
                      name={tr(`ExpeditionDetailPage.extraCostSumPrice`, `Celkové vícenáklady`)}
                      price={state.header.sumPrice.extraCostSumPrice}
                      currency={state.header.sumPrice.extraCostSumCurrency}
                    />
                    <ExpeditionCostItem
                      name={tr(`ExpeditionDetailPage.finalPrice`, `Výsledná cena`)}
                      price={state.header.sumPrice.finalPrice}
                      currency={state.header.sumPrice.finalPriceCurrency}
                    />
                  </ExpeditionCostWrapper>
                )}
              </div>
              {/*//
      @ts-ignore*/}
              <TabView className="tabs" ref={tabViewRef}>
                {/*//
      @ts-ignore*/}
                <TabPanel header={t(`ExpeditionDetailPage.shipmentProgress`, `Průběh přepravy`)} leftIcon="pi pi-chart-bar">
                  <div className="tab-content">
                    {state.header.lastExpeditionStatus === LastExpeditionStatusEnum.ASSIGNED && <TabShipmentAssigned disabled={disabledInputs} />}
                    {isExpeditionActiveByLast(state.header.lastExpeditionStatus) && (
                      <TabShipmentActive2Finish
                        toogleToExpeditionPlan={() => {
                          // @ts-ignore
                          if (tabViewRef && tabViewRef.current)
                            // @ts-ignore
                            tabViewRef.current.setState({ activeIndex: 1 });
                        }}
                        disabled={disabledInputs}
                      />
                    )}
                  </div>
                </TabPanel>
                {/*//
      @ts-ignore*/}
                <TabPanel header={t(`ExpeditionDetailPage.route`, `Plán expedice`)} leftIcon="pi pi-image">
                  <div className="tab-content">{<TabExpeditionRoute />}</div>
                </TabPanel>
                {/*//
      @ts-ignore*/}
                <TabPanel header={t(`ExpeditionDetailPage.documents`, `Dokumenty`)} leftIcon="pi pi-file-o">
                  <TabDocuments
                    expeditionId={state.header.id}
                    documents={state.documents}
                    onDelete={documentId => {}}
                    applicationId={state.header.applicationId}
                    companyId={state.header.companyId}
                    disabled={disabledInputs}
                  />
                </TabPanel>
              </TabView>
            </Panel>
          </Card>
        </>
      )}
    </>
  );
};

const splitIDs = (input: string) => {
  const firstDashIndex = input.indexOf('-');

  if (firstDashIndex === -1) {
    throw new Error('Invalid format: No dash found in input.');
  }

  let customerId, applicationId;

  if (firstDashIndex === 8) {
    // UUID (36 znaků celkem)
    if (/^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}$/.test(input.slice(0, 36))) {
      customerId = input.slice(0, 36); // Prvních 36 znaků je UUID
      applicationId = input.slice(37); // Zbytek po pomlčce
    } else {
      throw new Error('Invalid format: Expected a valid UUID.');
    }
  } else {
    // MongoID (24 znaků)
    if (/^[a-f\d]{24}$/.test(input.slice(0, 24))) {
      customerId = input.slice(0, 24); // Prvních 24 znaků je MongoID
      applicationId = input.slice(25); // Zbytek po pomlčce
    } else {
      throw new Error('Invalid format: Expected a valid MongoID.');
    }
  }

  return { customerId, applicationId };
};

const ExpeditionDetailPage = () => {
  const [state, setState] = useState<ExpeditionDetailTabPlanOutDTO>();
  const { caGetShipmentDetailHeader } = useContext(ApiContext);
  const { id } = useParams();

  const { customerId, applicationId } = splitIDs(id);

  useEffect(() => {
    caGetShipmentDetailHeader(customerId, applicationId, data => {
      setState(draft => {
        return data;
      });
    });
  }, []);

  return (
    <>
      {state && (
        <ExpeditionDetailHandlerProvider initialData={{ header: state }}>
          <ExpeditionDetailPageInner />
        </ExpeditionDetailHandlerProvider>
      )}
    </>
  );
};

export default ExpeditionDetailPage;
