import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { LocationDetailComponent } from './LocationDetailComponent';
import { Location, PageID, RouteType } from './types';
import ParametrizedInputWrapper from './ParametrizedInputWrapper/ParametrizedInputWrapper';
import { useTranslation } from 'react-i18next';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import RouteTypeComponent from '@components/obsolete/common/RouteTypeComponent.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { InputText } from 'primereact/inputtext';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { EntityTypeEnum, FeaturesEnum } from '@api/logsteo-api.v2.tsx';
import { ApiContext } from '@api/api.tsx';
import { Note } from '@components/styles.tsx';

interface ComponentProps {
  locations: Location[];
  routeType: RouteType;
}

const NewExpeditionStep2: React.FC<ComponentProps> = ({ locations, routeType }) => {
  const { t } = useTranslation('common');
  const { checkApplicationId } = useContext(ApiContext);
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { clickOnBreadCrumb, proceedToLoading, changeRouteType, validation, state, addCustomExpeditionId } = useContext(NewExpeditionViewContext);

  const onBlurHandler = (value: string) => {
    checkApplicationId(EntityTypeEnum.EXPEDITION, { applicationId: value }, d => {
      setIsValid(d.isValid);
      addCustomExpeditionId(value);
    });
  };

  return (
    <>
      {loggedUser?.features.some(t => t == FeaturesEnum.CUSTOM_EXP_APP_ID) && (
        <div className={'flex flex-row w-[300px] mb-4'}>
          <Field label={'Vlastní ID expedice'} errorMessage={isValid == false ? 'Toto ID není volné' : ''}>
            <InputText onBlur={e => onBlurHandler(e.target.value)} />
          </Field>
        </div>
      )}

      <div>
        <ParametrizedInputWrapper errors={validation} validationPath={'routeType'}>
          <RouteTypeComponent routeType={routeType} changeRouteType={changeRouteType} />
        </ParametrizedInputWrapper>
      </div>
      {locations.map((location, index) => (
        <LocationDetailComponent key={index} routeType={routeType} locationDetail={location} locationIndex={index} locationsLength={locations.length} />
      ))}
      <ButtonLayout>
        <Button label={t('wayPage.form.buttonContinue')} className="p-button-success p-mr-2" onClick={e => proceedToLoading()} />
        <Button label={t('wayPage.form.buttonBack')} className="p-button-text" onClick={e => clickOnBreadCrumb(PageID.STEP1)} />
      </ButtonLayout>
    </>
  );
};

export default NewExpeditionStep2;
